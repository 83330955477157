import APIengagement from "../APIengagement";

export function parseLocalInt(numberString) {
  if(!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(/,/g, '');

  const result = parseInt(normalizedString, 10);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

export function parseLocalFloat(numberString) {
  if(!numberString)
    return null;
  // Replace commas with dots
  const normalizedString = numberString.replace(',', '.');

  const result = parseFloat(normalizedString);
  // Check if the result is NaN and throw an error
  if (isNaN(result)) {
    throw new Error(`Invalid number format: ${numberString}`);
  }

  return result;
}

// this function returns a promise that resolves after n milliseconds
export const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));

export async function TrackLinks(creators = []) {
  const links = {}

  for (const creator of creators) {
    if (creator.liveLink) {
      try {
        const engagement = (await APIengagement.engagement.get_engagement({ urls: [creator.liveLink] }))[0];
        if (engagement.error)
          throw new Error(`url: ${engagement.url} error: ${engagement.error}`);
        links[creator.id] = engagement;
      } catch (error) {
        console.log(error);
        links[creator.id] = { error };
      }
    } else {
      links[creator.id] = null;
      console.log(`url: ${creator.id} error: no url`);
    }
  }

  return links;
}

export const drawerWidth = "20rem";

const emptyCountry = { code: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyAge = { age_range: 'N/A', value: Number.NEGATIVE_INFINITY };
const emptyGender = { name: 'N/A', value: Number.NEGATIVE_INFINITY };

export const CountSets = (sets = []) => (
  sets.reduce((counter, value) => counter + value.size, 0)
)

export const ToggleSet = (value, setGetter, setSetter) => {
  if (setGetter.has(value))
    setSetter(prevSet => {
      prevSet.delete(value)
      return new Set(prevSet)
    })
  else
    setSetter(prevSet => new Set(prevSet.add(value)))
}

export const highestValueCountry = (publicData) => {
  return publicData.reduce((ctrMax, country) => {
    if (country.value > ctrMax.value)
      ctrMax = country;
    return ctrMax;
  }, emptyCountry);
}

export const higherValueAge = (publicData) => {
  return publicData.reduce((ageMax, ageGroup) => {
    if (ageGroup.value > ageMax.value)
      ageMax = ageGroup;
    return ageMax;
  }, emptyAge);
}

export const higherValueGender = (publicData) => {
  return publicData.reduce((genderMax, genderGroup) => {
    if (genderGroup.value > genderMax.value)
      genderMax = genderGroup;
    return genderMax;
  }, emptyGender);
}

export const getAgeDemos = (demo) => {
  return demo.reduce((acc, entry) => {
    const { age_range, value } = entry;
    const existingEntry = acc.find(item => item.age_range === age_range);

    if (existingEntry) {
      existingEntry.value += Math.round(value);
    } else {
      acc.push({ age_range: age_range, value: Math.round(value) });
    }

    return acc;
  }, [])
}

export const getGenderDemos = (demo) => ([
  {
    gender: 'Male',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'male') accum += Math.round(item.value);
      return accum;
    }, 0)
  },
  {
    gender: 'Female',
    value: demo.reduce((accum, item) => {
      if (item.gender.toLowerCase() === 'female') accum += Math.round(item.value);
      return accum;
    }, 0)
  }
]);

export function generateRainbowColors(x) {
  const colors = [];
  for (let i = 0; i < x; i++) {
    const hue = Math.floor((360 / x) * i); // Calculate the hue for each color
    const color = `hsl(${hue}, 80%, 70%)`; // Use HSL to create the color
    colors.push(color);
  }
  return colors;
}

export const TimelineStatus = [
  'incomplete',
  'in progress',
  'complete'
]

export const formatCampaignSum = (sum) => {
  const numericSum = typeof sum === "number" ? sum : parseFloat(sum.replace(/[^\d.]/g, ""));
  return `$${numericSum.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatProposalDate = (dateString) => {
  const date = new Date(dateString);
  return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date
    .getDate()
    .toString()
    .padStart(2, "0")}/${date.getFullYear().toString().substr(-2)}`;
};

//As used by insightiq
export const ageGroups = ["13-17", "18-24", "25-34", "35-44", "45-64", "65+"];

export const region = ["NACAUKAUS", "Europe", "Asia", "LATAM", "Africa"];

export const ethnicity = ["Asian", "Black", "Hispanic", "White", "Other"];

export const genders = ["Male", "Female", "Non-binary", "Other"];

export const niches = [
  "Activist",
  "Artist",
  "Beauty",
  "Cars",
  "Cosplay",
  "Comedy",
  "Country",
  "Dance",
  "Educational",
  "Fashion",
  "Fitness",
  "Food",
  "Gaming",
  "Lifestyle",
  "Music",
  "Pets",
  "Reviews",
  "Sports",
  "Tech",
  "Thirst Trap",
  "Travel",
]

export const platforms = [
  'TikTok',
  'Instagram',
  'Youtube',
  'Podcasts',  // New Platform
  'Twitch',    // New Platform (Streaming)
  'Kick',      // New Platform (Streaming)
  'X',         // New Platform (Others)
];

export const promotionTypeArray = [
  ["Sound", "Brand", "Livestream"], // TikTok
  ["Sound", "Brand", "Feed Post"],  // Instagram
  ["3045s Integration", "60s Integration", "Shorts"], // Youtube
  ["15s", "60s"],  // Podcasts (Shows)
  ["Live 30s"],    // Twitch (Streaming)
  ["Live 30s"],    // Kick (Streaming)
  ["Feed Post", "Repost"],  // X (Others)
];

export const promotionTypeOptions = {
  TikTok: ["Sound", "Brand", "Livestream"],
  Instagram: ["Sound", "Brand", "Feed Post"],
  Youtube: ["30 Second Integration", "60 Second Integration", "Shorts"],
  Podcasts: ["15s", "60s"],  // Shows
  Twitch: ["Live 30s"],      // Streaming
  Kick: ["Live 30s"],        // Streaming
  X: ["Feed Post", "Repost"], // Others
};

// Updated indexToPromoArray to include new platform entries
export const indexToPromoArray = [
  [0, 0], // TikTok Sound
  [0, 1], // TikTok Brand
  [1, 0], // Instagram Sound
  [1, 1], // Instagram Brand
  [1, 2], // Instagram Feed Post
  [2, 0], // Youtube 30s Integration
  [2, 1], // Youtube 60s Integration
  [2, 2], // Youtube Shorts
  [3, 0], // Podcasts 15s
  [3, 1], // Podcasts 60s
  [4, 0], // Twitch Live 30s
  [5, 0], // Kick Live 30s
  [6, 0], // X Feed Post
  [6, 1], // X Repost
];

// Function to map index to platform and promotion type
export const indexToPromoFilter = (index) => ({
  platform: platforms[indexToPromoArray[index][0]],
  promo: promotionTypeArray[indexToPromoArray[index][0]][indexToPromoArray[index][1]]
});
