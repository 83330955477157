import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import client from './../API';

const UploadFileDialogPFP = ({ open, onClose }) => {
  const [uploadedUrl, setUploadedUrl] = useState('');
  const FILE_SIZE_LIMIT = 4.5 * 1024 * 1024; // 4.5 MB

  const { mutate: uploadFile } = useMutation(
    (fileData) => client.files.post_creator_pfp(fileData),
    {
      onSuccess: (data) => {
        console.log('File uploaded successfully:', data);
        setUploadedUrl(data.url);
        onClose(data.url); // Pass the uploaded URL to the parent
      },
      onError: (error) => {
        console.error('Error uploading file:', error);
        alert(`Error uploading file: ${error.message}`);
      },
    }
  );

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file.size > FILE_SIZE_LIMIT) {
      alert('File size exceeds the 4.5MB limit. Please upload a smaller file.');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64File = reader.result.split(',')[1];
      uploadFile({
        file: {
          name: file.name,
          content: base64File,
          type: file.type,
        },
      });
    };
  }, [uploadFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload File</DialogTitle>
      <DialogContent>
        <div {...getRootProps()} style={{ border: '2px dashed #000', padding: '20px', cursor: 'pointer' }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files (Max size: 4.5MB)</p>
          )}
        </div>
        {uploadedUrl && (
          <div>
            <p>Uploaded File:</p>
            <img src={uploadedUrl} alt="Uploaded File" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFileDialogPFP;
