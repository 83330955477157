import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, Button, Grid, CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import useAgencyAuth from '../../Hooks/agency-use-auth';
import AgencyNavbar from '../../Components/Navbar/agencyNavBar';
import API from '../../API';
import { useNavigate } from 'react-router-dom';

const AgencyStart = () => {
  const { getCompanyInfo } = useAgencyAuth();
  const agencyName = getCompanyInfo()?.name;
  const navigate = useNavigate();

  // Fetching campaigns, creators, and payouts/commissions
  const { isLoading: isLoadingCampaigns, error: errorCampaigns, data: campaigns } = useQuery(['agencyCampaigns', agencyName], () => API.campaigns.list);
  const { isLoading: isLoadingCreators, error: errorCreators, data: creators } = useQuery(['agencyCreators', agencyName], () => API.creators.listManager(agencyName));

  const renderCombinedCard = (title, value, description, buttonText, route, loading) => (
    <Card elevation={2}>
      <CardContent>
        <Typography variant="h5" gutterBottom>{title}</Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5">{value}</Typography>
        )}
        <Typography variant="body2">{description}</Typography>
        <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate(route)}>
          {buttonText}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box display="flex" flexDirection="row">
      {/* Navbar */}
      <AgencyNavbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Hello, {agencyName}
        </Typography>

        <Typography variant="h6" gutterBottom>
          Quick Access
        </Typography>

        <Grid container spacing={3}>
          {/* Total Campaigns Card */}
          <Grid item xs={12} sm={6} md={4}>
            {renderCombinedCard(
              "Total Campaigns",
              campaigns ? campaigns.length : 0,
              "Manage your ongoing and completed campaigns.",
              "View Campaigns",
              "/agency/projects",
              isLoadingCampaigns
            )}
          </Grid>

          {/* Total Creators Card */}
          <Grid item xs={12} sm={6} md={4}>
  {renderCombinedCard(
    "Total Creators",
    creators && Array.isArray(creators) ? creators.length : '',
    "Onboard and manage creators associated with your agency.",
    "View Creators",
    `/roster/${agencyName}`, // Use template literals for string interpolation
    isLoadingCreators
  )}
</Grid>


          {/* Import Creators Card */}
          <Grid item xs={12} sm={6} md={4}>
            <Card elevation={2}>
              <CardContent>
                <Typography variant="h5" gutterBottom>Import Creators</Typography>
                <Typography variant="body2">Quickly import creators to your agency's roster.</Typography>
                <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate( `/creatorsignup/${agencyName}`)}>
                  Import Creators
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AgencyStart;
