import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  Button,
  Select,
  MenuItem,
  TextField,
  Stack,
  Grid,
  Card,
  CardContent,
  IconButton
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useQuery, useMutation, useQueryClient } from "react-query";
import client from "../../../../API"; // Adjust the import path according to your project structure
import SelectAssetDialog from "../../../../Components/tools/selectfile";
import { StyledTableCell, StyledTableRow } from "../../../../Utils/styledcell";

const AssetsTab = ({ campaignDetails, onUpdate }) => {
  const [newCreator, setNewCreator] = useState({
    name: "",
    platform: "",
    promotionType: "",
    creatorBrief: "",
    postingInstructions: "",
    boostCode: "",
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [applyToAll, setApplyToAll] = useState(false);
  const [currentCreatorIndex, setCurrentCreatorIndex] = useState(null);
  const queryClient = useQueryClient();

  const { data: files, refetch } = useQuery("files", () => client.files.fetch(""));

  const { mutate: updateCampaignCreatorSpecs } = useMutation(
    (payload) => client.campaigns.updateCampaignCreatorSpecs(payload),
    {
      onSuccess: () => {
        alert("Creator details updated successfully!");
      },
      onError: (error) => {
        alert(error.response?.data?.error || error.message);
      },
    },
  );
  const handleDialogOpen = (applyToAllFlag = false, index = null) => {
    setApplyToAll(applyToAllFlag);
    setCurrentCreatorIndex(index);
    setDialogOpen(true);
  };

  const handleDialogClose = async (confirmed) => {
    if (confirmed && selectedAsset) {
      const updatedCreators = campaignDetails.creators.map((creator, idx) => {
        if (applyToAll || idx === currentCreatorIndex) {
          return { ...creator, creatorBrief: `${creator.creatorBrief}\n${selectedAsset.file_url}` };
        }
        return creator;
      });

      const updatedDetails = { ...campaignDetails, creators: updatedCreators };
      onUpdate(updatedDetails);

      const payload = {
        campaignId: updatedDetails.id,
        creators: updatedDetails.creators.map(creator => ({
          id: creator.id,
          creatorBrief: creator.creatorBrief,
          postingInstructions: creator.postingInstructions,
          boostCode: creator.boostCode,
        })),
      };

      updateCampaignCreatorSpecs(payload);
    }
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleAssetClick = (asset) => {
    setSelectedAsset(asset);
  };

  const handleInputChange = (index, field, value) => {
    const updatedCreators = campaignDetails.creators.map((creator, idx) => {
      if (index === idx) {
        return { ...creator, [field]: value };
      }
      return creator;
    });
    onUpdate({ ...campaignDetails, creators: updatedCreators });
  };

  const handleAddCreator = () => {
    const updatedCreators = [...campaignDetails.creators, newCreator];
    onUpdate({ ...campaignDetails, creators: updatedCreators });
    setNewCreator({
      name: "",
      platform: "",
      promotionType: "",
      creatorBrief: "",
      postingInstructions: "",
      boostCode: "",
    });
  };

  const handleChangeNewCreator = (field, value) => {
    setNewCreator((prev) => ({ ...prev, [field]: value }));
  };

  const promotionTypeOptions = {
    TikTok: ["Sound", "Brand", "Livestream", "UGC"],
    Instagram: ["Sound", "Brand", "Feed Post", "UGC"],
    YouTube: ["30-45s Integration", "60s Integration", "Shorts", "UGC"],
    Other: ["Sponsored Post", "Ad Placement", "Product Review", "UGC"],
  };

  const getPromotionTypes = (platform) => {
    return promotionTypeOptions[platform] || promotionTypeOptions["Other"];
  };

  return (
    <Box>
      {/* Creator Submission Portal Section */}
      <Typography variant="h6" sx={{ margin: 2}}>
        Creator Submission Portal
      </Typography>
      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
        {campaignDetails.creators.map((creator) => (
          <Grid item xs={12} sm={6} md={4} key={creator.id}>
            <Card
              sx={{
                cursor: creator.submissionLink ? "pointer" : "default",
                border: creator.submissionLink
                  ? "2px solid blue"
                  : "2px solid grey",
              }}
              onClick={() => {
                if (creator.submissionLink) {
                  window.open(creator.submissionLink, "_blank");
                }
              }}
            >
              <CardContent>
                <Typography variant="h6">{creator.name}</Typography>
                {creator.submissionLink ? (
                  <Typography variant="body2">
                    Click to view submission
                  </Typography>
                ) : (
                  <Typography variant="body2">
                    Creator link unavailable
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Existing Campaign Brief and Assets Table */}
      <Typography variant="h6" sx={{ margin: 2}}>
        Campaign Brief: {campaignDetails.brief}
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => handleDialogOpen(true)}
        sx={{ marginBottom: 2 }}
      >
        Apply an Asset to All Creators
      </Button>
      <TableContainer component={Paper}>
        <Table aria-label="assets details">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Name/ID</StyledTableCell>
              <StyledTableCell>Platform</StyledTableCell>
              <StyledTableCell>Promotion Type</StyledTableCell>
              <StyledTableCell>Creator Brief</StyledTableCell>
              <StyledTableCell>Posting Instructions</StyledTableCell>
              <StyledTableCell>Boost Code</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {campaignDetails.creators &&
              campaignDetails.creators.map((creator, index) => (
                <StyledTableRow key={creator.id}>
                  <StyledTableCell>
                    <Select
                      value={creator?.name || ""}
                      onChange={(e) =>
                        handleInputChange(index, "name", e.target.value)
                      }
                      displayEmpty
                      fullWidth
                    >
                      {campaignDetails.creators.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Select
                      value={creator?.promotionPlatform || ""}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "promotionPlatform",
                          e.target.value
                        )
                      }
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {Object.keys(promotionTypeOptions).map((platform) => (
                        <MenuItem key={platform} value={platform}>
                          {platform}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Select
                      value={creator?.promotionType || ""}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "promotionType",
                          e.target.value
                        )
                      }
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {getPromotionTypes(creator?.promotionPlatform).map(
                        (type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        fullWidth
                        multiline
                        value={creator?.creatorBrief || ""}
                        onChange={(e) =>
                          handleInputChange(index, "creatorBrief", e.target.value)
                        }
                        placeholder="Enter creator brief"
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleDialogOpen(false, index)}
                      >
                        Add Asset
                      </Button>
                    </Stack>
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      multiline
                      value={creator?.postingInstructions || ""}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "postingInstructions",
                          e.target.value
                        )
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <TextField
                      fullWidth
                      value={creator?.boostCode || ""}
                      onChange={(e) =>
                        handleInputChange(index, "boostCode", e.target.value)
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <IconButton onClick={handleAddCreator} color="primary">
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            <StyledTableRow>
              <StyledTableCell>
                <Select
                  value={newCreator.name}
                  onChange={(e) =>
                    handleChangeNewCreator("name", e.target.value)
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {campaignDetails.creators.map((creator) => (
                    <MenuItem key={creator.id} value={creator.name}>
                      {creator.name}
                    </MenuItem>
                  ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell>
                <Select
                  value={newCreator.promotionPlatform}
                  onChange={(e) =>
                    handleChangeNewCreator("promotionPlatform", e.target.value)
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Object.keys(promotionTypeOptions).map((platform) => (
                    <MenuItem key={platform} value={platform}>
                      {platform}
                    </MenuItem>
                  ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell>
                <Select
                  value={newCreator.promotionType}
                  onChange={(e) =>
                    handleChangeNewCreator("promotionType", e.target.value)
                  }
                  displayEmpty
                  fullWidth
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {getPromotionTypes(newCreator.platform).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </StyledTableCell>
              <StyledTableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleDialogOpen(false)}
                >
                  Select Asset
                </Button>
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  value={newCreator.postingInstructions}
                  onChange={(e) =>
                    handleChangeNewCreator("postingInstructions", e.target.value)
                  }
                  placeholder="Posting Instructions"
                  fullWidth
                  multiline
                />
              </StyledTableCell>
              <StyledTableCell>
                <TextField
                  value={newCreator.boostCode}
                  onChange={(e) =>
                    handleChangeNewCreator("boostCode", e.target.value)
                  }
                  placeholder="Boost Code"
                  fullWidth
                />
              </StyledTableCell>
              <StyledTableCell>
                <IconButton onClick={handleAddCreator} color="primary">
                  <AddCircleOutlineIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Import the SelectAssetDialog */}
      <SelectAssetDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        files={files}
        selectedAsset={selectedAsset}
        onAssetClick={handleAssetClick}
        refetchFiles={refetch} // Pass the refetch function to refresh the asset list
      />
    </Box>
  );
};

export default AssetsTab;
