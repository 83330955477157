import React from "react";
import { Grid, Card, CardContent, Typography, Button, CardMedia } from "@mui/material";
import profilePhoto from "../../../../Components/globalAssets/ppfLogo.png";

const CampaignsContainers = ({ creators }) => {
  return (
    <Grid container spacing={0} justifyContent="center">
      {creators.map((creator, index) => (
        <Grid item xs={12} sm={6} md={4} key={index} sx={{ padding: 0 }}>
          <Card sx={{ maxWidth: 345, margin: "auto", marginBottom: 2 }}>
            <CardMedia
              component="img"
              height="140"
              image={creator.pfphref || profilePhoto}
              alt={creator.name}
            />
            <CardContent sx={{ padding: 1 }}>
              <Typography variant="h5">@{creator.creator}</Typography>
              {creator.youtube && (
                <Typography variant="body1">Youtube following: {creator.youtube}</Typography>
              )}
              {creator.instagram && (
                <Typography variant="body1">Instagram following: {creator.instagram}</Typography>
              )}
              {creator.tiktok && (
                <Typography variant="body1">TikTok following: {creator.tiktok}</Typography>
              )}
              <Button
                variant="contained"
                color="primary"
                href={`https://blitzpay.pro/creators/${creator.creator}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ marginTop: 1 }}
              >
                View Media Kit
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CampaignsContainers;
