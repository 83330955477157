import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";

class Client {
  engagement = {
    get_engagement: (input) => HttpClient.post(API_ENDPOINTS.USERS_LOGIN, input),
  }
}

export default new Client();
