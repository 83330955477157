import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Card, CardContent, Grid, Divider } from '@mui/material';
import { useQuery } from 'react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import { Link } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { CSVLink } from 'react-csv';

const PayoutsInvoicing = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;

  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [notes, setNotes] = useState('');
  const [poNumber, setPoNumber] = useState('');

  const { isLoading: isLoadingPayouts, error: errorPayouts, data: payouts } = useQuery(
    ['creator', 'payouts'], 
    () => API.creatorConnect.getPartnerships(username),
    {
      keepPreviousData: true,
    }
  );

  const { isLoading: isLoadingFinancials, error: errorFinancials, data: financials } = useQuery(
    ['creator', 'financials'], 
    () => API.creatorConnect.getFinancials(username),
    {
      keepPreviousData: true
    }
  );

  const handleGeneratePO = () => {
    setPoNumber(`TCC-${Math.floor(Math.random() * 1000000)}`);
  };

  const handleClickOpen = () => {
    setOpen(true);
    handleGeneratePO();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInvoice = async () => {
    try {
      await API.creatorConnect.postInvoice({
        user_id: username,
        creator_id: username,
        po_number: poNumber,
        amount,
        payout_date: new Date().toISOString(),
        status: 'Pending',
        blitzpay: true,
        unique_code: poNumber,
        interest_rate: 5, // Assuming interest_rate is 5%
        client_name: clientName,
        client_email: clientEmail,
        notes
      });
      handleClose();
    } catch (error) {
      alert(error);
      handleClose();
    }
  };

  const handleDownloadCSV = () => {
    const csvData = [
      ['ID', 'Amount', 'Status', 'Payout Date', 'Blitzpay', 'Fee', 'Unique Code', 'Notes'],
      ...pendingInvoices.map(invoice => [
        invoice.id,
        invoice.amount?.toFixed(2) || '0.0',
        invoice.status,
        invoice.payout_date,
        invoice.blitzpay ? 'Yes' : 'No',
        ((invoice.interest_rate / 100) * invoice.amount).toFixed(2),
        invoice.unique_code,
        invoice.notes,
      ]),
      ...pendingPayouts.map(payout => [
        payout.id,
        payout.amount?.toFixed(2) || '0.0',
        payout.status,
        payout.payout_date,
        payout.blitzpay ? 'Yes' : 'No',
        ((payout.interest_rate / 100) * payout.amount).toFixed(2),
        payout.unique_code,
        payout.notes,
      ]),
    ];

    const csvString = csvData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `Financial_Statement_${new Date().toLocaleDateString()}.csv`);
  };

  if (isLoadingPayouts || isLoadingFinancials) return <CircularProgress sx={{ color: 'white' }} />;
  if (errorPayouts) return <Typography sx={{ color: 'error' }}>Error fetching payouts: {errorPayouts.message}</Typography>;
  if (errorFinancials) return <Typography sx={{ color: 'error' }}>Error fetching financials: {errorFinancials.message}</Typography>;

  const pendingPayouts = payouts?.filter(payout => payout.status === 'pending');
  const totalPendingEarnings = pendingPayouts?.reduce((sum, payout) => sum + (payout.amount || 0), 0) || 0;

  const pendingInvoices = financials?.invoices?.filter(invoice => invoice.status === 'Pending');
  const unverifiedFunds = pendingInvoices?.reduce((sum, invoice) => {
    const amount = invoice.amount || 0;
    return sum + amount;
  }, 0) || 0;

  const chartData = pendingPayouts.map((payout) => {
    // Parse the date, handling both formats
    let payoutDate = new Date(payout.payout_date);
    if (isNaN(payoutDate.getTime())) {
      // If the date parsing failed (e.g., "Invalid Date"), try parsing as a different format
      payoutDate = new Date(Date.parse(payout.payout_date));
    }
  
    if (isNaN(payoutDate.getTime())) {
      // If the date still fails to parse, skip this entry or set a default date
      return null; // or { date: 'Invalid Date', amount: payout.amount }
    }
  
    return {
      date: payoutDate.toLocaleDateString('en-US'), // Format the date as 'MM/DD/YYYY'
      amount: payout.amount,
    };
  }).filter(data => data !== null); // Remove any null entries
  

  return (
    <Box sx={{ p: 2, textAlign: 'center' }}>
      <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '2rem' }}>
        Balance: ${Number(totalPendingEarnings).toFixed(2)}
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" sx={{ fontSize: '2rem', mt: 1 }}>
        Unverified Funds: ${Number(unverifiedFunds).toFixed(2)}
      </Typography>
      
      <Divider sx={{ my: 3 }} />

      <Button
        variant="contained"
        sx={{ mt: 4 }}
        onClick={handleClickOpen}
      >
        Invoice Someone
      </Button>

      <Button
        variant="contained"
        sx={{ mt: 4, ml: 2 }}
        onClick={handleDownloadCSV}
      >
        Download Financial Statement
      </Button>

      {/* Line Chart */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Unverified Funds & Pending Payouts Over Time
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="amount" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      {/* Incoming Payouts */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Incoming Payouts
      </Typography>
      <Grid container spacing={2}>
        {pendingPayouts && pendingPayouts.length > 0 ? pendingPayouts.map((payout) => {
          const amount = payout.amount || 0;
          const fee = (payout.interest_rate / 100) * amount;

          return (
            <Grid item xs={12} sm={6} md={4} key={payout.id}>
              <Card elevation={2}>
                <CardContent>
                  <Typography variant="h6">Payout ID: {payout.id}</Typography>
                  <Typography>Amount: ${amount.toFixed(2)}</Typography>
                  <Typography>Status: {payout.status}</Typography>
                  <Typography>Payout Date: {payout.payout_date}</Typography>
                  <Typography>Campaign ID: {payout.campaign_id}</Typography>
                  <Typography>Blitzpay: {payout.blitzpay ? 'Yes' : 'No'}</Typography>
                  <Typography>Fee: ${fee.toFixed(2)}</Typography>
                  <Typography>Unique Code: {payout.unique_code}</Typography>
                  <Typography>Notes: {payout.notes}</Typography>
                  <Button variant="text" sx={{ mt: 2 }}>
                    Text BLITZPAY {payout.unique_code} to the Blitz phone number to claim today
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        }) : (
          <Typography>No pending payouts available</Typography>
        )}
      </Grid>
      <Divider sx={{ my: 2 }} />

      {/* Outgoing Invoices */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Outgoing Invoices
      </Typography>
      <Grid container spacing={2}>
        {pendingInvoices?.length > 0 ? pendingInvoices.map((invoice) => {
          const amount = invoice.amount || 0;
          const fee = (invoice.interest_rate / 100) * amount;

          return (
            <Grid item xs={12} sm={6} md={4} key={invoice.id}>
              <Card elevation={2}>
                <CardContent>
                  <Typography variant="h6">Invoice ID: {invoice.id}</Typography>
                  <Typography>Amount: ${amount.toFixed(2)}</Typography>
                  <Typography>Status: {invoice.status}</Typography>
                  <Typography>Payout Date: {invoice.payout_date}</Typography>
                  <Typography>Blitzpay: {invoice.blitzpay ? 'Yes' : 'No'}</Typography>
                  <Typography>Fee: ${fee.toFixed(2)}</Typography>
                  <Typography>Unique Code: {invoice.unique_code}</Typography>
                  <Typography>Notes: {invoice.notes}</Typography>
                  <Button 
                    variant="contained" 
                    component={Link} 
                    to={`/creatorinvoice/${invoice.id}`}
                    sx={{ mt: 2 }}
                  >
                    View Invoice
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        }) : (
          <Typography>No pending invoices available</Typography>
        )}
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invoice Client</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the client you want to invoice.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Client Name"
            fullWidth
            variant="outlined"
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Client Email"
            fullWidth
            variant="outlined"
            value={clientEmail}
            onChange={(e) => setClientEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Amount"
            fullWidth
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Notes"
            fullWidth
            variant="outlined"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleInvoice}>Send Invoice</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PayoutsInvoicing;
