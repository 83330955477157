import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import {
  Box,
  Paper,
  Typography,
  Button, // Import Button
  Grid,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import client from "../../../API"; // Ensure this is the correct path
import AssetsTab from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/campaignAssets";
import CreatorTimeline from "../../../CreatorUser/CreatorPages/Dashboard/creatorcomponents/creatorTimeline";
import { useMutation } from "react-query";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import { TimelineStatus } from "../../../Utils/constants";
import AgencyCampaignConversation from "./agencyconvo";
import useAgencyAuth from "../../../Hooks/agency-use-auth";
import { getAgencyAuthToken } from "../../../Hooks/agency-use-auth";
import { ConfigValue } from "../../../Config";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const AgencySpecificCampaign = () => {
  const { campaignId, creatorToken } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(null);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const editedEvents = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate hook
  const token = getAgencyAuthToken;
  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await client.campaigns.fetch(campaignId);
        let eventData = { events: [] };

        try {
          const timelineResponse = await fetch(`${ConfigValue.PUBLIC_REST_API_ENDPOINT}/campaigns/${campaignId}/creator/${creatorToken}/timeline`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${getAgencyAuthToken()}`,
            },
          });

          if (timelineResponse.ok) {
            eventData = await timelineResponse.json();
          } else if (timelineResponse.status === 404) {
            console.warn("No timeline found for this campaign. Proceeding without it.");
          } else {
            throw new Error(`Error fetching timeline: ${timelineResponse.statusText}`);
          }
        } catch (err) {
          console.error("Error fetching timeline events:", err);
        }

        editedEvents.current = false;
        setCampaign(data);
        setTimelineEvents(
          eventData.events.map((event) => ({
            ...event,
            projected_date: new Date(event.projected_date),
            ogStatus: event.status,
          }))
        );
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching campaign data:", err);
      }
    };

    fetchCampaignData();
  }, [campaignId, creatorToken]);

  const OnEditTimelineField = (targetIndex, target, value) => {
    editedEvents.current = true;
    setTimelineEvents(timelineEvents.map((event, index) => {
      if (index === targetIndex) {
        return { ...event, [target]: value };
      }
      else return event;
    }));
  };

  const { mutate: updateTimeline, isLoading: isUpdatingTimeline } = useMutation(
    ({ input }) => client.creatorConnect.campaignUpdateTimeline(campaignId, creatorToken, input),
    {
      onSuccess: () => {
        openAlert('Success', "Timeline Updated", closeDialog, closeDialog, 'Ok', null);
      },
      onError: (error) => {
        const errorMsg = (error.response && error.response.data) ? error.response.data.error : error.message;
        openAlert('Error', `Error when updating timeline: ${errorMsg}`, closeDialog, closeDialog, 'Ok', null);
      },
    }
  );

  const onSaveChanges = () => {
    if (editedEvents.current) {
      const foundSetComplete = timelineEvents.some((event) => event.ogStatus !== event.status && event.status === TimelineStatus[2]);
      if (foundSetComplete) {
        openAlert('Completed Objective',
          "You're setting an objective as complete. This will notify the manager of this campaign, and won't allow you to do any further updates to this objective. Continue?",
          () => { closeDialog(); updateTimeline({ input: timelineEvents }); }, closeDialog, 'Continue', 'Cancel');
      } else {
        updateTimeline({ input: timelineEvents });
      }
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Paper elevation={2} square sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
              Campaign Name: {campaign.name}
            </Typography>
            <Typography variant="h6">
              Ideal Due Date: {formatIdealDueDate(campaign.ideal_duedate)}
            </Typography>
            <Typography variant="h6">
              Brief: {campaign.brief}
            </Typography>
            <Typography variant="h6">
              Status: {campaign.campaign_status}
            </Typography>
          </Paper>

          <Box sx={{ marginTop: 4 }}>
            <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="campaign details tabs">
              <Tab label="Overview" />
              <Tab label="Assets" />
              <Tab label="Conversations" />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <Grid container display="flex" justifyContent="space-between" marginBottom={2} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
                    Campaign Manager:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2">
                        <strong>Name:</strong> {campaign.campaign_manager.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2">
                        <strong>Email:</strong> {campaign.campaign_manager.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2">
                        <strong>Phone:</strong> {campaign.campaign_manager.phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
                    Timeline:
                  </Typography>
                  <CreatorTimeline timelineEvents={timelineEvents} onEditField={OnEditTimelineField} onSaveChanges={onSaveChanges} isSavingChanges={isUpdatingTimeline}></CreatorTimeline>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AssetsTab campaignDetails={campaign} creator={creatorToken} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <AgencyCampaignConversation campaignId={campaign.id} />
            </TabPanel>
          </Box>

          {/* Go Back button */}
          <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={() => navigate('/agency/projects')}>
              Go Back
            </Button>
          </Box>
        </>
      )}
      <AlertDialog alertState={dialogState}></AlertDialog>
    </Box>
  );
};

export default AgencySpecificCampaign;
