import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useState } from 'react'
import SelectPopup from '../../../../Components/SelectPopup';
import FilterPopup from '../../../../Components/FilterPopup';
import { CountSets, ethnicity, genders, indexToPromoFilter, niches, region, ToggleSet } from '../../../../Utils/constants';

export default function CreatorFilter({ genderValue, raceValue, regionValue, onChangeValue }) {
    const [internalGender, setInternalGender] = useState(new Set([]));
    const [internalRegion, setInternalRegion] = useState(new Set([]));
    const [internalRace, setInternalRace] = useState(new Set([]));

    const onOpen = () => {
        setInternalGender(new Set(genderValue));
        setInternalRegion(new Set(regionValue));
        setInternalRace(new Set(raceValue));
    }

    const onClear = () => {
        setInternalGender(new Set([]));
        setInternalRegion(new Set([]));
        setInternalRace(new Set([]));
        onChangeValue({
            race:new Set([]),
            region:new Set([]),
            gender:new Set([])
        });
    };

    const onApply = () => {
        onChangeValue({
            race:new Set(internalRace),
            region:new Set(internalRegion),
            gender:new Set(internalGender)
        });
    };

    const preview = (CountSets([genderValue,raceValue,regionValue])) ? '...' : ''

    return (
        <SelectPopup value={preview} label='Creator Demographic' inputStyle={{ width: '13.5rem' }} onOpenPopup={onOpen} >
            <FilterPopup boxStyle={{ padding: 2}} onApply={onApply} onClear={onClear}>
                <Typography variant='subtitle1' color='text.secondary' ><b>Creator Gender</b></Typography>
                <Box sx={{ display: 'grid', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)' }}>
                    {genders.map((gender) =>
                        <FormControlLabel key={`gender-${gender}`} 
                        checked={internalGender.has(gender)} 
                        onChange={() => ToggleSet(gender, internalGender, setInternalGender)} 
                        label={gender} 
                        control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
                <Typography variant='subtitle1' color='text.secondary' ><b>Creator Race</b></Typography>
                <Box sx={{ display: 'grid', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)' }}>
                    {ethnicity.map((race) =>
                        <FormControlLabel key={`race-${race}`} 
                        checked={internalRace.has(race)} 
                        onChange={() => ToggleSet(race, internalRace, setInternalRace)} 
                        label={race} 
                        control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
                <Typography variant='subtitle1' color='text.secondary' ><b>Creator Region</b></Typography>
                <Box sx={{ display: 'grid', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)' }}>
                    {region.map((region) =>
                        <FormControlLabel key={`region-${region}`} 
                        checked={internalRegion.has(region)} 
                        onChange={() => ToggleSet(region, internalRegion, setInternalRegion)} 
                        label={region} 
                        control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
            </FilterPopup>
        </SelectPopup>
    )
}