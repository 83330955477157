import React, { useState, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Tabs,
  Tab,
  Link,
} from "@mui/material";
import client from "../../../../API"; // Ensure this is the correct path
import CreatorCampaignConversation from "./conversation";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";
import AssetsTab from "./campaignAssets";
import CreatorTimeline from "./creatorTimeline";
import { useMutation } from "react-query";
import useAlertDialog from "../../../../Components/useAlertDialog";
import AlertDialog from "../../../../Components/AlertDialog";
import { TimelineStatus } from "../../../../Utils/constants";

function formatIdealDueDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  } else {
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear().toString().substr(-2);
    return `${month}/${day}/${year}`;
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const CreatorSpecificCampaign = () => {
  const { campaignId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [campaign, setCampaign] = useState(null);
  const [timelineEvents, setTimelineEvents] = useState(undefined);
  const editedEvents = useRef(false)
  const [isLoading, setIsLoading] = useState(true);
  const { creatorToken } = useCreatorAuth();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await client.campaigns.fetch(campaignId);
        let eventData = { events: [] }; // Default to an empty array if no timeline is found
  
        try {
          eventData = await client.campaigns.timeline_by_creator(
            campaignId,
            creatorToken.creator_user.username
          );
        } catch (err) {
          if (err.response && err.response.status === 404) {
            console.warn("No timeline found for this campaign. Proceeding without it.");
          } else {
            throw err; // Rethrow if it's a different error
          }
        }
  
        editedEvents.current = false;
        setCampaign(data);
        setTimelineEvents(
          eventData.events.map((event) => ({
            ...event,
            projected_date: new Date(event.projected_date),
            ogStatus: event.status,
          }))
        );
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching campaign data:", err);
        // Handle general errors, possibly redirect or show an error message
      }
    };
    fetchCampaignData();
  }, [campaignId, creatorToken.creator_user.username]);
  


  const OnEditTimelineField = (targetIndex, target, value) => {
    editedEvents.current = true;
    setTimelineEvents(timelineEvents.map((event, index) => {
      if (index === targetIndex) {
        return { ...event, [target]: value }
      }
      else
        return event;
    })
    )
  }

  const { mutate: updateTimeline, isLoading: isUpdatingTimeline } = useMutation(
    ({ input }) => client.creatorConnect.campaignUpdateTimeline(campaignId, creatorToken.creator_user.username, input),
    {
      onSuccess: () => {
        openAlert('Success',
          "Timeline Updated",
          closeDialog, closeDialog, 'Ok', null);
      },
      onError: (error) => {
        const errorMsg = (error.response && error.response.data) ? error.response.data.error : error.message;
        openAlert('Error',
          `Error when updating timeline: ${errorMsg}`,
          closeDialog, closeDialog, 'Ok', null);
      },
    }
  );

  const onSaveChanges = () => {
    if (editedEvents.current) {
      const foundSetComplete = timelineEvents.some((event) => event.ogStatus != event.status && event.status == TimelineStatus[2]);
      if (foundSetComplete) {
        openAlert('Completed Objective',
          "You're setting an objective as complete. This will notify the manager of this campaign, and won't allow you to do any further updates to this objective. Continue?",
          () => {closeDialog(); updateTimeline({ input: timelineEvents })}, closeDialog, 'Continue', 'Cancel');
      }
      else
        updateTimeline({ input: timelineEvents });
    }
  }

  const creator = useMemo(() => {
    if (!campaign) {
      return undefined;
    }
    const creators = campaign.creators;
    return creators.find(
      (creator) => creator.id === creatorToken.creator_user.username
    );
  }, [campaign]);

  return (
    <Box sx={{ padding: 2 }}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Paper elevation={2} square sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
              Campaign Name: {campaign.name}
            </Typography>
            <Typography variant="h6">
              Ideal Due Date: {formatIdealDueDate(campaign.ideal_duedate)}
            </Typography>
            <Typography variant="h6">
              Brief: {campaign.brief}
            </Typography>
            <Typography variant="h6">
              Status: {campaign.campaign_status}
            </Typography>
          </Paper>

          <Box sx={{ marginTop: 4 }}>
            <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="campaign details tabs">
              <Tab label="Overview" />
              <Tab label="Assets" />
              <Tab label="Conversations" />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <Grid
                container
                display="flex"
                justifyContent="space-between"
                marginBottom={2}
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  {creator && (
                    <>
                      <Typography variant="h6">
                        Creator Brief: {creator.creatorBrief || "N/A"}
                      </Typography>
                      <Typography variant="h6">
                        Rate: {creator.agencyRate ? `$${creator.agencyRate}` : creator.price ? `$${creator.price}` : "N/A"}
                      </Typography>
                    </>
                  )}
                  <Typography variant="h6" style={{ marginTop: 20, marginBottom: 10 }}>
                    Campaign Manager:
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2">
                        <strong>Name:</strong> {campaign.campaign_manager.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2">
                        <strong>Email:</strong> {campaign.campaign_manager.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography variant="body2">
                        <strong>Phone:</strong> {campaign.campaign_manager.phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="h6">Timeline</Typography>
              <CreatorTimeline timelineEvents={timelineEvents} onEditField={OnEditTimelineField} onSaveChanges={onSaveChanges} isSavingChanges={isUpdatingTimeline}></CreatorTimeline>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AssetsTab campaignDetails={campaign} creator={creator} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <CreatorCampaignConversation campaignId={campaign.id} />
            </TabPanel>
          </Box>
        </>
      )}
      <AlertDialog alertState={dialogState}></AlertDialog>
    </Box>
  );
};

export default CreatorSpecificCampaign;
