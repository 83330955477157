import React from 'react';

import BlitzLayout from './BlitzLayout';
import { Outlet } from 'react-router-dom';

const BlitzRoute = ({onHelp}) => {
    return (
        <BlitzLayout onHelp={onHelp}>
            <Outlet></Outlet>
        </BlitzLayout>
    );
};

export default BlitzRoute;
