import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import client from '../../API';

const CreateAssetDialog = ({ open, onClose }) => {
  const [documentContent, setDocumentContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [image, setImage] = useState('');

  const { mutate: createAsset } = useMutation(
    (assetData) => client.files.createAsset(assetData),
    {
      onSuccess: () => {
        alert('Asset created successfully!');
        onClose();
      },
      onError: (error) => {
        console.error('Error creating asset:', error);
        alert(`Error creating asset: ${error.message}`);
      },
    }
  );

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setImage(reader.result);
    reader.readAsDataURL(file);
  };

  const handleCreateAsset = () => {
    createAsset({
      name: fileName,
      content: documentContent,
      image: image,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New Asset</DialogTitle>
      <DialogContent>
        <TextField
          label="File Name"
          fullWidth
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          margin="normal"
        />
        <ReactQuill
          value={documentContent}
          onChange={setDocumentContent}
          modules={{
            toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              ['link', 'image'],
              ['clean']
            ]
          }}
          formats={[
            'header', 'font',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'script', 'sub', 'super',
            'link', 'image'
          ]}
        />
        <input type="file" accept="image/*" onChange={handleImageUpload} style={{ marginTop: '20px' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleCreateAsset} color="primary">Create Asset</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateAssetDialog;
