import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useMutation } from 'react-query';
import client from '../../../../API';
import CreatorContainers from '../../Search/creatorcontainersearch';
import SidePanel from './sidepanel'; // Import the new SidePanel component

const SimilarCreatorsDialogContent = ({ creatorId, onSelect, campaignDetails }) => {
  const [creatorDetails, setCreatorDetails] = useState(null);
  const [similarCreators, setSimilarCreators] = useState([]);
  const [visibleCreators, setVisibleCreators] = useState(6);
  const [showPretty, setShowPretty] = useState(true);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [primaryMarket, setPrimaryMarket] = useState('');
  const [selectedCreators, setSelectedCreators] = useState([]); // State to hold selected creators

  const platformToKey = {
    TikTok: ["tiktok", "rate_tt_sound", "rate_tt_brand"],
    Instagram: ["instagram", "rate_ig_reelsound", "rate_ig_reelbrand"],
    Youtube: ["youtube", "rate_yt_integ30s", "rate_yt_integ60s", "rate_yt_short"],
  };

  const platPromoToKey = {
    TikTok: { "Sound": "rate_tt_sound", "Brand": "rate_tt_brand" },
    Instagram: { "Feed Post": "rate_ig_feedpost", "Sound": "rate_ig_reelsound", "Brand": "rate_ig_reelbrand" },
    Youtube: { "3045s Integration": "rate_yt_integ30s", "60s Integration": "rate_yt_integ60s", "shorts": "rate_yt_short" },
  };

  const headerToKeyMap = {
    Creator: "creator",
    TikTok: "tiktok",
    Instagram: "instagram",
    Youtube: "youtube",
    "Geolocation/Gender/Ethnicity": "geolocation_gender_ethnicity",
    "Primary Market": "primary_market",
    "Content Style": "notes_content_style",
    "TikTok Sound": "rate_tt_sound",
    "TikTok Brand": "rate_tt_brand",
    "IG Reels Sound": "rate_ig_reelsound",
    "IG Reels Brand": "rate_ig_reelbrand",
    "IG Feed Post": "rate_ig_feedpost",
    "Instagram Link": "instagram_link",
    "TikTok Link": "tiktok_link",
    "Youtube Link": "youtube_link",
    "AVG Views": "avg_views",
    "3045s Integration": "rate_yt_integ30s",
    "60s Integration": "rate_yt_integ60s",
    "shorts": "rate_yt_short"
  };

  const { mutate: fetchCreatorDetails, isLoading: isFetchingCreator, isError: isErrorCreator } = useMutation(
    () => client.creators.fetchSimilar(creatorId),
    {
      onSuccess: (data) => {
        setCreatorDetails(data);
        setPrimaryMarket(data.primary_market); // Set the initial primary market
        console.log('Creator details fetched:', data);
      },
      onError: (error) => {
        console.error('Failed to fetch creator details:', error);
      },
    }
  );

  const { mutate: fetchSimilarCreators } = useMutation(client.creators.list, {
    onSuccess: (data) => {
      const allCreators = data || [];
      console.log('All creators fetched:', allCreators);

      const filteredCreators = allCreators.filter(creator => {
        const regionMatch = creator.region === creatorDetails?.region;
        const primaryMarketMatch = creator.primary_market === primaryMarket;
        const rateMatch = creator.rate_tt_brand >= 0.5 * creatorDetails?.rate_tt_brand && creator.rate_tt_brand <= 1.5 * creatorDetails?.rate_tt_brand;

        console.log(`Creator ${creator.creatorId}: regionMatch=${regionMatch}, primaryMarketMatch=${primaryMarketMatch}, rateMatch=${rateMatch}`);
        return regionMatch && primaryMarketMatch && rateMatch;
      });

      // Randomize the filtered creators
      const randomizedCreators = filteredCreators.sort(() => 0.5 - Math.random());
      console.log('Filtered and randomized creators:', randomizedCreators);
      setSimilarCreators(randomizedCreators);
    },
    onError: (error) => {
      console.error('Failed to fetch similar creators:', error);
      setSimilarCreators([]);
    },
  });

  useEffect(() => {
    fetchCreatorDetails();
  }, [creatorId, fetchCreatorDetails]);

  useEffect(() => {
    if (creatorDetails) {
      console.log('Fetching similar creators with region:', creatorDetails.region);
      fetchSimilarCreators();
    }
  }, [creatorDetails, primaryMarket, fetchSimilarCreators]);

  const handleShowMore = () => {
    setVisibleCreators((prev) => prev + 6);
  };

  const toggleView = () => {
    setShowPretty(!showPretty);
  };

  const handleSelectItem = (creatorId) => {
    const selectedCreator = similarCreators.find(
      (creator) => creator.creator === creatorId,
    );
    if (!selectedCreator) return;

    let priceKey = platPromoToKey["TikTok"]["Brand"];
    const price = selectedCreator[priceKey] ? selectedCreator[priceKey] : "Price Unavailable";

    const platformLinkKey = `${"TikTok"} Link`;
    const platformLink = selectedCreator[headerToKeyMap[platformLinkKey]];
    const followingCount = selectedCreator[platformToKey["TikTok"][0]];

    setSelectedCreators((prevCreators) => [
      ...prevCreators,
      {
        id: selectedCreator.creator,
        name: selectedCreator.creator,
        price: price,
        following: followingCount,
        promotionPlatform: "TikTok",
        promotionType: "Brand",
        platformLink: platformLink,
        pfphref: selectedCreator.pfphref,
      }
    ]);

    setSelectedItems(new Set([...selectedItems, creatorId]));
  };

  if (isFetchingCreator) return 'Loading creator details...';
  if (isErrorCreator || !creatorDetails) return 'No creator details found';

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={{ width: '60%', pr: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="h5" sx={{ mr: 2 }}>
              You are looking at
            </Typography>
            <FormControl variant="outlined">
              <InputLabel id="primary-market-select-label">Primary Market</InputLabel>
              <Select
                labelId="primary-market-select-label"
                id="primary-market-select"
                value={primaryMarket}
                onChange={(e) => setPrimaryMarket(e.target.value)}
                label="Primary Market"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {[
                  "Activist",
                  "Artist",
                  "Beauty",
                  "Cars",
                  "Cosplay",
                  "Comedy",
                  "Country",
                  "Dance",
                  "Educational",
                  "Fashion",
                  "Fitness",
                  "Food",
                  "Gaming",
                  "Lifestyle",
                  "Music",
                  "Pets",
                  "Reviews",
                  "Sports",
                  "Tech",
                  "Thirst Trap",
                  "Travel",
                ].map((market) => (
                  <MenuItem key={market} value={market}>
                    {market}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider sx={{ my: 2 }} />
          {showPretty ? (
            <CreatorContainers
              creators={similarCreators.slice(0, visibleCreators)}
              platform="TikTok" // or any other platform you need to pass
              selectedItems={selectedItems}
              onCardClick={handleSelectItem} // Pass the creator object
            />
          ) : (
            <Box sx={{ overflowX: 'auto', mt: 2 }}>
              {/* Add CSV/Table view if needed */}
            </Box>
          )}
          {visibleCreators < similarCreators.length && (
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Button variant="contained" onClick={handleShowMore}>
                Show More
              </Button>
            </Box>
          )}
        </Box>
        <Box sx={{ width: '40%', pl: 2 }}>
          <SidePanel selectedCreators={selectedCreators} campaignDetails={campaignDetails} />
        </Box>
      </Box>
    </>
  );
};

export default SimilarCreatorsDialogContent;
