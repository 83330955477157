import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,

} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import OfferIcon from "@mui/icons-material/LocalOffer";
import PayoutIcon from "@mui/icons-material/MonetizationOn";
import MenuIcon from "@mui/icons-material/Menu";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useAuth from "../../../../Hooks/use-auth";
import client from "../../../../API";
import SendOfferDialog from "./smallercomps/sendoffer";
import PayoutDialog from "./smallercomps/payout";
import OfferDialog from "./smallercomps/offerdialog";
import DeliverableCard from "./smallercomps/deliverablecard";
import PartnershipsDetailView from "./detailview";

const PartnershipsPage = () => {
  const { partnershipId } = useParams();
  const [creator, setCreator] = useState({ name: "", pfphref: "" });
  const [conversationId, setConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [partnership, setPartnershipName] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [sendOfferOpen, setSendOfferOpen] = useState(false);
  const [sendPayoutOpen, setSendPayoutOpen] = useState(false);
  const [viewOfferOpen, setViewOfferOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [offerSent, setOfferSent] = useState(false);
  const [userMessagesCount, setUserMessagesCount] = useState(0);
  const [showMessageCapAlert, setShowMessageCapAlert] = useState(false);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const { getCurrrentUser } = useAuth();
  const [sendingMessage, setSendingMessage] = useState(false);

  const userInfo = getCurrrentUser();

  useEffect(() => {
    if (partnershipId) {
      setLoading(true);
      client.partnerships.fetchPartnership(partnershipId)
        .then((response) => {
          const partnership = response.partnership;
          setPartnershipName(partnership.name);
          if (partnership && partnership.creator) {
            setCreator({
                name: partnership.creator,
                pfphref: partnership.pfphref || "/placeholder.png"
            });
          } else {
            setCreator({ name: "Unknown", pfphref: "/placeholder.png" });
          }

          setConversationId(partnership.conversation_id || null);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching partnership details:", error);
          if (error.response && error.response.status === 403) {
            alert("You may not view this partnership!");
          }
          setLoading(false);
        });
    } else {
      console.error("No partnership ID provided.");
      setLoading(false);
    }
  }, [partnershipId]);

  const fetchMessages = useQuery(
    ['messages', conversationId],
    () => client.partnershipConversations.getMessages(conversationId),
    {
      enabled: !!conversationId,
      onSuccess: (data) => {
        const sortedMessages = data.sort((a, b) => a.id - b.id);
        setMessages(sortedMessages);
        const userMessages = sortedMessages.filter(msg => msg.sender_id === userInfo.id);
        const creatorMessages = sortedMessages.filter(msg => msg.sender_id !== userInfo.id);
        if (creatorMessages.length > 0) {
          const lastCreatorMessageTime = new Date(creatorMessages[creatorMessages.length - 1].created_at).getTime();
          const recentUserMessages = userMessages.filter(msg => new Date(msg.created_at).getTime() > lastCreatorMessageTime);
          setUserMessagesCount(recentUserMessages.length);
        } else {
          setUserMessagesCount(userMessages.length);
        }
      },
    }
  );

  const checkConversation = useMutation(client.partnershipConversations.check, {
    onSuccess: (data) => {
      if (data.exists) {
        setConversationId(data.conversation_id);
      } else {
        createConversation.mutate({ partnership_id: partnershipId, creator_id: creator.name });
      }
    },
    onError: (error) => {
      console.error("Error checking conversation:", error);
    },
  });
  
  useEffect(() => {
    if (partnershipId && creator.name) {
      checkConversation.mutate({ partnership_id: partnershipId, creator_id: creator.name });
    }
  }, [partnershipId, creator.name]);

  const createConversation = useMutation(client.partnershipConversations.create, {
    onSuccess: (data) => {
        setConversationId(data.conversation_id);
        sendMessageMutation.mutate({
            conversation_id: data.conversation_id,
            message,
        });
    },
    onError: (error) => {
        console.error("Error creating conversation:", error);
    },
  });

  const sendMessageMutation = useMutation(client.partnershipConversations.sendMessage, {
    onSuccess: (newMessage) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender_id: userInfo.id, message: newMessage.message, created_at: new Date() },
      ]);
      setMessage("");
      setUserMessagesCount(prevCount => prevCount + 1);
      queryClient.invalidateQueries(['messages', conversationId]);
    },
    onError: (error) => {
      console.error("Error sending message:", error);
    },
  });
  
  const handleSendMessage = () => {
    if (userMessagesCount >= 5) {
      setShowMessageCapAlert(true);
      return;
    }
  
    setSendingMessage(true); // Start loading spinner when sending starts
  
    const messageData = {
      message,
      sender_id: userInfo.id,
    };
  
    if (conversationId) {
      sendMessageMutation.mutate({
        ...messageData,
        conversation_id: conversationId,
      }, {
        onSettled: () => setSendingMessage(false), // Stop spinner on completion
      });
    } else {
      sendMessageMutation.mutate({
        ...messageData,
        partnership_id: partnershipId,
      }, {
        onSettled: () => setSendingMessage(false), // Stop spinner on completion
      });
    }
  };
  const { mutate: updatePartnershipStatus } = useMutation(
    (status) => client.partnerships.updateStatus(partnershipId, { status }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("partnerships");
        setPartnership((prev) => ({ ...prev, status: "Completed" }));
        setConfirmDialogOpen(false);
      },
      onError: (error) => {
        console.error("Error updating partnership status:", error);
      },
    }
  );

  const handleCompletePartnershipClick = () => {
    setConfirmDialogOpen(true);
    handleMenuClose();
  };

  const handleConfirmCompletion = () => {
    updatePartnershipStatus("Completed");
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSendOfferClick = () => {
    setSendOfferOpen(true);
  };

  const handleSendPayoutClick = () => {
    setSendPayoutOpen(true);
  };

  const handleViewOfferClick = (offer) => {
    setSelectedOffer(offer);
    setViewOfferOpen(true);
  };

  const handleViewDetailsClick = () => {
    setViewDetailsOpen(true);
    handleMenuClose();
  };

  const handleOfferSent = () => {
    sendMessageMutation.mutate({
      conversation_id: conversationId,
      message: "An offer was made, see above.",
    });
    setOfferSent(true);
  };

  if (loading) {
    return (
      <Typography
        sx={{
          width: "100vw",
          height: "100vh",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading partnership details...
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 2, display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
      {creator.name && (
        <>
          <Box sx={{ position: "sticky", top: 0, zIndex: 2, paddingBottom: 2 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
              <Box display="flex" alignItems="center">
                <Avatar src={creator.pfphref} alt={creator.name} />
                <Typography variant="h6" sx={{ marginLeft: 2 }}>
                  Partnership with @{creator.name} - {partnership}
                </Typography>
                <Typography variant="h8" sx={{ marginLeft: 2 }}>
                  {partnership.description}
                </Typography>
              </Box>
              <IconButton onClick={handleMenuOpen} color="inherit">
                <MenuIcon />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClose}>Close Partnership</MenuItem>
                <MenuItem onClick={handleViewDetailsClick}>View Partnership Details</MenuItem>
                {partnership.status === "Active" && (
                  <MenuItem onClick={handleCompletePartnershipClick}>Complete Partnership</MenuItem>
                )}
              </Menu>
            </Box>
          </Box>
          <Box
  display="flex"
  flexDirection="column"
  sx={{ 
    minHeight: "400px", 
    overflowY: "auto",
    flexGrow: 1,
  }}
>
  {fetchMessages.isLoading && <Typography>Loading messages...</Typography>}
  {fetchMessages.error && <Typography>Error loading messages</Typography>}
  {messages.map((msg, index) => (
    msg.deliverable ? (
      <DeliverableCard key={index} deliverable={msg.deliverable} onViewOffer={handleViewOfferClick} />
    ) : (
      <Box
        key={index}
        sx={{
          display: 'flex',
          justifyContent: msg.sender_id === userInfo.id ? 'flex-end' : 'flex-start',
          mb: 2,
        }}
      >
        <Box
          sx={{
            maxWidth: '70%',
            p: 2,
            borderRadius: 2,
            backgroundColor: msg.sender_id === userInfo.id ? '#007bff' : '#e4e6eb',
            color: msg.sender_id === userInfo.id ? 'white' : 'black',
            textAlign: msg.sender_id === userInfo.id ? 'right' : 'left',
          }}
        >
          <Typography variant="body1">
            {msg.message}
          </Typography>
          <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
            {new Date(msg.created_at).toLocaleTimeString()} - {msg.read_at ? `Read on ${new Date(msg.read_at).toLocaleString()}` : "Not read yet"}
          </Typography>
        </Box>
      </Box>
    )
  ))}
</Box>

          <Box>
            <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
              <Button variant="contained" startIcon={<OfferIcon />} onClick={handleSendOfferClick} sx={{ mr: 2 }}>
                Send Offer
              </Button>
              <Button variant="contained" startIcon={<PayoutIcon />} onClick={handleSendPayoutClick}>
                Send Payout
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Type a message"
                variant="outlined"
                sx={{ flex: 1 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                InputProps={{
                  style: {
                    color: "white",
                    backgroundColor: "#424242",
                  },
                }}
              />
             <IconButton onClick={handleSendMessage} color="primary" sx={{ ml: 2 }}>
  {sendingMessage ? <CircularProgress size={24} /> : <SendIcon />}
</IconButton>

            </Box>
          </Box>
          <SendOfferDialog
            open={sendOfferOpen}
            onClose={() => setSendOfferOpen(false)}
            partnershipId={partnershipId}
            creatorId={creator.name}
            conversationId={conversationId}
            onOfferSent={handleOfferSent}
          />
          <PayoutDialog
            open={sendPayoutOpen}
            onClose={() => setSendPayoutOpen(false)}
            partnershipId={partnershipId}
            creatorId={creator.name}
            conversationId={conversationId}
          />
          {selectedOffer && (
            <OfferDialog
              open={viewOfferOpen}
              onClose={() => setViewOfferOpen(false)}
              offer={selectedOffer}
            />
          )}
          <PartnershipsDetailView
            open={viewDetailsOpen}
            onClose={() => setViewDetailsOpen(false)}
            partnershipId={partnershipId}
            creator={{ name: creator.name }}
          />
          <Snackbar
            open={showMessageCapAlert}
            autoHideDuration={6000}
            onClose={() => setShowMessageCapAlert(false)}
          >
            <Alert onClose={() => setShowMessageCapAlert(false)} severity="warning" sx={{ width: "100%" }}>
              Messages since last creator response: You are capped at 5
            </Alert>
          
          </Snackbar>
        </>
      )}
       <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
          >
            <DialogTitle>Confirm Completion</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to mark this partnership as completed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleConfirmCompletion} color="primary">Confirm</Button>
            </DialogActions>
          </Dialog>
    </Box>
  );
};

export default PartnershipsPage;
