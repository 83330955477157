const routes = {
  base: "/",
  home: "/home",
  login: "/login",
  register: "/register",
  dashboard: "/dashboard",
  user: "/user",
  zapFeed: "/zap-feed",
  invoicing: "/invoicing",
  campaigns: "/campaigns",
  search: "/search",
  trackers: "/search/trackers",
  blitzpay: "/blitzpay",
  addCreators: "/add-creators/:campaignId",
  resources: "/resources",
  about: "/about",
  creatorSignup: "/creatorsignup",
  company: "/company",
  campaignReport: "/campaigns/:campaignId",
  campaignTimeline: "/campaigns/:campaignId/timelines",
  creatorCampaignTimeline: "/campaigns/:campaignId/creator/:creatorId/timelines", // Add this line for the new route
  creatorMediaKit: "/creators/:creatorId",
  roster: "/roster/:manager", // New route for the AgencyCreatorRoster component
  tempRoster: "/temp_roster/:manager", // New route for the AgencyCreatorRoster component
  creatorConnectReauth: "/creatorconnect/reauth/:creator",
  creatorConnect: "/creatorconnect/:creator",
  creatorLogin: "/creatorconnect/login",
  internalLogin: "/creatorconnect/internal_login",
  creatorConnectCampaigns: "/creatorconnect/campaigns",
  creatorConnectStart: "/creatorconnect/start",
  creatorConnectStripe: "/creatorconnect/start/stripe",
  creatorConnectOnboard: "/creatorconnect/onboard",
  commisions: "/commissions",
  creatorPayoutInvoices: "/creatorinvoice",
  creatorCRMPublic: "/creatorCRM",
  requestAccess: "/requestaccess",
  similarCreators: "/creators/:creatorId/similar",
  demoRegister: "/demoregister",
  about: "/about",
  deliverablePage: "/partnerships/:partnershipId/creator/:creatorId/deliverables", // Add this line for the new route
  tempCreator: "/tempCreator/:creatorId",
  help: "/help",
  assetsManager: "/assets",
  editCampaign: "/editcampaign/:campaignId",
  paypage: "/pay",
  partnershipsPage: "/partnerships/:partnershipId",
  solutons: "/solutions",
  forcreators: "/forcreators",
  agencyLogin: "/agency/login", // Optional agencyName parameter
  agencyLoginA: "/agency/login/:agencyName", // Optional agencyName parameter
  agencyProjects: "/agency/projects",
  agencyStart: "/agency/start",
  creatorConnectDashboard: "/creatorconnect/dashboard",
  creatorConnectCampaigns: "/creatorconnect/campaigns",
  creatorConnectPayoutsInvoicing: "/creatorconnect/payouts-invoicing",
  creatorConnectPitch: "/creatorconnect/pitch",
  creatorConnectPartnerships: "/creatorconnect/partnerships",
  creatorConnectEdit: "/creatorconnect/edit",
  creatorConnectDealsEmails: "/creatorconnect/deals-emails",
  creatorConnectMore: "/creatorconnect/more",
  creatorCampaigns: "/creatorconnect/campaigns/:campaignId",
  whypartner: "/whypartner",
  agencymarket: "/agency/marketplace",
  trackers: "/trackers",
  creaotrParntershipsSpecific: "/creatorconnect/partnerships/:partnershipId",
  agencyPartnerships: "/agency/partnerships",
  agencyParntershipsSpecific: "/agency/partnerships/:partnershipId",
  agencyCampaignsSpecific: "/agency/campaigns/:campaignId/:creatorToken",
  pitchFromZap: "/creatorconnect/pitch/:type/:id",

};

export default routes;
