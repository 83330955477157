import React, { useState } from 'react';
import {
    Box,
    TextField,
    Avatar,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useTheme,
    FormHelperText,
} from '@mui/material';
import { PhotoCamera, ThreeMpSharp } from '@mui/icons-material';
import MuiPhoneInput from '../Components/MuiPhoneInput';
import UploadFileDialogPFP from './onboardupload';

const OnboardPage1 = ({ minWidth, onFormSubmit }) => {
    const theme = useTheme();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [creatorName, setCreatorName] = useState("");
    const [creatorRegion, setCreatorRegion] = useState("");
    const [primaryMarket, setPrimaryMarket] = useState("");
    const [notesContentStyle, setNotesContentStyle] = useState("Tell us about yourself");
    const [race, setRace] = useState("");
    const [gender, setGender] = useState("");
    const [location, setLocation] = useState("");
    const [pfphref, setPfphref] = useState("");

    const [validationError, setValidationError] = useState("");
    const [phoneValidationError, setPhoneValidationError] = useState("");
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

    const handleOpenUploadDialog = () => {
        setIsUploadDialogOpen(true);
    };

    const handleCloseUploadDialog = (uploadedUrl) => {
        if (uploadedUrl) {
          setPfphref(uploadedUrl); // Update the avatar image
        }
        setIsUploadDialogOpen(false);
      };
      

    const SubmitForm = (event) => {
        event.preventDefault();

        if (creatorName.includes(" ")) {
            setValidationError("Creator name cannot contain spaces.");
            return;
        }

        if (phoneNumber.length < 11) {
            setPhoneValidationError("This phone number is invalid");
            return;
        }



        const payload = {
            creator: creatorName || null,
            geolocationgenderethnicity: `${location} / ${gender} / ${race}`,
            geolocation: location,
            gender: gender,
            ethnicity: race,
            primary_market: primaryMarket,
            region: creatorRegion,
            notescontent_style: notesContentStyle || null,
            phone_number: phoneNumber,
            pfphref: pfphref || null,
        };

        onFormSubmit(payload);
    };

    return (
        <>
            <form id="page-1" onSubmit={SubmitForm}>
                <Box sx={{ flexDirection: 'column', display: 'flex', minWidth }}>
                    {/* Avatar Section */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                        <Avatar
                            alt={creatorName}
                            src={pfphref}
                            sx={{ width: 100, height: 100, cursor: 'pointer' }}
                            onClick={handleOpenUploadDialog}
                        >
                            {!pfphref && <PhotoCamera fontSize="large" />} {/* Default Icon */}
                        </Avatar>
                    </Box>

                    <TextField
                        margin="dense"
                        label="Creator Name"
                        type="text"
                        fullWidth
                        value={creatorName}
                        onChange={(e) => setCreatorName(e.target.value)}
                        variant="outlined"
                        required
                        error={!!validationError}
                        helperText={validationError}
                    />
                    <FormControl variant="outlined" fullWidth margin="dense" required>
                        <InputLabel>Creator Region</InputLabel>
                        <Select
                            label="Creator Region"
                            value={creatorRegion}
                            onChange={(e) => setCreatorRegion(e.target.value)}
                        >
                            <MenuItem value="NACAUKAUS">USA, Canada, UK, Australia</MenuItem>
                            <MenuItem value="Europe">Europe</MenuItem>
                            <MenuItem value="Asia">Asia</MenuItem>
                            <MenuItem value="LATAM">LATAM</MenuItem>
                            <MenuItem value="Africa">Africa</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth margin="dense">
                        <InputLabel id="race-select-label">Race (optional)</InputLabel>
                        <Select
                            labelId="race-select-label"
                            value={race}
                            onChange={(e) => setRace(e.target.value)}
                            label="Race (optional)"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Asian">Asian</MenuItem>
                            <MenuItem value="Black">Black</MenuItem>
                            <MenuItem value="Hispanic">Hispanic</MenuItem>
                            <MenuItem value="White">White</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth margin="dense" required>
                        <InputLabel id="gender-select-label">Gender</InputLabel>
                        <Select
                            labelId="gender-select-label"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            label="Gender"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                            <MenuItem value="Non-binary">Non-binary</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Location"
                        type="text"
                        fullWidth
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        variant="outlined"
                        required
                    />
                    <FormControl variant="outlined" fullWidth margin="dense" required>
                        <InputLabel id="primary-market-label">Primary Market</InputLabel>
                        <Select
                            labelId="primary-market-label"
                            value={primaryMarket}
                            onChange={(e) => setPrimaryMarket(e.target.value)}
                            label="Primary Market"
                        >
                            {[
                                "Activist",
                                "Artist",
                                "Beauty",
                                "Cars",
                                "Cosplay",
                                "Comedy",
                                "Country",
                                "Dance",
                                "Educational",
                                "Fashion",
                                "Fitness",
                                "Food",
                                "Gaming",
                                "Lifestyle",
                                "Music",
                                "Pets",
                                "Reviews",
                                "Sports",
                                "Tech",
                                "Thirst Trap",
                                "Travel",
                            ].map((market) => (
                                <MenuItem key={market} value={market}>
                                    {market}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        margin="dense"
                        label="Bio"
                        type="text"
                        fullWidth
                        multiline
                        minRows={4}
                        placeholder="Tell us about yourself"
                        variant="outlined"
                        value={notesContentStyle}
                        onChange={(e) => setNotesContentStyle(e.target.value)}
                    />
                    <MuiPhoneInput
                        country={'us'}
                        preferredCountries={['us']}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        isValid={(value)=>value.length>=10}
                        required
                    />
                </Box>
            </form>
            <UploadFileDialogPFP open={isUploadDialogOpen} onClose={handleCloseUploadDialog} />
        </>
    );
}

export default OnboardPage1;
