import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Paper,
  Box,
  Tabs,
  Tab,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Divider
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import Navbar from '../../Components/Navbar/NavBar';
import client from '../../API'; // Assuming your API client is set up here

const Trackers = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [open, setOpen] = useState(false);
  const [newTracker, setNewTracker] = useState({
    name: '',
    description: '',
    links: [],
    status: 'Active',
    refreshFrequency: '1 day', // Default to 1 day
  });
  const [statusFilter, setStatusFilter] = useState('All');
  const [selectedTrackers, setSelectedTrackers] = useState([]);

  // Fetch trackers using react-query
  const { data: trackers, isLoading, error, refetch } = useQuery('trackers', async () => {
    const response = await client.trackers.fetch(); // Make sure this is the correct API call
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Error fetching trackers');
    }
  });

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Handle open and close of dialog
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle input changes for the new tracker
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewTracker(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Add new tracker and refetch data
  const handleAddTracker = async () => {
    const trackerWithDate = {
      ...newTracker,
      dateCreated: new Date().toLocaleDateString(),
    };
    try {
      const response = await client.trackers.create(trackerWithDate);
      if (response.status === 200) {
        refetch(); // Refetch trackers after creation
        handleClose(); // Close the dialog after adding the tracker
      }
    } catch (error) {
      console.error('Error creating tracker:', error);
    }
  };

  // Filter trackers by active tab
  const filteredTrackers = trackers?.filter(tracker => {
    if (activeTab === 'all') return true;
    return tracker.status.toLowerCase() === activeTab;
  });

  // Handle tracker selection
  const handleTrackerSelect = (trackerId) => {
    setSelectedTrackers(prevSelected => {
      if (prevSelected.includes(trackerId)) {
        return prevSelected.filter(id => id !== trackerId);
      }
      return [...prevSelected, trackerId];
    });
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Navbar />
      <Paper elevation={2} square sx={{ padding: 2, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Trackers
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            <AddIcon /> Add Tracker
          </Button>
          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel>Status</InputLabel>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Paper>

      {/* Tabs for switching between statuses */}
      <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 4 }}>
        <Tab label="All" value="all" />
        <Tab label="Active" value="active" />
        <Tab label="Completed" value="completed" />
      </Tabs>

      {/* Render Trackers */}
      {isLoading ? (
        <Typography>Loading trackers...</Typography>
      ) : error ? (
        <Typography>Error fetching trackers: {error.message}</Typography>
      ) : (
        <Grid container spacing={3}>
          {filteredTrackers?.map((tracker) => (
            <Grid item xs={12} sm={6} md={4} key={tracker.id}>
              <Card
                onClick={() => handleTrackerSelect(tracker.id)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 6,
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  <Box display="flex" justifyContent="space-between">
                    <Checkbox
                      checked={selectedTrackers.includes(tracker.id)}
                      onChange={() => handleTrackerSelect(tracker.id)}
                    />
                    <Typography variant="h6">{tracker.name}</Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {tracker.description}
                  </Typography>
                  <Typography variant="body2">Links: {tracker.links.length}</Typography>
                  <Typography variant="body2">Created: {tracker.date_created}</Typography>
                  <Typography variant="body2">Status: {tracker.status}</Typography>
                  <Typography variant="body2">Refresh: {tracker.refresh_frequency}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Dialog for adding a new tracker */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New Tracker</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="dense"
            label="Tracker Name"
            name="name"
            value={newTracker.name}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Description"
            name="description"
            value={newTracker.description}
            onChange={handleInputChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Refresh Frequency</InputLabel>
            <Select
              name="refreshFrequency"
              value={newTracker.refreshFrequency}
              onChange={handleInputChange}
            >
              <MenuItem value="1 day">1 day</MenuItem>
              <MenuItem value="0">Manual</MenuItem>
            </Select>
          </FormControl>
          {/* Add link fields dynamically based on refresh frequency */}
          {Array.from({ length: newTracker.refreshFrequency === '0' ? 15 : 5 }).map((_, i) => (
            <TextField
              key={i}
              fullWidth
              margin="dense"
              label={`Link ${i + 1}`}
              value={newTracker.links[i] || ''}
              onChange={(e) => {
                const updatedLinks = [...newTracker.links];
                updatedLinks[i] = e.target.value;
                setNewTracker(prevState => ({
                  ...prevState,
                  links: updatedLinks,
                }));
              }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
          <Button onClick={handleAddTracker} color="primary">Create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Trackers;
