import React, { useState, useMemo, useEffect } from 'react';
import { Box, Typography, Paper, Button, CircularProgress, Tabs, Tab, Badge, IconButton, Grid, Card, CardContent, CardActions, CardHeader } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import API from '../../../API';
import CreatorCampaignPitchedDialog from './creatorcomponents/creatorCampaignPitchedDialog';
import { useNavigate } from 'react-router-dom';

const CheckIsPitched = (campaign, username) => {
  const creator = campaign.creators.find(c => c.id === username);
  return (creator?.status === undefined || creator?.status === 'Pitched') && campaign.campaign_status === 'Launched';
}

const CreatorCampaigns = () => {
  const { creatorToken } = useCreatorAuth();
  const username = creatorToken?.creator_user?.username;
  const token = creatorToken?.token;

  const [currentCampaign, setCurrentCampaign] = useState(-1);
  const [currentFilter, setCurrentFilter] = useState('launched');
  const [openPitch, setOpenPitch] = useState(false);

  const navigate = useNavigate();



  const handleClosePitch = () => {
    setOpenPitch(false);
  };


  const fetchCampaigns = async () => {
    if (!token) return [];
    return API.creatorConnect.campaigns(token);
  };

  const { isLoading, error, data: campaigns, refetch: refetchCampaigns } = useQuery(
    ['campaigns', 'creator', username],
    fetchCampaigns,
    {
      initialData: [],
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    if (!openPitch) {
      refetchCampaigns();
    }
  }, [openPitch, refetchCampaigns]);

  const filteredCampaigns = useMemo(() => campaigns.filter((campaign) => {
    const creator = campaign.creators.find(c => c.id === username);
    let isAcceptedCreator = creator?.status !== undefined && creator.status !== 'Declined' && creator.status !== 'Dropped' && creator.status !== 'Pitched';
    let isValidSelection = true;
    switch (currentFilter) {
      default:
        isValidSelection = isAcceptedCreator && campaign.campaign_status === 'Launched';
        break;
      case 'Pitched':
        isValidSelection = (creator?.status === undefined || creator?.status === 'Pitched') && campaign.campaign_status === 'Launched';
        break;
      case 'completed':
        isValidSelection = isAcceptedCreator && campaign.campaign_status === 'Completed';
        break;
    }
    return isValidSelection;
  }), [campaigns, currentFilter, username]);

  const pitchedCount = useMemo(() => campaigns.reduce((amount, campaign) => {
    const creator = campaign.creators.find(c => c.id === username);
    if ((creator?.status === undefined || creator?.status === 'Pitched') && campaign.campaign_status === 'Launched') {
      amount++;
    }
    return amount;
  }, 0), [campaigns, username]);

  if (isLoading) return <CircularProgress sx={{ color: 'white' }} />;
  if (error) return <Typography sx={{ color: 'error' }}>Error fetching campaigns: {(error.response && error.response.data) ? error.response.data.error : error.message}</Typography>;

  const getOffer = (campaign) => {
    const creators = campaign.creators;
    if (!creators || creators.length === 0) return 'N/A';
    const creator = creators.find(c => c.id === username);
    if (!creator) return 'N/A';
    const agencyRate = parseFloat(creator.agencyRate);
    const price = parseFloat(creator.price);
    return agencyRate ? `$${agencyRate.toFixed(2)}` : price ? `$${price.toFixed(2)}` : 'N/A';
  };

  const handleClickCampaign = (campaignId) => {
    const selected = campaigns.find((campaign) => campaign.id == campaignId);
    const isPitch = CheckIsPitched(selected, username);
    if (isPitch) {
      setCurrentCampaign(campaignId);
      setOpenPitch(true);
    }
    else {
      console.log(`Going to campaign ${campaignId}`)
      navigate(`${campaignId}`); //Since theres no starting /, uses relative linking, so it goes to current page + /1
    }
  };

  return (
    <Box>
      <Paper elevation={2} square>
        <Tabs value={currentFilter} onChange={(event, value) => setCurrentFilter(value)} centered>
          <Tab label="Current" value='launched'></Tab>
          <Tab label={<Badge badgeContent={pitchedCount} color='secondary' overlap='rectangular' sx={{ padding: 1 }}>Offered</Badge>} value='Pitched'></Tab>
          <Tab label="Completed" value='completed'></Tab>
        </Tabs>
      </Paper>
      <Typography variant="h6" gutterBottom sx={{ p: 2, marginBlock: 1 }}>
        Your Campaigns
      </Typography>
      <Grid container spacing={3} sx={{ paddingInline: { xs: 1, md: 4 } }}>
        {filteredCampaigns && filteredCampaigns.length > 0 ? filteredCampaigns.map(campaign => (
          <Grid item xs={12} sm={6} md={3} key={campaign.id}>
            <Card>
              <CardHeader title={campaign.name} titleTypographyProps={{ sx: { textTransform: 'capitalize' } }} subheader={campaign.campaign_status}></CardHeader>
              <CardContent>
                <Typography variant="body2">Offer: {getOffer(campaign)}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" onClick={() => handleClickCampaign(campaign.id)}>
                  {currentFilter != "Pitched" ? "Manage Campaign" : "See Pitch"}
                </Button>
                <IconButton onClick={() => {/* handle share */ }}>
                  <ShareIcon />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        )) : (
          <Grid item xs={12}>
            <Typography align="center">No campaigns available</Typography>
          </Grid>
        )}
      </Grid>
      <CreatorCampaignPitchedDialog openDialog={openPitch} handleCloseDialog={handleClosePitch} campaignId={currentCampaign} />
    </Box>
  );
};

export default CreatorCampaigns;
