import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import SelectPopup from '../../../../Components/SelectPopup';
import FilterPopup from '../../../../Components/FilterPopup';

export default function MinMaxFilter({ minValue, maxValue, onChangeValue, name, width }) {
    const [internalMin, setInternalMin] = useState(0);
    const [internalMax, setInternalMax] = useState(0);
    const [error, setError] = useState(null);
    const onOpen = () => {
        setInternalMax(!!maxValue ? maxValue : 0);
        setInternalMin(!!minValue ? minValue : 0);
        setError(null);
    }

    const onClear = () => {
        setInternalMax(0);
        setInternalMin(0);
        setError(null);
        onChangeValue({ min: null, max: null });
    };

    const onApply = () => {
        if (internalMin == null || internalMax == null) {
            setError('Please enter both minimum and maximum values.');
        } else if (internalMin > internalMax) {
            setError('The minimum can\'t be more than the maximum')
        }
        else if (internalMin == internalMax) {
            setError('The minimum and maximum cannot be the same')
        }
        else {
            setError(null)
            onChangeValue({ min: internalMin, max: internalMax });
        }
    };

    const setNum = (event, setter) => {
        if (!Number.isNaN(event.target.valueAsNumber))
            setter(event.target.valueAsNumber)
    }

    return (
        <SelectPopup value={(minValue !== null) ? '...' : ''} label={name} inputStyle={{ width: !!width ? width : '7rem' }} onOpenPopup={onOpen} >
            <FilterPopup boxStyle={{ padding: 2 }} onApply={onApply} onClear={onClear}>
                <Typography variant='subtitle1' color='text.secondary' ><b>Show creators between:</b></Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1, alignItems: 'baseline', marginBlockStart: 1 }}>
                    <TextField value={internalMin} onChange={(event) => setNum(event, setInternalMin)} error={!!error} helperText={error} variant='outlined' label={`Min. ${name}`} sx={{ width: '10rem' }} size='small' type='number' InputProps={{ inputProps: { min: 0 } }}></TextField>
                    And
                    <TextField value={internalMax} onChange={(event) => setNum(event, setInternalMax)} error={!!error} variant='outlined' label={`Max. ${name}`} sx={{ width: '10rem' }} size='small' type='number' InputProps={{ inputProps: { min: 0 } }}></TextField>
                </Box>
            </FilterPopup>
        </SelectPopup>
    )
}