import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import BlitzHeader from "../../Components/BlitzHeader";
import MenuIcon from "@mui/icons-material/Menu";
import frame from "../../Components/globalAssets/frame.png";
import imageOnRight from "../../Components/globalAssets/flash.gif";
import PaymentSection from "./homeExtended";
import MarketplaceSection from "./marketplace";
import AIManagerSection from "./aimanager";
import CreatorBanner from "./topcreators";
import ClientsSection from "./clientSection";
import routes from "../../Config/routes";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import GroupIcon from "@mui/icons-material/Group";
import CampaignIcon from "@mui/icons-material/Campaign";
import BoltIcon from "@mui/icons-material/Bolt";
import PaidIcon from "@mui/icons-material/Paid";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessIcon from "@mui/icons-material/Business";

const Home = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [creatorMenuAnchorEl, setCreatorMenuAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleSignUp = () => navigate(routes.login);
  const handleCreatorLogin = () => navigate(routes.creatorConnectStart);
  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (benefit) => {
    setAnchorEl(null);
    navigate(`${routes.solutons}#${benefit}`);
  };

  const handleCreatorMenuClick = (event) => {
    setCreatorMenuAnchorEl(event.currentTarget);
  };

  const handleCreatorMenuClose = () => {
    setCreatorMenuAnchorEl(null);
  };

  const handleCreatorMenuItemClick = (benefit) => {
    setCreatorMenuAnchorEl(null);
    navigate(`${routes.forcreators}#${benefit}`);
  };

  return (
    <>
      <BlitzHeader>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          {/* Logo */}
          <Box
            display="flex"
            alignItems="center"
            flex={{ xs: 1, lg: 1 }}
            justifyContent="flex-start"
          >
            {/* BlitzLogo can be placed here */}
          </Box>

          {/* Menu */}
          <Box
            display="flex"
            alignItems="center"
            flex={{ xs: 2, lg: 1 }}
            justifyContent="center"
          >
            {isMobile ? (
              <IconButton color="inherit" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Box>
                <Button
                  color="inherit"
                  variant="text"
                  onClick={() => navigate(routes.about)}
                >
                  About
                </Button>
                <Button
                  color="inherit"
                  variant="text"
                  onMouseEnter={handleMenuClick}
                >
                  Solutions
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  onMouseLeave={handleMenuClose}
                  PaperProps={{
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      padding: "20px",
                      gap: "20px",
                    },
                  }}
                >
                  <MenuItem onClick={() => handleMenuItemClick("blitzPay")}>
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <Typography>BlitzPay - Accounts Payable</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("creatorSourcing")}
                  >
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <Typography>Creator Sourcing</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick("aiCampaignManagement")
                    }
                  >
                    <ListItemIcon>
                      <CampaignIcon />
                    </ListItemIcon>
                    <Typography>AI Campaign Management</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleMenuItemClick("acceleratedCollaborations")
                    }
                  >
                    <ListItemIcon>
                      <BoltIcon />
                    </ListItemIcon>
                    <Typography>Accelerated Creator Collaborations</Typography>
                  </MenuItem>
                </Menu>
                <Button
                  color="inherit"
                  variant="text"
                  onMouseEnter={handleCreatorMenuClick}
                >
                  For Creators
                </Button>
                <Menu
                  anchorEl={creatorMenuAnchorEl}
                  open={Boolean(creatorMenuAnchorEl)}
                  onClose={handleCreatorMenuClose}
                  onMouseLeave={handleCreatorMenuClose}
                  PaperProps={{
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      padding: "20px",
                      gap: "20px",
                    },
                  }}
                >
                  <MenuItem
                    onClick={() => handleCreatorMenuItemClick("getPaidFaster")}
                  >
                    <ListItemIcon>
                      <PaidIcon />
                    </ListItemIcon>
                    <Typography>Get Paid Faster</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleCreatorMenuItemClick("manageDeals")}
                  >
                    <ListItemIcon>
                      <HandshakeIcon />
                    </ListItemIcon>
                    <Typography>Manage Brand Deals and Collabs</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleCreatorMenuItemClick("aiSalesManager")}
                  >
                    <ListItemIcon>
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <Typography>AI Sales Manager</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      handleCreatorMenuItemClick("businessSuite")
                    }
                  >
                    <ListItemIcon>
                      <BusinessIcon />
                    </ListItemIcon>
                    <Typography>Creator Business Suite</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>

          {/* Login and Creator Login */}
          <Box
            flex={{ xs: 1, lg: 1 }}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button color="inherit" variant="text" onClick={handleSignUp}>
              Login
            </Button>
            <Button
              color="inherit"
              variant="text"
              onClick={handleCreatorLogin}
            >
              Creator Login
            </Button>
          </Box>
        </Box>

        {/* Mobile Dropdown Menu */}
        {isMobile && (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => navigate(routes.about)}>About</MenuItem>
            <MenuItem onClick={() => handleMenuItemClick("blitzPay")}>
              Solutions
            </MenuItem>
            <MenuItem onClick={() => navigate(routes.forcreators)}>
              For Creators
            </MenuItem>
          </Menu>
        )}
      </BlitzHeader>

      {/* Main content section */}
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          color: "#000", // Light mode text color
          backgroundColor: "#ececec", // Light mode background color
          px: 2,
          py: 10, // Consistent padding across sections
          mt: { xs: 8, md: 0 }, // Add margin-top for mobile devices
        }}
      >
        {/* Left Section */}
        <Box
          flex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
            textAlign: { xs: "center", md: "left" },
            mb: { xs: 4, md: 0 },
            px: { xs: 2, md: 4 }, // Add padding to prevent text cutoff
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
            Craft AI-enabled influencer campaigns without costly subscription
            fees
          </Typography>
          <Typography variant="subtitle1" sx={{ mb: 3 }}>
            Sourcing creators is hard, but you don’t have to do it alone. Blitz
            allows you to source, communicate with, and pay creators all in one
            place.
          </Typography>
          <Button
            variant="contained"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: "1rem",
              textTransform: "none",
              backgroundColor: "#7b2a2a", // Light mode primary color
              color: "#fff",
            }}
            onClick={handleAccess}
          >
            Request Access
          </Button>
        </Box>

        {/* Right Section with custom frame */}
        <Box
          flex={1}
          sx={{
            display: { xs: "none", md: "flex" }, // Hide on mobile, show on desktop
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            maxWidth: "100%", // Prevent overflow on mobile
            px: { xs: 2, md: 4 }, // Add padding to prevent frame/photo cutting over
            mb: { xs: 4, md: 0 },
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "300px", // Fixed width for desktop
              height: "600px", // Fixed height for desktop
            }}
          >
            <img
              src={frame}
              alt="Frame"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
              }}
            />
            <img
              src={imageOnRight}
              alt="Preview"
              style={{
                width: "85%",
                height: "85%",
                position: "absolute",
                top: "7.5%",
                left: "7.5%",
                zIndex: 0,
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* Content Sections */}
      <PaymentSection />
      <CreatorBanner />
      <MarketplaceSection />
      <ClientsSection />
      <AIManagerSection />

      {/* Footer */}
      <Box
        sx={{
          mt: 4,
          textAlign: "center",
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          sx={{ mt: 4, textAlign: "center" }}
        >
          © 2024 Pangea, Inc. All Rights Reserved.
        </Typography>
      </Box>
    </>
  );
};

export default Home;
