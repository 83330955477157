// CampaignCreationDialog.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import SimilarCreatorsDialogContent from './similarcreatorsdialogcontent';

const CampaignCreationDialog = ({ open, onClose, creatorId }) => {
  const [selectedCreators, setSelectedCreators] = useState(new Set());

  const handleSelect = (newSelectedItems) => {
    setSelectedCreators(newSelectedItems);
  };

  const handleCreateCampaign = () => {
    console.log('Selected creators for campaign:', Array.from(selectedCreators));
    // Implement the backend call to create a campaign with selected creators
  };

  // Replace this with actual campaign details if available
  const campaignDetails = {
    name: 'Example Campaign',
    brief: 'This is an example campaign brief.',
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>Create a Campaign</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Select similar creators to add to your campaign:
        </Typography>
        <SimilarCreatorsDialogContent
          creatorId={creatorId}
          onSelect={handleSelect}
          campaignDetails={campaignDetails}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleCreateCampaign} color="primary">
          Create Campaign
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignCreationDialog;
