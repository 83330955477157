import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button, CircularProgress, Grid } from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { useCreatorAuth } from '../../../../Hooks/creator-use-auth';
import API from '../../../../API';
import CreatorHeader from '../../../../Components/CreatorHeader';
import PartnershipConversationDialog from './creatorpartnershipconversationdialog';

const CreatorPartnerships = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPartnership, setCurrentPartnership] = useState(null);
  const { creatorToken } = useCreatorAuth();
  const navigate = useNavigate(); // Initialize navigate

  const handleOpenDialog = (partnership) => {
    navigate(`/creatorconnect/partnerships/${partnership.id}`); // Navigate to partnership page
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentPartnership(null);
  };

  const { isLoading, error, data: partnerships, refetch: refetchPartnerships } = useQuery(
    ['creator', 'partnerships'],
    API.creatorConnect.getPartnerships,
    {
      initialData: [],
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    if (!openDialog) {
      refetchPartnerships();
    }
  }, [openDialog, refetchPartnerships]);

  const filteredPartnerships = useMemo(() => partnerships.filter(partnership => partnership.status !== 'DELETED'), [partnerships]);

  if (isLoading) return <CircularProgress sx={{ color: 'white' }} />;
  if (error) return <Typography sx={{ color: 'error' }}>Error fetching partnerships: {error.message}</Typography>;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        All Partnerships
      </Typography>
      
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {filteredPartnerships.length > 0 ? filteredPartnerships.map(partnership => (
          <Grid item xs={12} sm={6} md={4} key={partnership.id}>
            <Card>
              <CardContent>
                <Typography variant="h6">{partnership.name}</Typography>
                <Typography color="textSecondary">{partnership.description}</Typography>
                <Typography color="textSecondary">Status: {partnership.status}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" onClick={() => handleOpenDialog(partnership)}>
                  Click to Manage Partnership
                </Button>
              </CardActions>
            </Card>
          </Grid>
        )) : (
          <Grid item xs={12}>
            <Typography align="center">No partnerships available</Typography>
          </Grid>
        )}
      </Grid>
      
      {currentPartnership && (
        <PartnershipConversationDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          partnership={currentPartnership}
        />
      )}
    </Box>
  );
};

export default CreatorPartnerships;
