import React, { useState, useMemo, useEffect } from 'react';
import { 
  Box, Typography, Paper, Button, CircularProgress, Tabs, Tab, Badge, 
  IconButton, Grid, Card, CardContent, CardActions, CardHeader, Avatar 
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useQuery } from 'react-query';
import useAgencyAuth from '../../Hooks/agency-use-auth';
import AgencyNavbar from '../../Components/Navbar/agencyNavBar';
import AgencyCampaignPitchedDialog from './components/agencypitcheddialog.js';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { ConfigValue } from '../../Config';

const AgencyCampaigns = () => {
  const { getToken, getCompanyInfo } = useAgencyAuth();
  const token = getToken();
  const agencyName = getCompanyInfo()?.name;
  const [currentFilter, setCurrentFilter] = useState('launched');
  const [loadingTabSwitch, setLoadingTabSwitch] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [currentCreator, setCurrentCreator] = useState(null); // new state for current creator
  const [openPitch, setOpenPitch] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const fetchAgencyCampaigns = async () => {
    if (!token || !agencyName) return [];

    const url = `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/agency/agencyCampaigns?agencyName=${encodeURIComponent(agencyName)}`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch campaigns');
    }

    const data = await response.json();
    return data;
  };

  const { isLoading, error, data: campaigns, refetch: refetchCampaigns } = useQuery(
    ['campaigns', 'agency', agencyName],
    fetchAgencyCampaigns,
    {
      initialData: [],
      keepPreviousData: false,
      onSettled: () => setLoadingTabSwitch(false),
    }
  );

  useEffect(() => {
    refetchCampaigns();
  }, [refetchCampaigns]);

  const handleTabChange = (event, value) => {
    setCurrentFilter(value);
    setLoadingTabSwitch(true);
    refetchCampaigns();
  };

  const handleManageCampaign = (campaignId, creatorId) => { // now accepts creatorId
    const selectedCampaign = campaigns.find(campaign => campaign.id === campaignId);
    const selectedCreator = selectedCampaign.creators.find(creator => creator.creator === creatorId);

    if (selectedCreator?.status === 'Accepted') {
      // Navigate to the specific campaign page
      navigate(`/agency/campaigns/${campaignId}/${creatorId}`);
    } else {
      // Open the pitched dialog for any other status
      setCurrentCampaign(campaignId);
      setCurrentCreator(creatorId);
      setOpenPitch(true);
    }
  };

  const handleClosePitch = () => {
    setOpenPitch(false);
  };

  const filteredCampaigns = useMemo(() => {
    return campaigns.filter((campaign) => {
      switch (currentFilter) {
        case 'Pitched':
          return campaign.creators.some(creator => creator.status === 'Pitched');
        case 'completed':
          return campaign.campaign_status === 'Completed';
        default:
          return campaign.campaign_status === 'Launched';
      }
    });
  }, [campaigns, currentFilter]);

  const pitchedCount = useMemo(() => {
    return campaigns.reduce((amount, campaign) => {
      if (campaign.creators.some(creator => creator.status === 'Pitched')) {
        amount++;
      }
      return amount;
    }, 0);
  }, [campaigns]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'primary.main' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography sx={{ color: 'error' }}>
        Error fetching campaigns: {(error.response && error.response.data) ? error.response.data.error : error.message}
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="row">
      {/* Navbar */}
      <AgencyNavbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Your Agency's Campaigns {agencyName}
        </Typography>
        <Paper elevation={2} sx={{ marginBlockEnd: 2 }}>
          <Tabs value={currentFilter} onChange={handleTabChange} centered>
            <Tab label="Current" value='launched' />
            <Tab label={<Badge badgeContent={pitchedCount} color='secondary' overlap='rectangular'>Offered</Badge>} value='Pitched' />
            <Tab label="Completed" value='completed' />
          </Tabs>
        </Paper>

        {loadingTabSwitch && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <CircularProgress sx={{ color: 'primary.main' }} />
          </Box>
        )}

        <Grid container spacing={3} sx={{ paddingInline: { xs: 1, md: 4 } }}>
          {filteredCampaigns && filteredCampaigns.length > 0 ? filteredCampaigns.map(campaign => (
            <Grid item xs={12} sm={6} md={4} key={campaign.id}>
              <Card>
                <CardHeader
                  title={campaign.name}
                  subheader={campaign.campaign_status}
                />
                <CardContent>
                  {campaign.creators.map(creator => (
                    <Box key={creator.creator} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Avatar 
                        alt={creator.creator} 
                        src={creator.pfphref} 
                        sx={{ width: 64, height: 64, marginRight: 2 }} 
                      />
                      <Typography>
                        {creator.creator} ({creator.status}) - Price: {creator.agencyRate}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleManageCampaign(campaign.id, creator.creator)} // pass creator id here
                      >
                        Manage Campaign
                      </Button>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <IconButton onClick={() => {/* handle share */ }}>
                    <ShareIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          )) : (
            <Grid item xs={12}>
  {isLoading ? (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
      <CircularProgress sx={{ color: 'primary.main' }} />
    </Box>
  ) : (
    <Typography align="center">No campaigns available</Typography>
  )}
</Grid>

          )}
        </Grid>
        
        <AgencyCampaignPitchedDialog 
          openDialog={openPitch} 
          handleCloseDialog={handleClosePitch} 
          campaignId={currentCampaign} 
          creatorId={currentCreator} // pass creator id to the dialog
        />
      </Box>
    </Box>
  );
};

export default AgencyCampaigns;
