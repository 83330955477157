import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import client from "../../../../API";
import useAuth from "../../../../Hooks/use-auth";
import { StyledTableCell, StyledTableRow } from "../../../../Utils/styledcell";

const Tools = ({ campaignDetails, onUpdate }) => {
  const { getCurrrentUser } = useAuth();
  const currentUser = getCurrrentUser();
  const isTcc = currentUser?.company_name === "TCC";

  const [email, setEmail] = useState("");
  const [bonusDialogOpen, setBonusDialogOpen] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [bonusAmount, setBonusAmount] = useState("");
  const [cameThrough, setCameThrough] = useState(false);
  const [poNumber, setPoNumber] = useState(campaignDetails?.poNumber || "");
  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [isChangingManager, setIsChangingManager] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    const bonuses = campaignDetails?.tools?.filter((tool) => tool.type === "bonus") || [];
    setBonuses(bonuses);
  }, [campaignDetails]);

  useEffect(() => {
    if (isTcc) {
      client.companies.listUsers().then(setManagers).catch(console.error);
    }
  }, [isTcc]);

  const addBonus = useMutation(
    (newBonus) => client.campaigns.addBonus(campaignDetails.id, newBonus),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["campaign", campaignDetails.id]);
        alert("Bonus added successfully!");
        onUpdate();
      },
      onError: (error) => {
        alert(`Error adding bonus: ${error.message}`);
      },
    }
  );

  const changeManager = useMutation(
    client.campaigns.updateManager,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["campaign", campaignDetails.id]);
        alert("Manager changed successfully!");
        onUpdate();
      },
      onError: (error) => {
        alert(`Error changing manager: ${error.message}`);
      },
    }
  );

  const handleBonusSubmit = () => {
    addBonus.mutate({
      creator_id: selectedCreator,
      bonusAmount,
      cameThrough,
    });
    setBonusDialogOpen(false);
    resetBonusForm();
  };

  const resetBonusForm = () => {
    setBonusAmount("");
    setSelectedCreator(null);
    setCameThrough(false);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleShare = () => {
    if (email) {
      // Add your share campaign mutation or function here
      alert("Campaign shared!"); // Replace with actual share logic
    } else {
      alert("Please enter an email address.");
    }
  };

  const handlePoNumberChange = (event) => {
    setPoNumber(event.target.value);
    onUpdate({ ...campaignDetails, poNumber: event.target.value });
  };

  const handleManagerChange = (event) => {
    setSelectedManager(event.target.value);
    setIsChangingManager(true);
  };

  const applyManagerChange = () => {
    const managerIndex = Number.parseInt(selectedManager);
    changeManager.mutate({
      campaignId: campaignDetails.id,
      manager: {
        name: `${managers[managerIndex].first_name} ${managers[managerIndex].last_name}`,
        email: managers[managerIndex].email,
        phone_number: "",
      },
    });
    setIsChangingManager(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Share this Campaign
      </Typography>
      <TextField
        label="Email Address"
        type="email"
        fullWidth
        value={email}
        onChange={handleEmailChange}
        variant="outlined"
        margin="normal"
        sx={{
          mb: 2,
        }}
      />
      <Button variant="contained" onClick={handleShare} fullWidth sx={{ mb: 2 }}>
        Add Email
      </Button>

      {campaignDetails?.shared_with && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">
            Shared With:
          </Typography>
          <Typography>{campaignDetails.shared_with.join(", ")}</Typography>
        </Box>
      )}

      <Typography variant="h6" sx={{ mt: 2 }}>
        PO Number
      </Typography>
      <TextField
        label="PO Number"
        fullWidth
        value={poNumber}
        onChange={handlePoNumberChange}
        variant="outlined"
        margin="normal"
        sx={{
          mb: 2,
        }}
      />

      {isTcc && (
        <>
          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Change Campaign Manager
          </Typography>
          <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
            <InputLabel >Select Manager</InputLabel>
            <Select
              value={selectedManager}
              onChange={handleManagerChange}
              label="Select Manager"
            >
              {managers.map((manager, index) => (
                <MenuItem key={manager.id} value={index}>
                  {manager.first_name} {manager.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="subtitle1" color='textSecondary' sx={{ mb: 1 }}>
            Current Manager
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap:1, marginBlockEnd:2 }}>
            <Typography variant="body2">
              <strong>Name: </strong> 
              {campaignDetails.campaign_manager?.name}
            </Typography>
            <Typography variant="body2">
              <strong> Email: </strong> 
              {campaignDetails.campaign_manager?.email}
            </Typography>
            <Typography variant="body2">
              <strong> Phone: </strong> 
              {campaignDetails.campaign_manager?.phone_number}
            </Typography>
          </Box>
          {isChangingManager && (
            <Button variant="contained" onClick={applyManagerChange} fullWidth sx={{ mb: 2 }}>
              Apply Manager Change
            </Button>
          )}
        </>
      )}

      <Typography variant="h6" sx={{ mt: 2 }}>
        Bonuses
      </Typography>
      <Button variant="contained" onClick={() => setBonusDialogOpen(true)} sx={{ mb: 2 }}>
        Add Bonus
      </Button>

      {bonusDialogOpen && (
        <Dialog open={bonusDialogOpen} onClose={() => setBonusDialogOpen(false)}>
          <DialogTitle>Assign Bonus</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Select a creator</InputLabel>
              <Select
                value={selectedCreator}
                onChange={(e) => setSelectedCreator(e.target.value)}
                displayEmpty
                fullWidth
                sx={{ mt: 2 }}
              >
                {campaignDetails?.creators?.map((creator) => (
                  <MenuItem key={creator.id} value={creator.id}>
                    {creator.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              label="Bonus Amount ($)"
              type="number"
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
              sx={{
                mt: 2,
                mb: 2,
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}>
              <Checkbox
                checked={cameThrough}
                onChange={(e) => setCameThrough(e.target.checked)}
              />
              <Typography>Came Through</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setBonusDialogOpen(false)}>Close</Button>
            <Button variant="contained" onClick={handleBonusSubmit}>
              Add Bonus
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Table size="small" sx={{ mt: 2 }}>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Creator Name</StyledTableCell>
            <StyledTableCell>Bonus Amount</StyledTableCell>
            <StyledTableCell>Came Through</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {bonuses?.map((bonus, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>
                {campaignDetails?.creators?.find((c) => c.id === bonus.creator_id)?.name}
              </StyledTableCell>
              <StyledTableCell>{bonus.bonusAmount}</StyledTableCell>
              <StyledTableCell>{bonus.cameThrough ? "Yes" : "No"}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Tools;
