import React from "react";
import { Box, Button, Typography } from "@mui/material";
import marketplaceImage from "../../Components/globalAssets/blitzLogo.png"; // Replace with actual image path
import { useNavigate } from "react-router-dom";
import routes from "../../Config/routes"; // Import the routes object

const MarketplaceSection = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box sx={{ backgroundColor: "#d3d3d3", py: 10 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" }, // Flipped layout
          justifyContent: "center",
          alignItems: "center",
          color: "#000", // Light mode text color
          px: 0, // Removed horizontal padding to make it flush
          py: 10, // Adjusted vertical padding as needed
          maxWidth: "100%", // Set to full width to remove any side margins
          margin: "0 auto", // Center align within parent container
          boxShadow: "none", // Removed box shadow to eliminate the lifted effect
        }}
      >
        {/* Right Section with Image (flipped) */}
        <Box
          flex={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: { xs: 4, md: 0 },
          }}
        >
          <img
            src={marketplaceImage} // Use your marketplace-related image here
            alt="Marketplace"
            style={{
              width: "100%",
              maxWidth: "450px", // Adjusted for the new broader layout
            }}
          />
        </Box>

        {/* Left Section with Text (flipped) */}
        <Box
          flex={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { xs: "center", md: "flex-start" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
            Access our global creator marketplace
          </Typography>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Explore a worldwide network of content creators and brands. Blitz seamlessly connects everyone all in one place.
          </Typography>
          <Button
            variant="contained"
            sx={{
              px: 4,
              py: 1.5,
              fontSize: "1rem",
              textTransform: "none",
              backgroundColor: "#7b2a2a", // Light mode primary color
              color: "#fff",
            }}
            onClick={handleAccess}
          >
            Request Access
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketplaceSection;
