import React from 'react';
import { Outlet } from 'react-router-dom';
import CreatorHeader from './CreatorHeader';

const CreatorConnectRoute = () => {
    return (
        <CreatorHeader>
            <Outlet></Outlet>
        </CreatorHeader>
    );
};

export default CreatorConnectRoute;
