import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress
} from "@mui/material";
import client from "../../../../API";
import { useCreatorAuth } from "../../../../Hooks/creator-use-auth";

const CreatorCampaignConversation = ({ campaignId }) => {
  const { creatorToken } = useCreatorAuth();
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const { data: checkResults, isLoading: isChecking, error: checkError } = useQuery({
    queryKey: ['messagesId', campaignId],
    queryFn: () => client.conversations.check({ campaign_id: campaignId, creator_id: creatorToken.creator_user.username })
  });

  const convId = checkResults?.exists ? checkResults.conversation_id : null;

  const { data: messagesData, error: messagesError, isLoading: isMessagesLoading } = useQuery({
    queryKey: ['messages', convId],
    queryFn: () => client.conversations.getMessages(convId),
    enabled: !!convId,
    onSuccess: (data) => {
      setMessages(data);
    }
  });

  const sendMessageMutation = useMutation(client.conversations.sendMessage, {
    onMutate: () => {
      setIsSending(true); 
    },
    onSuccess: (newMessage) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        { 
          sender_id: "You", 
          message: newMessage.message, 
          created_at: new Date(), 
          creator_id: creatorToken.creator_user.username,
          read_at: null
        },
      ]);
      setMessage("");
      setIsSending(false); 
    },
    onError: (error) => {
      console.error("Error sending message:", error);
      setIsSending(false); 
    }
  });

  const handleSendMessage = () => {
    if (convId && message.trim()) {
      sendMessageMutation.mutate({
        conversation_id: convId,
        message,
        sender_role: 'creator', // Add this line
        creator_id: creatorToken.creator_user.username,
        campaign_id: campaignId 
      });
    } else {
      console.error("Conversation ID is not available or message is empty.");
    }
  };

  return (
    <Box style={{ display: "flex", flexDirection: "column" }}>
      {isChecking && <Typography>Loading messages...</Typography>}
      {checkError && <Typography>Error loading messages</Typography>}
      {(!messages || messages.length === 0) && !isMessagesLoading && !messagesError && <Typography>No messages</Typography>}
      <Box
        display="flex"
        flexDirection="column"
        sx={{ minHeight: "300px", maxHeight: "500px", overflowY: "auto" }}
        style={{ flex: 1 }}
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: msg.creator_id === creatorToken.creator_user.username ? 'flex-end' : 'flex-start',
              mb: 2,
            }}
          >
            <Box
              sx={{
                maxWidth: '70%',
                p: 2,
                borderRadius: 2,
                backgroundColor: msg.creator_id === creatorToken.creator_user.username ? '#007bff' : '#e4e6eb',
                color: msg.creator_id === creatorToken.creator_user.username ? 'white' : 'black',
                textAlign: msg.creator_id === creatorToken.creator_user.username ? 'right' : 'left',
              }}
            >
              <Typography variant="body1">
                {msg.message}
              </Typography>
              <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                {new Date(msg.created_at).toLocaleTimeString()}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box style={{ height: "3em", display: "flex", flexDirection: "row", alignItems: "center" }}>
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Type a message"
          fullWidth
          variant="outlined"
          style={{ height: "100%" }}
          disabled={isSending} 
          onKeyPress={(e) => {
            if (e.key === "Enter" && !isSending) {
              handleSendMessage();
            }
          }}
        />
        <Button
          color="primary"
          variant="contained"
          style={{ flex: 0.25 }}
          onClick={handleSendMessage}
          disabled={isSending} 
        >
          {isSending ? <CircularProgress size={24} color="inherit" /> : "Send"}
        </Button>
      </Box>
    </Box>
  );
};

export default CreatorCampaignConversation;
