import React, { useState } from 'react';
import {
    Box,
    Typography,
    Backdrop,
    CircularProgress,
    Toolbar,
    Snackbar,
    Alert,
    Tabs,
    Tab,
    Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import routes from '../Config/routes';
import { useCreatorAuth } from '../Hooks/creator-use-auth';
import { ConfigValue } from '../Config';
import AlertDialog from '../Components/AlertDialog';
import useAlertDialog from '../Components/useAlertDialog';
import BlitzHeader from '../Components/BlitzHeader';
import pfplogo from '../Components/globalAssets/blitzLogo.png'; // Adjust the path if necessary

const CreatorLogin = () => {
    const navigate = useNavigate();
    const { login } = useCreatorAuth();
    const [isLoading, setIsLoading] = useState(false);
    const { dialogState, openDialog } = useAlertDialog();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");
    const [tabValue, setTabValue] = useState(0);

    const handleLoginSuccess = async (response) => {
        setIsLoading(true);
        try {
            const token = response.credential;
            const fetchResponse = await fetch(ConfigValue.PUBLIC_REST_API_ENDPOINT + '/creatorUsers/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token })
            });

            if (fetchResponse.ok) {
                const jsonResponse = await fetchResponse.json();
                login(jsonResponse);
                openDialog("Success", "You have logged in successfully", 
                    () => navigate(routes.creatorConnectStart), 
                    () => navigate(routes.creatorConnectStart), "Ok", null);
            } else {
                const errorMessage = await fetchResponse.text();
                setSnackbarMessage(`Login failed: ${errorMessage}`);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setSnackbarMessage('Login process encountered an error.');
            setSnackbarSeverity("error");
            setOpenSnackbar(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleLoginFailure = (error) => {
        console.error('Failed to login with Google:', error);
        setSnackbarMessage('Google login failed, please try again.');
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 1) {
            navigate('/creatorconnect/onboard');
        }
    };

    return (
        <>
            <BlitzHeader />
            <Toolbar />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 5 }}>
                <img 
                    src={pfplogo} 
                    alt="Blitz Logo" 
                    style={{ marginBottom: '20px', height: '75px' }} 
                />
                <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%', textAlign: 'center' }}>
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        Log in to Blitz
                    </Typography>
                    <Tabs value={tabValue} onChange={handleTabChange} centered>
                        <Tab label="Sign In" />
                        <Tab label="Onboard" />
                    </Tabs>
                    <Box sx={{ marginTop: 3 }}>
                        <GoogleLogin
                            onSuccess={handleLoginSuccess}
                            onError={handleLoginFailure}
                            useOneTap
                        />
                    </Box>
                </Paper>

                <Typography variant="body2" sx={{ marginTop: 3, textAlign: 'center' }}>
                    Use Blitz to <strong>PAY</strong>, <strong>CREATE</strong>, and <strong>ACCELERATE</strong>
                </Typography>
                <Typography variant="caption" sx={{ textAlign: 'center', marginTop: 1 }}>
                    Made with love by humans on Earth 🌍
                </Typography>
                {isLoading && (
                    <Backdrop open={isLoading} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <AlertDialog alertState={dialogState} />
        </>
    );
};

export default CreatorLogin;
