import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TextField,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  InputLabel,
  TableFooter,
  Checkbox,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { StyledTableRow } from "../../../../Utils/styledcell";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiDateField from "../../../../Components/MuiDateField";

function formatDateToYYYYMMDD(dateTime) {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone };
  const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(dateTime);
  return formattedDate.replace(/\//g, '-');
}

function PickerToLocal(dateObject) {
  const localDate = new Date(dateObject.getUTCFullYear(), dateObject.getUTCMonth(), dateObject.getUTCDate());
  return localDate;
}

const TimelineEntry = ({ event, onEditField, campaignStatus }) => (
  <StyledTableRow
    sx={
      (theme) => ({
        ...(event.will_delete ? { bgcolor: `${theme.palette.error.main} !important` } : {}),
        ...(event.status === 'complete' ? {
          border: `2px solid ${theme.palette.success.main}`
        } : {})
      })
    }
  >
    <TableCell>
      <TextField
        value={event.objective}
        id="Objective-picker"
        fullWidth
        onChange={(e) => (onEditField(event.index, 'objective', e.target.value))}
        disabled={campaignStatus !== 'Draft'}
      >
      </TextField>
    </TableCell>
    <TableCell>
      <TextField
        value={event.status}
        fullWidth
        disabled
        inputProps={{ style: { textTransform: 'capitalize' } }}
      >
      </TextField>
    </TableCell>
    <TableCell>
      <TextField
        value={event.notes}
        fullWidth
        minRows={2}
        multiline
        disabled={campaignStatus !== 'Draft'}
        onChange={(e) => (onEditField(event.index, 'notes', e.target.value))}
      >
      </TextField>
    </TableCell>
    <TableCell>
      <Tooltip title='Only you can see this'>
        <TextField
          value={event.manager_notes}
          id="Objective-picker"
          fullWidth
          onChange={(e) => (onEditField(event.index, 'manager_notes', e.target.value))}
        >
        </TextField>
      </Tooltip>
    </TableCell>
    <TableCell align="right">
      {event.status === 'complete' ?
        <CheckCircleIcon color="success"></CheckCircleIcon>
        :
        <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
      }
    </TableCell>
    <TableCell align="right">
      <Checkbox
        checked={event.manager_completed}
        onChange={(e) => (onEditField(event.index, 'manager_completed', e.target.checked))}
      ></Checkbox>
    </TableCell>
    <TableCell>
      <MuiDateField
        id="date-picker"
        label="Due Date"
        type="date"
        fullWidth
        variant="outlined"
        value={formatDateToYYYYMMDD(event.projected_date)}
        disabled={campaignStatus !== 'Draft'}
        onChange={(e) => (onEditField(event.index, 'projected_date', PickerToLocal(e.target.valueAsDate)))}
        InputLabelProps={{
          shrink: true,
        }}>
      </MuiDateField>
    </TableCell>
    <TableCell align="right">
      <Checkbox
        checked={event.will_delete}
        checkedIcon={<DeleteIcon></DeleteIcon>}
        icon={<DeleteOutlineIcon></DeleteOutlineIcon>}
        onChange={(e) => (onEditField(event.index, 'will_delete', e.target.checked))}
        disabled={campaignStatus !== 'Draft'}
      ></Checkbox>
    </TableCell>
  </StyledTableRow>
)

const TimelineTab = ({ campaignDetails, timelineEvents, onEditField, onCreateRow }) => {
  const [selectedCreator, setSelectedCreator] = useState(campaignDetails.creators[0].id);
  const [openDialog, setOpenDialog] = useState(false);

  if (timelineEvents === undefined || timelineEvents === null) {
    return (<></>);
  }

  const currentTimeline = timelineEvents.map((event, index) => ({ ...event, index })).filter((event) => (event.username === selectedCreator));
  currentTimeline.sort((a, b) => {
    if (!a.projected_date) return 1; // Place items without a date at the bottom
    if (!b.projected_date) return -1;
    return new Date(a.projected_date) - new Date(b.projected_date);
  });
  
  const isTimelineEmpty = currentTimeline.length === 0;

  const handleApplyTemplate = () => {
    const today = new Date();

    const newEvent1 = {
      objective: 'send script/video idea',
      status: 'incomplete',
      notes: '',
      manager_notes: '',
      manager_completed: false,
      projected_date: new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000),
      will_delete: false
    };
    onCreateRow(selectedCreator, newEvent1);

    const newEvent2 = {
      objective: 'send draft/approve draft',
      status: 'incomplete',
      notes: '',
      manager_notes: '',
      manager_completed: false,
      projected_date: new Date(today.getTime() + 4 * 24 * 60 * 60 * 1000),
      will_delete: false
    };
    onCreateRow(selectedCreator, newEvent2);

    const newEvent3 = {
      objective: 'video posted',
      status: 'incomplete',
      notes: '',
      manager_notes: '',
      manager_completed: false,
      projected_date: new Date(today.getTime() + 5 * 24 * 60 * 60 * 1000),
      will_delete: false
    };
    onCreateRow(selectedCreator, newEvent3);

    setOpenDialog(false);
  };

  return (
    <Box>
      <FormControl sx={{ marginBlockEnd: 2, width: '12rem' }}>
        <InputLabel id="creator-select-label">Creator</InputLabel>
        <Select labelId="creator-select-label"
          id='creator-select'
          label='Select'
          value={selectedCreator}
          onChange={(e) => setSelectedCreator(e.target.value)}
        >
          {campaignDetails.creators.map((creator) => (<MenuItem key={creator.id} value={creator.id}>{creator.id}</MenuItem>))}
        </Select>
      </FormControl>
      {isTimelineEmpty && (
        <Box textAlign="right" mb={2}>
          <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
            Apply Template Timeline
          </Button>
          <Dialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Apply Template Timeline"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to apply the template timeline for the selected creator?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleApplyTemplate} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
      <TableContainer component={Paper}>
        <Table aria-label="assets details">
          <TableHead>
            <StyledTableRow>
              <TableCell>Objective</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell><Tooltip title='Only you can see this'>Manager only notes</Tooltip></TableCell>
              <TableCell width={'5rem'}>Creator Delivered</TableCell>
              <TableCell width={'5rem'}>Accepted</TableCell>
              <TableCell>Date</TableCell>
              <TableCell width={'5rem'}>Actions</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {currentTimeline.map((event) => (
              <TimelineEntry key={event.index} event={event} onEditField={onEditField} campaignStatus={campaignDetails.campaign_status}></TimelineEntry>
            ))}
          </TableBody>
          {campaignDetails.campaign_status === 'Draft' && <TableFooter>
            <TableRow>
              <TableCell colSpan={8} align="right">
                <IconButton color="primary" onClick={() => onCreateRow(selectedCreator)} >
                  <AddCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TimelineTab;
