import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import MuiPhoneInput from "../../../Components/MuiPhoneInput";
import { RateField } from "../../../Components/RateField";
import { parseLocalFloat, parseLocalInt } from "../../../Utils/constants";
import API from "../../../API";
import BlitzHeader from "../../../Components/BlitzHeader";

const CreatorSignup = ({ onSubmit }) => {
  const { manager } = useParams();
  const navigate = useNavigate();
  const [contactEmail, setContactEmail] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailSame, setEmailSame] = useState(false);
  const [creatorName, setCreatorName] = useState("");
  const [creatorRegion, setCreatorRegion] = useState("");
  const [platforms, setPlatforms] = useState({
    TikTok: false,
    Instagram: false,
    YouTube: false,
  });
  const [primaryMarket, setPrimaryMarket] = useState("");
  const [notesContentStyle, setNotesContentStyle] = useState(
    "tell us about yourself"
  );

  const [race, setRace] = useState("");
  const [gender, setGender] = useState("");
  const [location, setLocation] = useState("");
  const [pfphref, setPfphref] = useState("");

  // Instagram
  const [instagramLink, setInstagramLink] = useState("");
  const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
  const [instagramBrandRate, setInstagramBrandRate] = useState("");
  const [instagramSongRate, setInstagramSongRate] = useState("");
  const [instagramStoryRate, setInstagramStoryRate] = useState("");

  // TikTok
  const [tikTokLink, setTikTokLink] = useState("");
  const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
  const [tikTokBrandRate, setTikTokBrandRate] = useState("");
  const [tikTokSongRate, setTikTokSongRate] = useState("");

  // YouTube
  const [youtubeLink, setYoutubeLink] = useState("");
  const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
  const [youtube30sBrandRate, setYoutube30sBrandRate] = useState("");
  const [youtube60sBrandRate, setYoutube60sBrandRate] = useState("");
  const [youtubeShortsBrandRate, setYoutubeShortsBrandRate] = useState("");

  // Dialog state
  const [openDialog, setOpenDialog] = useState(false);
  const [openRedirectDialog, setOpenRedirectDialog] = useState(false);
  const [validationError, setValidationError] = useState("");

  useEffect(() => {
    if (!manager) {
      setOpenDialog(true);
    }
  }, [manager]);

  const handlePlatformChange = (event) => {
    setPlatforms({ ...platforms, [event.target.name]: event.target.checked });
  };

  const handleEmailSameChange = (event) => {
    setEmailSame(event.target.checked);
    if (event.target.checked) {
      setPaymentEmail(contactEmail);
    } else {
      setPaymentEmail("");
    }
  };

  const handleFollowerCountChange = (setter) => (e) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, "");
    const formattedValue = formatNumber(rawValue);
    setter(formattedValue);
  };

  const formatNumber = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "" : number.toLocaleString();
  };

  const formatRate = (value) => {
    const number = parseInt(value, 10);
    return isNaN(number) ? "" : `${number.toLocaleString()}`;
  };

  const handleSaveCreator = async () => {
    const anyPlatform = [platforms.TikTok, platforms.Instagram, platforms.YouTube].some((value)=>value);
    if(!anyPlatform){
      alert('You need to select at least one platform.');
      return;
    }

    if(platforms.TikTok){
      const anyPromo = [tikTokBrandRate, tikTokSongRate].some((value)=>value);
      if(!anyPromo){
        alert('You need to select at least one promotion type for TikTok.');
        return;
      }
    }

    if(platforms.Instagram){
      const anyPromo = [instagramBrandRate, instagramSongRate, instagramStoryRate].some((value)=>value);
      if(!anyPromo){
        alert('You need to select at least one promotion type for Instagram.');
        return;
      }
    }

    if(platforms.YouTube){
      const anyPromo = [youtube30sBrandRate, youtube60sBrandRate, youtubeShortsBrandRate].some((value)=>value);
      if(!anyPromo){
        alert('You need to select at least one promotion type for YouTube.');
        return;
      }
    }



    if (phoneNumber.length < 11) {
      alert(`This Phone number is not valid`);
      return;
    }
    if (creatorName.includes(" ")) {
      setValidationError("Creator name cannot contain spaces.");
      return;
    }

    const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
    const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
    const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;

    let payload = null;
    try {
      payload = {
        creator: creatorName,
        tiktok: parseLocalInt(tikTokFollowerCount) || null,
        tiktok_link: formattedTikTokLink || null,
        instagram: parseLocalInt(instagramFollowerCount) || null,
        instagram_link: formattedInstagramLink || null,
        youtube: parseLocalInt(youtubeFollowerCount) || null,
        youtube_link: formattedYouTubeLink || null,
        geolocationgenderethnicity: `${location} / ${gender} / ${race}`,
        geolocation: location,
        gender: gender,
        ethnicity: race,
        primary_market: primaryMarket,
        region: creatorRegion,
        notescontent_style: notesContentStyle || null,
        rate_tt_sound: parseLocalFloat(tikTokSongRate),
        rate_tt_brand: parseLocalFloat(tikTokBrandRate),
        rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
        rate_ig_reelsound: parseLocalFloat(instagramSongRate),
        rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
        rate_yt_integ30s: parseLocalFloat(youtube30sBrandRate),
        rate_yt_integ60s: parseLocalFloat(youtube60sBrandRate),
        rate_yt_short: parseLocalFloat(youtubeShortsBrandRate),
        contactEmail: contactEmail,
        paymentEmail: paymentEmail,
        phone_number: phoneNumber,
        pfphref: pfphref || null,
        manager: manager || null,
      };
    } catch (error) {
      alert(`Error parsing this form: ${error}`);
      return;
    }
    console.log(payload)

    try {
      const data = await API.creators.signup(payload);

      setOpenRedirectDialog(true);
      setTimeout(() => {
        navigate(`/creators/${creatorName}`);
      }, 3000);

    } catch (error) {
      alert(`Network error: ${(error?.response?.data?.error || error.message)}\nPlease contact us.`);
      console.error('Network error:', (error?.response?.data?.error || error.message));
    }

  };

  return (
    <>
      <BlitzHeader></BlitzHeader>
      <Container maxWidth="md">
        <h1> {manager} Creator Intake Form</h1>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSaveCreator();
          }}
        >
          <TextField
            margin="dense"
            label="Creator Name"
            type="text"
            fullWidth
            value={creatorName}
            onChange={(e) => setCreatorName(e.target.value)}
            variant="outlined"
            required
            error={!!validationError}
            helperText={validationError}
          />
          <FormControl variant="outlined" fullWidth margin="dense" required>
            <InputLabel>Creator Region</InputLabel>
            <Select
              label="Creator Region"
              value={creatorRegion}
              onChange={(e) => setCreatorRegion(e.target.value)}
            >
              <MenuItem value="NACAUKAUS">USA, Canada, UK, Australia</MenuItem>
              <MenuItem value="Europe">Europe</MenuItem>
              <MenuItem value="Asia">Asia</MenuItem>
              <MenuItem value="LATAM">LATAM</MenuItem>
              <MenuItem value="Africa">Africa</MenuItem>
            </Select>
          </FormControl>
          <FormGroup row>
            {Object.keys(platforms).map((platform) => (
              <FormControlLabel
                key={platform}
                control={
                  <Checkbox
                    checked={platforms[platform]}
                    onChange={handlePlatformChange}
                    name={platform}
                  />
                }
                label={platform}
              />
            ))}
          </FormGroup>
          {platforms.TikTok && (
            <>
              <TextField
                margin="dense"
                label="TikTok Username"
                type="text"
                fullWidth
                value={tikTokLink}
                onChange={(e) => setTikTokLink(e.target.value)}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      tiktok.com/@
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                label="TikTok Follower Count"
                type="text"
                fullWidth
                value={tikTokFollowerCount}
                onChange={handleFollowerCountChange(setTikTokFollowerCount)}
                variant="outlined"
                required
              />
              <RateField
                margin="dense"
                label="TikTok Brand Rate"
                fullWidth
                value={tikTokBrandRate}
                onChange={(value) => setTikTokBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="TikTok Song Rate"
                fullWidth
                value={tikTokSongRate}
                onChange={(value) => setTikTokSongRate(value)}
                variant="outlined"
                minValue={100}
              />
            </>
          )}
          {platforms.Instagram && (
            <>
              <TextField
                margin="dense"
                label="Instagram Username"
                type="text"
                fullWidth
                value={instagramLink}
                onChange={(e) => setInstagramLink(e.target.value)}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      instagram.com/
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                label="Instagram Follower Count"
                type="text"
                fullWidth
                value={instagramFollowerCount}
                onChange={handleFollowerCountChange(setInstagramFollowerCount)}
                variant="outlined"
                required
              />
              <RateField
                margin="dense"
                label="Instagram Brand Rate"
                fullWidth
                value={instagramBrandRate}
                onChange={(value) => setInstagramBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="Instagram Song Rate"
                fullWidth
                value={instagramSongRate}
                onChange={(value) => setInstagramSongRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="Instagram Story Rate"
                fullWidth
                value={instagramStoryRate}
                onChange={(value) => setInstagramStoryRate(value)}
                variant="outlined"
                minValue={20}
              />
            </>
          )}
          {platforms.YouTube && (
            <>
              <TextField
                margin="dense"
                label="Youtube Username"
                type="text"
                fullWidth
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
                variant="outlined"
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      youtube.com/
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                margin="dense"
                label="YouTube Follower Count"
                type="text"
                fullWidth
                value={youtubeFollowerCount}
                onChange={handleFollowerCountChange(setYoutubeFollowerCount)}
                variant="outlined"
                required
              />
              <RateField
                margin="dense"
                label="YouTube Brand Rate - 30s"
                type="text"
                fullWidth
                value={youtube30sBrandRate}
                onChange={(value) => setYoutube30sBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="YouTube Brand Rate - 60s"
                type="text"
                fullWidth
                value={youtube60sBrandRate}
                onChange={(value) => setYoutube60sBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
              <RateField
                margin="dense"
                label="YouTube Shorts Rate"
                type="text"
                fullWidth
                value={youtubeShortsBrandRate}
                onChange={(value) => setYoutubeShortsBrandRate(value)}
                variant="outlined"
                minValue={100}
              />
            </>
          )}

          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel id="race-select-label">Race (optional)</InputLabel>
            <Select
              labelId="race-select-label"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              label="Race (optional)"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Asian">Asian</MenuItem>
              <MenuItem value="Black">Black</MenuItem>
              <MenuItem value="Hispanic">Hispanic</MenuItem>
              <MenuItem value="White">White</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" fullWidth margin="dense" required>
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId="gender-select-label"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Gender"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Non-binary">Non-binary</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Location"
            type="text"
            fullWidth
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            variant="outlined"
            required
          />
          <FormControl variant="outlined" fullWidth margin="dense" required>
            <InputLabel id="primary-market-label">Primary Market</InputLabel>
            <Select
              labelId="primary-market-label"
              value={primaryMarket}
              onChange={(e) => setPrimaryMarket(e.target.value)}
              label="Primary Market"
            >
              {[
                "Activist",
                "Artist",
                "Beauty",
                "Cars",
                "Cosplay",
                "Comedy",
                "Country",
                "Dance",
                "Educational",
                "Fashion",
                "Fitness",
                "Food",
                "Gaming",
                "Lifestyle",
                "Music",
                "Pets",
                "Reviews",
                "Sports",
                "Tech",
                "Thirst Trap",
                "Travel",
              ].map((market) => (
                <MenuItem key={market} value={market}>
                  {market}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Bio"
            type="text"
            fullWidth
            multiline
            rows={4}
            placeholder="tell us about yourself"
            variant="outlined"
            value={notesContentStyle}
            onChange={(e) => setNotesContentStyle(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Contact Email"
            type="email"
            fullWidth
            value={contactEmail}
            onChange={(e) => setContactEmail(e.target.value)}
            variant="outlined"
            required
          />
          <TextField
            margin="dense"
            label="Paypal Payment Email"
            type="email"
            fullWidth
            disabled={emailSame}
            value={paymentEmail}
            onChange={(e) => setPaymentEmail(e.target.value)}
            variant="outlined"
            required={!emailSame}
          />
          <MuiPhoneInput
            country={'us'}
            preferredCountries={['us']}
            value={phoneNumber}
            onChange={setPhoneNumber}
            isValid={(value) => value.length >= 11}
            required
          />
          <TextField
            margin="dense"
            label="Profile Photo URL"
            type="text"
            fullWidth
            value={pfphref}
            onChange={(e) => setPfphref(e.target.value)}
            variant="outlined"
            placeholder="Enter URL for profile photo"
          />
          <FormControlLabel
            control={
              <Checkbox checked={emailSame} onChange={handleEmailSameChange} />
            }
            label="Payment and contact email are the same"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: "5px", marginBottom: "10px" }}
          >
            Submit Information
          </Button>
        </form>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Signup Form Information</DialogTitle>
          <DialogContent>
            <p>
              This signup form is for creators to be seen in the Blitz
              marketplace and to send campaigns directly to their email and
              phone number. By opting in you are joining a community of thousands of creators world wide!
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openRedirectDialog}>
          <DialogTitle>Success</DialogTitle>
          <DialogContent>
            <p>Redirecting you to your mediakit...</p>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
};

export default CreatorSignup;
