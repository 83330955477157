import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react'
import SelectPopup from '../../../../Components/SelectPopup';
import FilterPopup from '../../../../Components/FilterPopup';
import { indexToPromoFilter, niches } from '../../../../Utils/constants';

export default function NichesFilter({ value, onChangeValue }) {
    const [internalSet, setInternalSet] = useState(new Set([]));
    const onOpen = () => {
        setInternalSet(new Set(value));
    }

    const onClear = () => {
        setInternalSet(new Set([]));
        onChangeValue(new Set([]));
    };

    const ToggleSet = (value) => {
        if (internalSet.has(value))
            setInternalSet(prevSet => {
                prevSet.delete(value)
                return new Set(prevSet)
            })
        else
            setInternalSet(prevSet => new Set(prevSet.add(value)))
    }

    return (
        <SelectPopup value={Array.from(value).reduce((accum, value) => `${accum}${value}, `, '')} label='Niches' inputStyle={{ width: '7rem' }} onOpenPopup={onOpen} >
            <FilterPopup boxStyle={{ padding: 2, overflowY: 'auto', maxHeight: '15rem' }} onApply={() => onChangeValue(new Set(internalSet))} onClear={onClear}>
                <Box sx={{ width: '15rem', display: 'grid', height: 'max-content', justifyContent:'start', gridTemplateColumns:'repeat(2, 1fr)' }}>
                    {niches.map((niche) =>
                        <FormControlLabel key={niche} checked={internalSet.has(niche)} onChange={() => ToggleSet(niche)} label={niche} control={<Checkbox></Checkbox>}></FormControlLabel>
                    )}
                </Box>
            </FilterPopup>
        </SelectPopup>
    )
}