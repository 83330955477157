import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  IconButton,
  Drawer,
  Tabs,
  Tab,
  Switch,
  Box,
  Chip,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Fab,
  Badge,
  styled
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useMutation, useQuery } from "react-query";
import API from "../../API";
import useAgencyAuth from "../../Hooks/agency-use-auth";
import AgencyNavbar from "../../Components/Navbar/agencyNavBar";
import SearchFilterSection from "../Dashboard/Search/leftColumnSearch";
import { ArrowRight, EditRounded } from "@mui/icons-material";

const StyleHeader = (theme) => ({
  margin: theme.spacing(1),
  fontWeight: "bold",
});

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  margin: theme.spacing(1, 0)
}));

const RightDrawerWidth = '22rem';

const classes = {
  drawer: {
    boxSizing: 'border-box',
    flexShrink: 0,
  },
  drawerPaper: {
    width: RightDrawerWidth,
    maxWidth: '100vw',
    boxSizing: 'border-box',
    resize: "horizontal",
    overflow: "auto",
    paddingInline: 2,
    paddingBlockEnd: 4,
  },
  listItem: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textField: {
    width: "100%",
  },
  dialogTitle: {
    color: "#424242",
  },
  dialogContentText: {
    color: "#424242",
  },
};

const AgencyMarket = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  let itemsPerPage = 4;
  if (isSmScreen)
    itemsPerPage = 8;
  if (isMdScreen)
    itemsPerPage = 16;

  const [selectedItems, setSelectedItems] = useState(new Set());
  const [selectedCreatorsData, setSelectedCreatorsData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [campaignBrief, setCampaignBrief] = useState("");
  const [videoAmount, setVideoAmount] = useState("1");
  const [campaignType, setCampaignType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [campaignSum, setCampaignSum] = useState(0);
  const [blitzFee, setBlitzFee] = useState(0);
  const [finalCost, setFinalCost] = useState(0);
  const [idealDueDate, setIdealDueDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [isCreatorDialogOpen, setIsCreatorDialogOpen] = useState(false);
  const [emailRecipient, setEmailRecipient] = useState("");
  const [selectedTab, setSelectedTab] = useState("Campaign");
  const [miscType, setMiscType] = useState("Gifting");
  const [eventDetails, setEventDetails] = useState({});
  const [managers, setManagers] = useState([]);
  const [currentManager, setCurrentManager] = useState("0");
  const [asset, setAsset] = useState(null);
  const [assetDialogOpen, setAssetDialogOpen] = useState(false);
  const [isBlitzFeeOnCreators, setIsBlitzFeeOnCreators] = useState(true);
  const { getCompanyInfo } = useAgencyAuth();
  const agencyName = getCompanyInfo()?.name;

  const filterRef = useRef(null);

  const [openDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    const sum = calculateTotalCampaignSum();
    setCampaignSum(sum);
    const fee = isBlitzFeeOnCreators ? 0 : calculateBlitzFee(sum);
    setBlitzFee(fee);
    setFinalCost(sum + fee);
  }, [selectedCreatorsData, isBlitzFeeOnCreators]);

  useEffect(() => {
    if (filterRef.current) {
      setTableHeight(
        document.documentElement.offsetHeight -
        filterRef.current.clientHeight -
        82
      );
    }
  }, [filterRef]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await API.agencyConnect.listManagers(agencyName);
        setManagers(data);
      } catch (error) {
        setManagers([]);
      }
    };
    fetchData();
  }, [agencyName]);

  const calculateTotalCampaignSum = () => {
    const sum = selectedCreatorsData.reduce((accumulator, creator) => {
        const priceAsString = typeof creator.price === "string" ? creator.price : "0";
        const cleanedPriceString = priceAsString.replace(/[^0-9.-]+/g, "");
        const price = parseFloat(cleanedPriceString);
        if (isFinite(price)) {
            return accumulator + price;
        } else {
            console.error(`Invalid price format detected: ${creator.price}`);
            return accumulator;
        }
    }, 0);

    return isBlitzFeeOnCreators ? sum : sum + calculateBlitzFee(sum);
  };

  const calculateBlitzFee = (sum) => {
    return sum * 0.05;
  };

  const generateCampaignID = () => new Date().getTime().toString();

  const createProjectData = () => {
    const newCreators = selectedCreatorsData.map((creator) => {
      const price = parseFloat(creator.price.replace(/[^0-9.-]+/g, ""));
      return {
        id: creator.id,
        name: creator.name,
        price: parseFloat(creator.price.replace(/[^0-9.-]+/g, "")),
        agencyRate: isBlitzFeeOnCreators ? (price * 0.95).toFixed(2) : price.toFixed(2),
        following: creator.following,
        promotionPlatform: creator.promotionPlatform,
        promotionType: creator.promotionType,
        platformLink: creator.platformLink,
        pfphref: creator.pfphref,
        deliveryAddress: creator.deliveryAddress,
        deliveryConfirmationNumber: creator.deliveryConfirmationNumber,
        affiliateCode: creator.affiliateCode,
        affiliatePercentage: creator.affiliatePercentage,
      };
    });

    const managerIndex = Number.parseInt(currentManager);
    const projectData = {
      id: generateCampaignID(),
      name: campaignName,
      brief: asset ? asset.file_url : campaignBrief,
      videoAmount: parseInt(videoAmount, 10),
      campaignType:
        selectedTab === "Partnership"
          ? "partnership"
          : selectedTab === "Misc"
            ? miscType
            : campaignType,
      type: selectedTab === "Misc" ? miscType : campaignType,
      creators: newCreators,
      proposalDate: new Date().toISOString().slice(0, 10),
      campaign_sum: calculateTotalCampaignSum(),
      idealDueDate: idealDueDate,
      emailRecipient: emailRecipient,
      agency: true,
      campaign_manager: {
        name: managers[managerIndex]
          ? `${managers[managerIndex].first_name} ${managers[managerIndex].last_name}`
          : "",
        email: managers[managerIndex] ? managers[managerIndex].email : "",
        phone_number: "",
      },
      description: asset ? asset.file_url : campaignBrief,
      email: getCompanyInfo()?.email,
      company_id: getCompanyInfo()?.id,
      finish_date: idealDueDate,
      invoice_date: idealDueDate,
      paid_date: idealDueDate,
      total_sum: calculateTotalCampaignSum(),
      status: "pending",
      po_number: generateCampaignID(),
      drive_link: "",
      blitz_autocampaign: false,
      shared_with: [],
      tools: {},
      amount: newCreators[0]?.price,
      interest_rate: 0,
      unique_code: generateCampaignID(),
      notes: "",
      conversation_id: generateCampaignID(),
      creator: newCreators.length > 0 ? newCreators[0].id : null,
      asset: asset ? asset.file_url : null,
    };

    if (selectedTab === "Misc") {
      if (miscType === "Gifting") {
        projectData.creator_data = selectedCreatorsData.map((creator) => ({
          creator_id: creator.id,
          delivery_address: creator.deliveryAddress,
          delivery_confirmation_number: creator.deliveryConfirmationNumber,
          affiliate_code: creator.affiliateCode,
          affiliate_percentage: creator.affiliatePercentage,
        }));
      } else if (miscType === "Event Invite") {
        projectData.event_details = eventDetails;
        projectData.creator_data = selectedCreatorsData.map((creator) => ({
          creator_id: creator.id,
          name: creator.name,
        }));
      }
      delete projectData.campaign_sum;
      delete projectData.amount;
    }

    return projectData;
  };

  const { mutate: createProject, isLoading: isCreatingProject } = useMutation(
    (projectData) => {
      if (selectedTab === "Partnership") {
        return API.agencyConnect.createPartnership(projectData);
      } else if (selectedTab === "Misc") {
        return API.agencyConnect.createMiscProject(projectData);
      } else {
        return API.agencyConnect.createCampaign(projectData);
      }
    },
    {
      onSuccess: (data) => {
        setDialogMessage("Project created successfully!");
        setOpenDialog(true);
        setSelectedItems(new Set());
        setSelectedCreatorsData([]);
        setCampaignName("");
        setCampaignBrief("");
        setAsset(null);
      },
      onError: (error) => {
        setDialogMessage(`Error: ${error.message}`);
        setOpenDialog(true);
      },
    }
  );

  const handleSelectItem = useCallback((creatorId, relevantData) => {
    if (selectedTab === "Partnership" && selectedItems.size > 0) {
      return;
    }

    const isSelected = selectedItems.has(creatorId);
    if (isSelected) {
      setSelectedItems((prev) => (prev.delete(creatorId), new Set(prev)));
    } else {
      setSelectedItems((prev) => (prev.add(creatorId), new Set(prev)));
    }
    if (!isSelected) {
      setSelectedCreatorsData((prev) => [...prev, relevantData]);
    } else {
      setSelectedCreatorsData((prev) =>
        prev.filter((item) => item.id !== creatorId)
      );
    }
  }, [selectedItems, selectedCreatorsData, selectedTab]);

  const handleDeleteItem = (creatorId) => {
    setSelectedItems((prev) => (prev.delete(creatorId), new Set(prev)));
    setSelectedCreatorsData((prev) =>
      prev.filter((item) => item.id !== creatorId)
    );
  };

  const handlePriceChange = (creatorId, newPrice) => {
    setSelectedCreatorsData((prev) =>
      prev.map((creator) =>
        creator.id === creatorId ? { ...creator, price: newPrice } : creator
      )
    );
  };

  const handleCreateProject = () => {
    const projectData = createProjectData();
    createProject(projectData);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    if (newValue === "Partnership") {
      setSelectedItems(new Set());
      setSelectedCreatorsData([]);
    }
  };

  return (
    <Box display="flex" flexDirection="row">
      {/* Navbar */}
      <AgencyNavbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Marketplace
        </Typography>

        <SearchFilterSection
          onCreatorSelect={handleSelectItem}
          selectedItems={selectedItems}
          itemsPerPage={itemsPerPage}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Campaign Name"
              variant="outlined"
              fullWidth
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Campaign Brief"
              variant="outlined"
              fullWidth
              multiline
              minRows={4}
              value={campaignBrief}
              onChange={(e) => setCampaignBrief(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Due Date"
              type="date"
              fullWidth
              variant="outlined"
              value={idealDueDate}
              onChange={(e) => setIdealDueDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email Recipient"
              variant="outlined"
              fullWidth
              value={emailRecipient}
              onChange={(e) => setEmailRecipient(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Manager</InputLabel>
              <Select
                value={currentManager}
                onChange={(e) => setCurrentManager(e.target.value)}
                label="Manager"
              >
                {managers.map((manager, index) => (
                  <MenuItem value={index} key={index}>
                    {manager.first_name} {manager.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleCreateProject}
              startIcon={
                isCreatingProject && (
                  <CircularProgress size={20} color="inherit" />
                )
              }
            >
              Create Campaign
            </Button>
          </Grid>
        </Grid>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Project Creation Status</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default AgencyMarket;
