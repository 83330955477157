import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import { feature } from "topojson-client";
import worldTopoJSON from "./topojson.json"; // Adjust the path if needed
import { useTheme } from "@mui/material/styles";

const Globe = ({ size }) => {
  const theme = useTheme();
  const [rotation, setRotation] = useState([-95, -30, 0]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((prevRotation) => [
        prevRotation[0] + 0.5, // Increase rotation speed by adjusting this value
        prevRotation[1],
        prevRotation[2],
      ]);
    }, 20);

    // Convert TopoJSON to GeoJSON and set countries
    const countriesData = feature(worldTopoJSON, worldTopoJSON.objects.countries).features;
    setCountries(countriesData);

    return () => clearInterval(interval);
  }, []);

  const projection = d3
    .geoOrthographic()
    .fitSize([size, size], { type: "Sphere" })
    .rotate(rotation);

  const geoGenerator = d3.geoPath().projection(projection);

  return (
    <svg width={size} height={size}>
      <path
        d={geoGenerator({ type: "Sphere" })}
        fill={theme.palette.background.default}
        stroke={theme.palette.text.primary}
      />
      {countries.map((feature, i) => (
        <path
          key={i}
          d={geoGenerator(feature)}
          fill={theme.palette.primary.main}
          stroke={theme.palette.text.primary}
        />
      ))}
    </svg>
  );
};

export default Globe;
