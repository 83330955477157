import React from "react";
import { Box, Button, Typography } from "@mui/material";
import paymentImage from "../../Components/globalAssets/blackwhite.png";
import { useNavigate } from "react-router-dom";
import routes from "../../Config/routes"; // Import the routes object

const PaymentSection = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        color: "#000", // Light mode text color
        backgroundColor: "#ececec", // Light mode background color
        px: 3,
        py: 10, // Increased vertical padding for taller section
      }}
    >
      {/* Left Section with Image */}
      <Box
        flex={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: { xs: 4, md: 0 },
        }}
      >
        <img
          src={paymentImage}
          alt="Payment"
          style={{
            width: "100%",
            maxWidth: "400px",
            borderRadius: "50%", // Circular frame
            objectFit: "cover", // Ensure the image covers the circular frame properly
          }}
        />
      </Box>

      {/* Right Section with Text */}
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          Pay your creators all in one place
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Sometimes creators cannot accept payments through your desired payment method. We make sure your talent gets paid on time and every time.
        </Typography>
        <Button
          variant="contained"
          sx={{
            px: 4,
            py: 1.5,
            fontSize: "1rem",
            textTransform: "none",
            backgroundColor: "#7b2a2a", // Light mode primary color
            color: "#fff",
          }}
          onClick={handleAccess}
        >
          Request Access
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentSection;
