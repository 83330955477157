import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Card, CardContent, CardActions, Button, CircularProgress, Grid, Paper, Tabs, Tab } from '@mui/material';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import useAgencyAuth from '../../Hooks/agency-use-auth';
import AgencyNavbar from '../../Components/Navbar/agencyNavBar';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ConfigValue } from '../../Config';

const AgencyPartnerships = () => {
  const [currentFilter, setCurrentFilter] = useState('active');
  const [loadingTabSwitch, setLoadingTabSwitch] = useState(false);
  const { getToken, getCompanyInfo } = useAgencyAuth();
  const token = getToken();
  const agencyName = getCompanyInfo()?.name;
  const navigate = useNavigate();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const fetchAgencyPartnerships = async () => {
    const token = getToken();
    const agencyName = getCompanyInfo()?.name;
  
    if (!token || !agencyName) {
      throw new Error('Token or agency name missing');
    }
  
    const url = `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/agency/agencyPartnerships?agencyName=${encodeURIComponent(agencyName)}`;
  
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch partnerships');
    }
  
    const data = await response.json();
    return data;
  };
  
  const { isLoading, error, data: partnerships, refetch } = useQuery(
    ['agency', 'partnerships', agencyName],
    fetchAgencyPartnerships,
    {
      enabled: !!agencyName && !!token,  // Ensure fetch only happens when agencyName and token are available
      keepPreviousData: false,
      onSettled: () => setLoadingTabSwitch(false),
    }
  );

  useEffect(() => {
    if (!loadingTabSwitch) {
      refetch();
    }
  }, [loadingTabSwitch, refetch]);

  const handleTabChange = (event, value) => {
    setCurrentFilter(value);
    setLoadingTabSwitch(true);
    refetch();
  };

  const handleOpenDialog = (partnership) => {
    navigate(`/agency/partnerships/${partnership.id}`, {
      state: {
        creatorName: partnership.creator,
        agencyName: agencyName,
      },
    });
  };

  const filteredPartnerships = useMemo(() => {
    return partnerships ? partnerships.filter(partnership => {
      switch (currentFilter) {
        case 'completed':
          return partnership.status === 'Completed';
        case 'active':
        default:
          return partnership.status !== 'Completed' && partnership.status !== 'DELETED';
      }
    }) : [];
  }, [partnerships, currentFilter]);

  return (
    <Box display="flex" flexDirection="row">
      {/* Navbar */}
      <AgencyNavbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Typography variant="h6" gutterBottom>
          All Partnerships for {agencyName}
        </Typography>

        <Paper elevation={2} sx={{ marginBlockEnd: 2 }}>
          <Tabs value={currentFilter} onChange={handleTabChange} centered>
            <Tab label="Active" value="active" />
            <Tab label="Completed" value="completed" />
          </Tabs>
        </Paper>

        {loadingTabSwitch && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <CircularProgress sx={{ color: 'primary.main' }} />
          </Box>
        )}

        {!loadingTabSwitch && (
          <Grid container spacing={3} sx={{ mt: 2 }}>
            {filteredPartnerships.length > 0 ? filteredPartnerships.map(partnership => (
              <Grid item xs={12} sm={6} md={4} key={partnership.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h6">{partnership.name}</Typography>
                    <Typography color="textSecondary">{partnership.description}</Typography>
                    <Typography color="textSecondary">Status: {partnership.status}</Typography>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" color="primary" onClick={() => handleOpenDialog(partnership)}>
                      Manage Partnership
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            )) : (
              <Grid item xs={12}>
                <Typography align="center">No partnerships available</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default AgencyPartnerships;
