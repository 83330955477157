import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, CircularProgress, Badge, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useCreatorAuth } from '../../../Hooks/creator-use-auth';
import ZapFeed from './zapfeed/zapfeed';  // Import the updated ZapFeed component
import { useQuery } from 'react-query';
import API from '../../../API';
import routes from './../../../Config/routes';
import useIsDesktop from '../../../Hooks/useIsDesktop';

const CreatorStart = () => {
  const navigate = useNavigate();
  const { creatorToken } = useCreatorAuth();
  const [openUnreadMessagesDialog, setOpenUnreadMessagesDialog] = useState(false);
  const isDesktop =  useIsDesktop();

  if (!creatorToken) return <>Loading</>;

  // Fetching data for action cards
  const { isLoading: isLoadingPayouts, error: errorPayouts, data: payouts } = useQuery(['payouts', creatorToken.creator_user.username], API.creatorConnect.payouts);
  const { isLoading: isLoadingCampaigns, error: errorCampaigns, data: campaigns } = useQuery(['campaigns', creatorToken.creator_user.username], API.creatorConnect.campaigns);
  const { isLoading: isLoadingUnreadMessages, error: errorUnreadMessages, data: unreadMessages } = useQuery('unreadMessages', API.creatorConnect.unreadMessages);

  if (isLoadingPayouts || isLoadingCampaigns || isLoadingUnreadMessages) return <CircularProgress sx={{ color: 'white' }} />;
  if (errorPayouts) return <Typography sx={{ color: 'error' }}>Error fetching payouts: {errorPayouts.message}</Typography>;
  if (errorCampaigns) return <Typography sx={{ color: 'error' }}>Error fetching campaigns: {errorCampaigns.message}</Typography>;
  if (errorUnreadMessages) return <Typography sx={{ color: 'error' }}>Error fetching unread messages: {errorUnreadMessages.message}</Typography>;

  // Calculating total earnings and status breakdown
  const totalEarnings = payouts?.reduce((sum, payout) => sum + payout.amount, 0) || 0;
  const pendingEarnings = payouts?.filter(payout => payout.status === 'Pending').reduce((sum, payout) => sum + payout.amount, 0) || 0;
  const approvedEarnings = payouts?.filter(payout => payout.status === 'Approved').reduce((sum, payout) => sum + payout.amount, 0) || 0;

  // Calculating campaign counts and status breakdown
  const totalCampaigns = campaigns?.length || 0;
  const draftCampaigns = campaigns?.filter(campaign => campaign.campaign_status === 'Draft').length || 0;
  const launchedCampaigns = campaigns?.filter(campaign => campaign.campaign_status === 'Launched').length || 0;

  const handleUnreadMessagesClick = () => {
    setOpenUnreadMessagesDialog(true);
  };

  const handleUnreadMessagesClose = () => {
    setOpenUnreadMessagesDialog(false);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  return (
    <>
      <Box sx={{
        paddingBlockStart: 4,
        paddingInline: { xs: 2, md: 8 },
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}>
        <Typography variant="h5" gutterBottom>
          Welcome, {creatorToken.creator_user.username}!
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: isDesktop ? 'row' : 'column',
          justifyContent: 'space-between',
          gap: 2,
        }}>
          <Box>
            <ZapFeed />
          </Box>
          <Box sx={{ flex: 1, minWidth: { xs: 'auto', sm: '25em' } }}>
            <Typography variant="h6" gutterBottom>
              Quick Access
            </Typography>

            <Grid container spacing={2} sx={{ padding: 0, marginBottom:2 }}>
              {/* Total Earnings Card */}
              <Grid item xs={12} sm={6}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">Total Earnings</Typography>
                    <Typography variant="body1">Total: ${totalEarnings.toFixed(2)}</Typography>
                    <Typography variant="body2" color="textSecondary">Pending: ${pendingEarnings.toFixed(2)}</Typography>
                    <Typography variant="body2" color="textSecondary">Approved: ${approvedEarnings.toFixed(2)}</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectPayoutsInvoicing)}>
                      View Earnings
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Total Campaigns Card */}
              <Grid item xs={12} sm={6}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">Total Campaigns</Typography>
                    <Typography variant="body1">Total: {totalCampaigns}</Typography>
                    <Typography variant="body2" color="textSecondary">Draft: {draftCampaigns}</Typography>
                    <Typography variant="body2" color="textSecondary">Launched: {launchedCampaigns}</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectCampaigns)}>
                      View Campaigns
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Unread Messages Card */}
              <Grid item xs={12}>
                <Card elevation={2} sx={{ backgroundColor: '#ffebee' }}>
                  <CardContent>
                    <Badge badgeContent={unreadMessages?.length || 0} color='secondary'>
                      <Typography variant="h6">Unread Messages</Typography>
                    </Badge>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {unreadMessages?.length
                        ? `You have ${unreadMessages.length} unread messages across ${unreadMessages.length} campaigns.`
                        : 'You have no unread messages.'}
                    </Typography>
                    <Button variant="contained" onClick={handleUnreadMessagesClick} disabled={unreadMessages?.length === 0}>
                      View Unread Messages
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Partnerships Card */}
              <Grid item xs={12} sm={6}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">Partnerships</Typography>
                    <Typography variant="body2" color="textSecondary">Manage your partnerships and collaborations.</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectPartnerships)}>
                      View Partnerships
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Pitch Card */}
              <Grid item xs={12} sm={6}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">Pitch</Typography>
                    <Typography variant="body2" color="textSecondary">Send out pitches and proposals.</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectPitch)}>
                      View Pitches
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Mediakit Card */}
              <Grid item xs={12} sm={6}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">Mediakit</Typography>
                    <Typography variant="body2" color="textSecondary">Manage your media and content assets.</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectEdit)}>
                      View Mediakit
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* Deals & Emails Card */}
              <Grid item xs={12} sm={6}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">Deals & Emails</Typography>
                    <Typography variant="body2" color="textSecondary">View and manage your email communications.</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectDealsEmails)}>
                      View Emails
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              {/* More Options Card */}
              <Grid item xs={12}>
                <Card elevation={2}>
                  <CardContent>
                    <Typography variant="h6">More Options</Typography>
                    <Typography variant="body2" color="textSecondary">Explore more options and settings.</Typography>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={() => handleNavigate(routes.creatorConnectMore)}>
                      View More
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {/* Unread Messages Dialog */}
      <Dialog open={openUnreadMessagesDialog} onClose={handleUnreadMessagesClose} fullWidth maxWidth="sm">
        <DialogTitle>Unread Messages</DialogTitle>
        <DialogContent>
          <List>
            {unreadMessages?.map((campaign) => (
              <Box key={campaign.campaign_id}>
                <Typography variant="h6" sx={{ marginTop: 2 }}>{campaign.campaign_name}</Typography>
                {campaign.messages.map((message) => (
                  <ListItem key={message.message_id} onClick={() => handleNavigate(routes.creatorConnectCampaigns)}>
                    <ListItemText primary={message.text} secondary={`Received on ${new Date(message.created_at).toLocaleString()}`} />
                  </ListItem>
                ))}
              </Box>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreatorStart;
