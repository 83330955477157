import { ListItemIcon, ListItemText, styled } from '@mui/material';
import React from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import WhiteListButton from './WhiteListButton';

const ListItemLink = ({ to, primary, icon, isOpen = true }) => {
    const location = useLocation();
    const isActive = location.pathname === to;
    return (
        <WhiteListButton component={RouterLink} to={to} isLocation={isActive}
            sx={{
                    justifyContent: isOpen ? 'initial' : 'center'
                }}>
            {icon ? <ListItemIcon 
            sx={{ color: isActive ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.87)', minWidth: '36px', marginInlineEnd:isOpen?0:'auto', justifyContent: isOpen ? 'initial' : 'center' }}>{icon}</ListItemIcon> : null}
            <ListItemText primaryTypographyProps={{ fontSize: '1.2rem' }} primary={primary} sx={{opacity:isOpen?1:0}} />
        </WhiteListButton>
    );
};

export default ListItemLink;