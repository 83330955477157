import { atom, useAtom } from "jotai";
import Cookies from "js-cookie";
import { ConfigValue } from "../Config";

// Define keys for storing token and agency information
export const AGENCY_AUTH_TOKEN_KEY = "agency-auth-token";
export const AGENCY_AUTH_COMPANY_INFO = "agency-auth-company";

// Create an atom to manage the authorization state
const agencyAuthorizationAtom = atom(checkHasAgencyAuthToken());

export default function useAgencyAuth() {
  const [isAuthorized, setAuthorized] = useAtom(agencyAuthorizationAtom);

  return {
    setToken: (token) => setAgencyAuthToken(token),
    getToken: () => getAgencyAuthToken(),
    getCompanyInfo: () => getAgencyCompanyInfo(),
    setCompanyInfo: (info) => setAgencyCompanyInfo(info),
    isAuthorized,
    authorize(token, companyInfo) {
      setAgencyAuthToken(token);
      setAgencyCompanyInfo(companyInfo);
      setAuthorized(true);
    },
    unauthorize() {
      setAuthorized(false);
      removeAgencyAuthToken();
    },
  };
}

// Helper functions to manage agency auth token and company info in cookies

export const getAgencyAuthToken = () => {
  if (typeof window === undefined) {
    return null;
  }
  return Cookies.get(AGENCY_AUTH_TOKEN_KEY);
};

export function setAgencyAuthToken(token) {
  Cookies.set(AGENCY_AUTH_TOKEN_KEY, token, { expires: 1 });
}

export function removeAgencyAuthToken() {
  Cookies.remove(AGENCY_AUTH_TOKEN_KEY);
}

export function checkHasAgencyAuthToken() {
  const token = Cookies.get(AGENCY_AUTH_TOKEN_KEY);
  if (!token) return false;
  return true;
}

export const getAgencyCompanyInfo = () => {
  if (typeof window === undefined) {
    return null;
  }

  const companyInfo = Cookies.get(AGENCY_AUTH_COMPANY_INFO);
  if (companyInfo) {
    return JSON.parse(companyInfo);
  } else {
    return null;
  }
};

export function setAgencyCompanyInfo(info) {
  Cookies.set(AGENCY_AUTH_COMPANY_INFO, JSON.stringify(info), { expires: 1 });
}
