import React from 'react';
import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';

const helpContent = {
  '/home': {
    video: 'https://www.youtube.com/embed/home_video_id',
    text: 'This is the home page where you can find the latest updates and featured content.',
  },
  '/dashboard': {
    video: 'https://www.youtube.com/embed/dashboard_video_id',
    text: 'This is the dashboard where you can view your overall account status and access different features.',
  },
  '/search': {
    video: 'https://www.youtube.com/embed/WGYbGZfiWjI?si=DZBW60_q9FBAwUo0',
    text: 'This is the search page where you can find campaigns, creators, and other resources.',
  },
  '/campaigns': {
    video: 'https://www.youtube.com/embed/vBEEWHIv3ms?si=EprBNuvQnmELE4Og',
    text: 'This is the campaigns page where you can manage your campaigns and track their progress.',
  },
  '/campaigns/': {
    video: 'https://www.youtube.com/embed/xf-IPA2Mx4A?si=5wdqs6xyPUbHn049',
    text: 'This is a page dedicated to viewing and interacting with your inquiry or current campaign. Here, you can track progress, view reporting, and extract projections.',
  },
  '/blitzpay/': {
    video: 'https://www.youtube.com/embed/mm1Xqrr_eng?si=D4vAtn_E9ZVDiVk7',
    text: 'This is the creator media kit page where you can manage a creator’s information and bookings.',
  },
};

const HelpPage = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const paths = Object.keys(helpContent);
  const currentPath = paths[selectedTab];
  const content = helpContent[currentPath] || {};

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{ style: { backgroundColor: '#ffffff' } }} // Hardcoded indicator color
          style={{ backgroundColor: '#333', color: '#ffffff' }} // Hardcoded tab background and text color
        >
          {paths.map((path, index) => (
            <Tab
              key={index}
              label={path.slice(1).toUpperCase() || 'HOME'}
              style={{ color: '#ffffff' }} // Hardcoded text color
            />
          ))}
        </Tabs>
      </AppBar>
      <Box p={3} style={{ backgroundColor: '#f4f4f4', color: '#333' }}> {/* Hardcoded background and text color for content */}
        <Typography variant="h4" gutterBottom>
          {currentPath.slice(1).toUpperCase() || 'HOME'} Help
        </Typography>
        <Box mb={2}>
          {content.video ? (
            <iframe
              width="100%"
              height="400"
              src={content.video}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Help Video"
            ></iframe>
          ) : (
            <Typography>No video available for this section.</Typography>
          )}
        </Box>
        <Typography variant="body1">{content.text || 'No explanation available for this section.'}</Typography>
      </Box>
    </Box>
  );
};

export default HelpPage;
