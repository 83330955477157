import React, { useState } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Paper,
  Button,
  Skeleton,
  Link,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import API from '../../../../API';

const ZapFeed = () => {
  const [visibleCount, setVisibleCount] = useState(5);
  const canPitch = true;
  const navigate = useNavigate();

  const {data: feed} = useQuery({
    queryKey:['zapfeed'],
    queryFn:API.creatorConnect.getZapFeed
  })

  const getAllActivities = () => {
    return feed?.flatMap((creator) =>
        creator.activity.map((activity) => ({
          ...activity,
          creatorName: creator.creator_username,
          pfphref: creator.pfphref,
        }))
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date))||[];
  };

  const activities = getAllActivities();
  const visibleActivities = activities.slice(0, visibleCount);

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  const handlePitchClick = (activity) => {
    const id = activity.details.id;
    if (activity.type === 'campaign' || activity.type === 'pitch') {
      navigate(`/creatorconnect/pitch/campaign/${id}`);
    } else if (activity.type === 'partnership') {
      navigate(`/creatorconnect/pitch/partnership/${id}`);
    }
  };

  const getActivitySummaryJSX = (activity) => {
    const { creatorName, type, details, date } = activity;
    const formattedDate = new Date(date).toLocaleDateString();

    const creatorLink = (
      <Link
        href={`https://useblitz.co/creators/${creatorName}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {creatorName}
      </Link>
    );

    switch (type) {
      case 'payout':
        return (
          <>
            {creatorLink} got paid on {formattedDate}
          </>
        );
      case 'campaign':
        return (
          <>
            {creatorLink} started working on the {details.name} campaign on {formattedDate}
          </>
        );
      case 'partnership':
        return (
          <>
            Partnership for {creatorLink} started on {formattedDate}
          </>
        );
      case 'pitch':
        return (
          <>
            {creatorLink} got pitched for {details.name} on {formattedDate}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="h6" gutterBottom align="left">
        ZapFeed - See what other creators are doing
      </Typography>

      <List
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0 }}
      >
        {visibleActivities.map((activity, index) => (
          <Paper
            key={index}
            elevation={1}
            sx={{
              width: '100%',
              maxWidth: '40rem',
              marginBottom: 1,
              padding: 1,
              backgroundColor: 'background.paper',
            }}
          >
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={activity.creatorName} src={activity.pfphref} />
              </ListItemAvatar>
              <Box sx={{ textAlign: 'left', color: 'text.primary', flex: 1 }}>
                <Typography>{getActivitySummaryJSX(activity)}</Typography>
                {canPitch && ['campaign', 'partnership', 'pitch'].includes(activity.type) && (
                  <Button
                    onClick={() => handlePitchClick(activity)}
                    variant="contained"
                    size="small"
                    sx={{ marginTop: 1 }}
                  >
                    Pitch to this deal
                  </Button>
                )}
              </Box>
            </ListItem>
          </Paper>
        ))}
        {visibleActivities.length === 0 && (
          <Paper elevation={1} sx={{ width: '100%', marginBottom: 1, padding: 1 }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </ListItemAvatar>
              <Box sx={{ textAlign: 'left', flex: 1 }}>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
              </Box>
            </ListItem>
          </Paper>
        )}
      </List>
      {visibleCount < activities.length && (
        <Button onClick={handleShowMore} variant="outlined" sx={{ marginTop: 2 }}>
          Show More
        </Button>
      )}
    </Box>
  );
};

export default ZapFeed;
