import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
  FormControlLabel
} from "@mui/material";
import { useMutation } from "react-query";
import { styled } from "@mui/system";
import client from "../../../../../API";

const StyledDialogTitle = styled(DialogTitle)`
  background-color: #424242;
  color: white;
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  background-color: #424242;
`;

const SendOfferDialog = ({ open, onClose, partnershipId, creatorId, conversationId, onOfferSent }) => {
  const [deliverableType, setDeliverableType] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [platforms, setPlatforms] = useState([]);
  const [revision, setRevision] = useState("");
  const [script, setScript] = useState(false);  // Boolean for script
  const [isLoading, setIsLoading] = useState(false);  // Loading state

  const createDeliverableMutation = useMutation(client.deliverables.create, {
    onSuccess: () => {
      setIsLoading(false);
      onClose();
      onOfferSent();
    },
    onError: (error) => {
      setIsLoading(false);
      console.error("Error creating deliverable:", error);
    },
  });

  const handleSubmit = () => {
    setIsLoading(true);
    createDeliverableMutation.mutate({
      partnership_id: partnershipId,
      creator_id: creatorId,
      conversation_id: conversationId,
      deliverable_type: deliverableType,
      deliverable_link: null,
      amount,
      status: "sent",
      notes,
      due_date: dueDate,
      platforms,
      revision,
      script,  // Send boolean value for script
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <StyledDialogTitle>Send Offer</StyledDialogTitle>
      <StyledDialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Deliverable Type</InputLabel>
          <Select
            value={deliverableType}
            onChange={(e) => setDeliverableType(e.target.value)}
          >
            <MenuItem value="Brand Post">Brand Post</MenuItem>
            <MenuItem value="UGC Post">UGC Post</MenuItem>
            <MenuItem value="Story Post">Story Post</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          margin="normal"
          label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
        <TextField
          fullWidth
          margin="normal"
          label="Brief"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Due Date"
          type="date"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Revision"
          value={revision}
          onChange={(e) => setRevision(e.target.value)}
          type="number"
        />

        {/* Checkbox for Boolean Script */}
        <FormControlLabel
          control={
            <Checkbox
              checked={script}
              onChange={(e) => setScript(e.target.checked)}
              color="primary"
            />
          }
          label="Include Script"
        />

        <FormControl fullWidth margin="normal">
          <InputLabel>Platform Type</InputLabel>
          <Select
            multiple
            value={platforms}
            onChange={(e) => setPlatforms(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
          >
            {["TikTok", "Instagram", "YouTube"].map((platform) => (
              <MenuItem key={platform} value={platform}>
                <Checkbox checked={platforms.indexOf(platform) > -1} />
                <ListItemText primary={platform} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>

        {/* Conditional rendering for loading state */}
        <Button onClick={handleSubmit} color="primary" disabled={isLoading}>
          {isLoading ? <CircularProgress size={24} /> : "Send Offer"}
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default SendOfferDialog;
