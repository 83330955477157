import React, { useState } from 'react';
import {
    Box,
    TextField,
    Checkbox,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    Divider,
} from '@mui/material';
import { parseLocalFloat, parseLocalInt } from '../Utils/constants';
import { RateField } from '../Components/RateField';

const OnboardPage2 = ({ minWidth, onFormSubmit }) => {

    const [platforms, setPlatforms] = useState({
        TikTok: false,
        Instagram: false,
        YouTube: false,
    });

    // Instagram
    const [instagramLink, setInstagramLink] = useState("");
    const [instagramBrandRate, setInstagramBrandRate] = useState("");
    const [instagramFollowerCount, setInstagramFollowerCount] = useState("");
    const [instagramSongRate, setInstagramSongRate] = useState("");
    const [instagramStoryRate, setInstagramStoryRate] = useState("");

    // TikTok
    const [tikTokLink, setTikTokLink] = useState("");
    const [tikTokFollowerCount, setTikTokFollowerCount] = useState("");
    const [tikTokBrandRate, setTikTokBrandRate] = useState("");
    const [tikTokSongRate, setTikTokSongRate] = useState("");

    // YouTube
    const [youtubeLink, setYoutubeLink] = useState("");
    const [youtube30sBrandRate, setYoutube30sBrandRate] = useState("");
    const [youtubeFollowerCount, setYoutubeFollowerCount] = useState("");
    const [youtube60sBrandRate, setYoutube60sBrandRate] = useState("");
    const [youtubeShortsBrandRate, setYoutubeShortsBrandRate] = useState("");


    const handlePlatformChange = (event) => {
        setPlatforms({ ...platforms, [event.target.name]: event.target.checked });
    };

    const handleFollowerCountChange = (setter) => (e) => {
        const rawValue = e.target.value.replace(/[^0-9]/g, "");
        const formattedValue = formatNumber(rawValue);
        setter(formattedValue);
    };

    const formatNumber = (value) => {
        const number = parseInt(value, 10);
        return isNaN(number) ? "" : number.toLocaleString();
    };

    const formatRate = (value) => {
        const number = parseInt(value, 10);
        return isNaN(number) ? "" : `${number.toLocaleString()}`;
    };

    const SubmitForm = (event) => {
        event.preventDefault();

        const formattedTikTokLink = tikTokLink ? `https://www.tiktok.com/@${tikTokLink}` : null;
        const formattedInstagramLink = instagramLink ? `https://www.instagram.com/${instagramLink}` : null;
        const formattedYouTubeLink = youtubeLink ? `https://www.youtube.com/${youtubeLink}` : null;

        const anyPlatform = [platforms.TikTok, platforms.Instagram, platforms.YouTube].some((value) => value);
        if (!anyPlatform) {
            alert('You need to select at least one platform.');
            return;
        }

        if (platforms.TikTok) {
            const anyPromo = [tikTokBrandRate, tikTokSongRate].some((value) => value);
            if (!anyPromo) {
                alert('You need to select at least one promotion type for TikTok.');
                return;
            }
        }

        if (platforms.Instagram) {
            const anyPromo = [instagramBrandRate, instagramSongRate, instagramStoryRate].some((value) => value);
            if (!anyPromo) {
                alert('You need to select at least one promotion type for Instagram.');
                return;
            }
        }

        if (platforms.YouTube) {
            const anyPromo = [youtube30sBrandRate, youtube60sBrandRate, youtubeShortsBrandRate].some((value) => value);
            if (!anyPromo) {
                alert('You need to select at least one promotion type for YouTube.');
                return;
            }
        }

        let payload = null;

        try {
            payload = {
                tiktok: parseLocalInt(tikTokFollowerCount) || null,
                tiktok_link: formattedTikTokLink || null,
                instagram: parseLocalInt(instagramFollowerCount) || null,
                instagram_link: formattedInstagramLink || null,
                youtube: parseLocalInt(youtubeFollowerCount) || null,
                youtube_link: formattedYouTubeLink || null,
                rate_tt_sound: parseLocalFloat(tikTokSongRate),
                rate_tt_brand: parseLocalFloat(tikTokBrandRate),
                rate_ig_feedpost: parseLocalFloat(instagramStoryRate),
                rate_ig_reelsound: parseLocalFloat(instagramSongRate),
                rate_ig_reelbrand: parseLocalFloat(instagramBrandRate),
                rate_yt_integ30s: parseLocalFloat(youtube30sBrandRate),
                rate_yt_integ60s: parseLocalFloat(youtube60sBrandRate),
                rate_yt_short: parseLocalFloat(youtubeShortsBrandRate),
            };
        } catch (error) {
            alert(`Error parsing this form: ${error}`);
            return;
        }


        onFormSubmit(payload);
    };

    return (
        <>
            <form id="page-1" onSubmit={SubmitForm}>
                <Box sx={{ flexDirection: 'column', display: 'flex', minWidth }}>
                    <FormGroup row>
                        {Object.keys(platforms).map((platform) => (
                            <FormControlLabel
                                key={platform}
                                control={
                                    <Checkbox
                                        checked={platforms[platform]}
                                        onChange={handlePlatformChange}
                                        name={platform}
                                    />
                                }
                                label={platform}
                            />
                        ))}
                    </FormGroup>
                    {(platforms.Instagram || platforms.TikTok || platforms.YouTube) && <Divider sx={{ marginBlockEnd: '1em' }}></Divider>}
                    {platforms.Instagram && (
                        <>
                            <TextField
                                margin="dense"
                                label="Instagram Username"
                                type="text"
                                fullWidth
                                value={instagramLink}
                                onChange={(e) => setInstagramLink(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            instagram.com/
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="Instagram Follower Count"
                                type="text"
                                fullWidth
                                value={instagramFollowerCount}
                                onChange={handleFollowerCountChange(setInstagramFollowerCount)}
                                variant="outlined"
                                required
                            />
                            <RateField
                                margin="dense"
                                label="Instagram Brand Rate"
                                fullWidth
                                value={instagramBrandRate}
                                onChange={(value) => setInstagramBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="Instagram Song Rate"
                                fullWidth
                                value={instagramSongRate}
                                onChange={(value) => setInstagramSongRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="Instagram Story Rate"
                                fullWidth
                                value={instagramStoryRate}
                                onChange={(value) => setInstagramStoryRate(value)}
                                variant="outlined"
                                minValue={20}
                            />
                        </>
                    )}
                    {platforms.TikTok && (
                        <>
                            <TextField
                                margin="dense"
                                label="TikTok Username"
                                type="text"
                                fullWidth
                                value={tikTokLink}
                                onChange={(e) => setTikTokLink(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            tiktok.com/@
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="TikTok Follower Count"
                                type="text"
                                fullWidth
                                value={tikTokFollowerCount}
                                onChange={handleFollowerCountChange(setTikTokFollowerCount)}
                                variant="outlined"
                                required
                            />
                            <RateField
                                margin="dense"
                                label="TikTok Brand Rate"
                                fullWidth
                                value={tikTokBrandRate}
                                onChange={(value) => setTikTokBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="TikTok Song Rate"
                                fullWidth
                                value={tikTokSongRate}
                                onChange={(value) => setTikTokSongRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                        </>
                    )}
                    {platforms.YouTube && (
                        <>
                            <TextField
                                margin="dense"
                                label="Youtube Username"
                                type="text"
                                fullWidth
                                value={youtubeLink}
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                variant="outlined"
                                required
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            youtube.com/
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="dense"
                                label="YouTube Follower Count"
                                type="text"
                                fullWidth
                                value={youtubeFollowerCount}
                                onChange={handleFollowerCountChange(setYoutubeFollowerCount)}
                                variant="outlined"
                                required
                            />
                            <RateField
                                margin="dense"
                                label="YouTube Brand Rate - 30s"
                                fullWidth
                                value={youtube30sBrandRate}
                                onChange={(value) => setYoutube30sBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="YouTube Brand Rate - 60s"
                                fullWidth
                                value={youtube60sBrandRate}
                                onChange={(value) => setYoutube60sBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                            <RateField
                                margin="dense"
                                label="YouTube Shorts Rate"
                                fullWidth
                                value={youtubeShortsBrandRate}
                                onChange={(value) => setYoutubeShortsBrandRate(value)}
                                variant="outlined"
                                minValue={100}
                            />
                        </>
                    )}
                </Box>
            </form>
        </>
    );
};

export default OnboardPage2;
