import React from "react";
import { Box, Button, Typography } from "@mui/material";
import aiManagerImage from "../../Components/globalAssets/blitzBoltBlack.png"; // Replace with actual image path
import routes from "../../Config/routes"; // Import the routes object
import { useNavigate } from "react-router-dom";

const AIManagerSection = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.demoRegister}/thealexroa`);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        alignItems: "center",
        color: "#000", // Light mode text color
        backgroundColor: "#ececec", // Light mode background color
        px: 3,
        py: 10, // Consistent padding across sections
      }}
    >
      {/* Left Section with Image */}
      <Box
        flex={1}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: { xs: 4, md: 0 },
        }}
      >
        <img
          src={aiManagerImage} // Use your AI Manager-related image here
          alt="AI Manager"
          style={{
            width: "100%",
            maxWidth: "400px", // Adjust as necessary
          }}
        />
      </Box>

      {/* Right Section with Text */}
      <Box
        flex={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: { xs: "center", md: "flex-start" },
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "bold", mb: 2 }}>
          All-in-One AI Manager
        </Typography>
        <Typography variant="h6" sx={{ mb: 3 }}>
          Don’t settle for a shoddy human manager who charges 40% premiums when our AI manager will do it all for free.
        </Typography>
        <Button
          variant="contained"
          sx={{
            px: 4,
            py: 1.5,
            fontSize: "1rem",
            textTransform: "none",
            backgroundColor: "#7b2a2a", // Light mode primary color
            color: "#fff",
          }}
          onClick={handleAccess}
        >
          Request Access
        </Button>
      </Box>
    </Box>
  );
};

export default AIManagerSection;
