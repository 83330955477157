import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import client from "../../../API";
import Globe from "./globe";
import { styled } from "@mui/material/styles";
import { useIsMounted } from "../../../Hooks/use-is-mounted";
import { StyledTableRow } from "../../../Utils/styledcell";
import ZapFeedForUser from "./zapfeedforusers";

const StyledGraphContainer = styled(Box)`
  max-width: 600px;
  height: 300px;
  margin: 20px;
  overflow: hidden;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #666;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionCardGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
  z-index: 2;
`;

const StyledGlobeFloat = styled(Box)`
  position: absolute;
  overflow-x: clip;
  overflow-y: clip;
  top: 0px;
  width: 450px;
  height: 450px;
  right: 0px;
  z-index: 0;
  opacity: 0.5;
`;

const StyledGlobeContainer = styled(Box)`
  position: absolute;
  top: -20px;
  right: -40px;
`;

const Dashboard = () => {
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const [creators, setCreators] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [payouts, setPayouts] = useState([]);
  const [partnerships, setPartnerships] = useState([]);
  const [aggregateChartData, setAggregateChartData] = useState([]);
  const [dataView, setDataView] = useState("campaigns");
  const [tabView, setTabView] = useState("table");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    companyId: "",
    companyName: "",
    id: "",
  });
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState({
    creators: true,
    campaigns: true,
    payouts: true,
    partnerships: true,
  });

  const fetchUserInfo = useMutation(client.users.fetchUser, {
    onSuccess: (data) => {
      const { first_name, last_name, company_name, id } = data;
      setUserInfo({
        firstName: first_name,
        lastName: last_name,
        companyName: company_name,
        id,
      });
      setCompanyName(company_name);
    },
    onError: (error) => {
      console.error("Error fetching user or company info:", error);
    },
  });

  const fetchCreators = useMutation(() => client.creators.listManager(companyName), {
    onSuccess: (data) => {
      setCreators(data.creators || []);
      setLoading((prev) => ({ ...prev, creators: false }));
    },
    onError: (error) => {
      console.error("Error fetching creators:", error);
      setLoading((prev) => ({ ...prev, creators: false }));
    },
  });

  const fetchCampaigns = useMutation(client.campaigns.list, {
    onSuccess: (data) => {
      setCampaigns(data);
      setLoading((prev) => ({ ...prev, campaigns: false }));
    },
    onError: (error) => {
      console.error("Error fetching campaigns:", error);
      setLoading((prev) => ({ ...prev, campaigns: false }));
    },
  });

  const fetchPayouts = useMutation(client.payouts.list, {
    onSuccess: (data) => {
      setPayouts(data.payouts || []);
      setLoading((prev) => ({ ...prev, payouts: false }));
    },
    onError: (error) => {
      console.error("Error fetching payouts:", error);
      setLoading((prev) => ({ ...prev, payouts: false }));
    },
  });

  const fetchPartnerships = useMutation(client.partnerships.list, {
    onSuccess: (data) => {
      setPartnerships(data || []);
      setLoading((prev) => ({ ...prev, partnerships: false }));
    },
    onError: (error) => {
      console.error("Error fetching partnerships:", error);
      setLoading((prev) => ({ ...prev, partnerships: false }));
    },
  });

  useEffect(() => {
    if (isMounted) {
      fetchUserInfo.mutate();
    }
  }, [isMounted]);

  useEffect(() => {
    if (companyName) {
      // Sequential fetching of data
      fetchCreators.mutate(undefined, {
        onSuccess: () => fetchCampaigns.mutate(undefined, {
          onSuccess: () => fetchPayouts.mutate(undefined, {
            onSuccess: () => fetchPartnerships.mutate(),
          }),
        }),
      });
    }
  }, [companyName]);

  const handleDataViewChange = (event, newValue) => {
    setDataView(newValue);
  };

  const handleTabViewChange = (event, newValue) => {
    setTabView(newValue);
  };

  const handleInviteFriend = () => {
    navigate(`/requestaccess/${userInfo.id}`);
  };

  const renderCombinedCard = (title, value, icon, description, buttonText, route, loading) => (
    <StyledCard>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5">
            {value} <span className="material-icons-round">{icon}</span>
          </Typography>
        )}
        <Typography variant="body2">{description}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => navigate(route)}>
          {buttonText}
        </Button>
      </CardActions>
    </StyledCard>
  );

  const tableHeaders = {
    creators: [
      "Creator",
      "TikTok",
      "Instagram",
      "YouTube",
      "Manager",
      "Email",
      "Date Added",
    ],
    campaigns: [
      "Name",
      "Campaign Sum",
      "Proposal Date",
      "Brief",
      "Campaign Status",
    ],
    payouts: ["Creator ID", "Amount", "Payout Date", "BlitzPay", "Status"],
    invoices: ["Campaign/Creator", "Amount Due", "Status"],
  };

  const renderTableData = () => {
    const dataSets = { creators, campaigns, payouts, invoices: [] };
    const data = dataSets[dataView] || [];
    return data.map((item, index) => (
      <StyledTableRow key={index}>
        {tableHeaders[dataView].map((header) => (
          <TableCell key={header}>
            {item[header.toLowerCase().replace(/\s/g, "_")]}
          </TableCell>
        ))}
      </StyledTableRow>
    ));
  };

  return (
    <Box p={2}>
      <StyledGlobeFloat>
        <StyledGlobeContainer>
          <Globe size={450} />
        </StyledGlobeContainer>
      </StyledGlobeFloat>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={7}>
          <Typography variant="h2">Welcome to Blitz - Dashboard</Typography>
          <ZapFeedForUser />
          <Button
            variant="contained"
            color="primary"
            onClick={handleInviteFriend}
            sx={{ marginTop: 2 }}
          >
            Invite a friend to blitz, earn commission!
          </Button>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={12}>
            <ActionCardGrid>
            {renderCombinedCard(
          "Total Campaigns",
          campaigns.length, // Actual campaign count
          "campaigns",
          "Review and manage your existing campaigns.",
          "View",
          "/campaigns",
          loading.campaigns // Loading state for campaigns
        )}
              {renderCombinedCard(
          "Total Payouts",
          `$${payouts.reduce((sum, payout) => sum + (payout.amount || 0), 0).toFixed(2)}`, // Sum of payout amounts
          "paid",
          "Issue payments to your creators quickly and securely.",
          "Pay",
          "/blitzpay",
          loading.payouts // Loading state for payouts
        )}
               {renderCombinedCard(
          "Total Creators",
          creators.length, // Actual creator count
          "creators",
          "Generate your unique Signup link to have creators onboard seamlessly.",
          "Generate Link",
          `/creatorsignup/${userInfo.companyName}`, // Dynamic company name for signup link
          loading.creators // Loading state for creators
        )}
              {renderCombinedCard(
          "Total Partnerships",
          partnerships.length, // Actual partnership count
          "partnerships in progress",
          "Review and manage your ongoing partnerships!",
          "View",
          `/campaigns`,
          loading.partnerships // Loading state for partnerships
        )}
            </ActionCardGrid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">In-Depth Summaries</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={1} sx={{ overflowX: "auto", maxWidth: "100%" }}>
            <Paper elevation={3} square={true} sx={{ position: "sticky", left: 0 }}>
              <Tabs value={tabView} onChange={handleTabViewChange}>
                <Tab label="Table" value="table" />
                <Tab label="Tutorials" value="tutorials" />
              </Tabs>
            </Paper>
            {tabView === "table" && (
              <>
                <Paper elevation={3} square={true} sx={{ position: "sticky", left: 0 }}>
                  <Tabs value={dataView} onChange={handleDataViewChange}>
                    <Tab label="Creators" value="creators" />
                    <Tab label="Campaigns" value="campaigns" />
                    <Tab label="Payouts" value="payouts" />
                    <Tab label="Invoices" value="invoices" />
                  </Tabs>
                </Paper>
                <Paper elevation={2} sx={{ width: "fit-content" }}>
                  {loading[dataView] ? (
                    <CircularProgress size={50} />
                  ) : (
                    <Table sx={{ marginBlockStart: 2 }}>
                      <TableHead>
                        <TableRow>
                          {tableHeaders[dataView].map((header) => (
                            <TableCell key={header}>{header}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>{renderTableData()}</TableBody>
                    </Table>
                  )}
                </Paper>
              </>
            )}

            {tabView === "tutorials" && (
              <>
                <Typography variant="h6">Watch Tutorials</Typography>
                {/* Render YouTube Videos */}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
