import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Button,
  Skeleton
} from '@mui/material';
import axios from 'axios';
import API from '../../../API';

const ZapFeedForUser = () => {
  const [feed, setFeed] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5); // Initial visible count is set to 5

  //TODO: REPLACE THIS WITH useQuery!!
  useEffect(() => {
    const fetchFeed = async () => {
      try {
        const data = await API.users.fetchZapFeed();
        setFeed(data);
      } catch (error) {
        console.error('Error fetching zap feed:', error);
      }
    };

    fetchFeed();
  }, []);

  const formatActivitySummary = (activity, userName, companyName) => {
    const date = new Date(activity.date).toLocaleDateString();
  
    switch (activity.type) {
      case 'payout':
        return `${userName} from ${companyName} sent a BlitzPay on ${date}`;
      case 'campaign':
        if (activity.creator_name) {
          return `${activity.creator_name} from ${companyName} worked with ${activity.details.name} on ${activity.creator_post_date}`;
        }
        return `${userName} from ${companyName} started the ${activity.details.name} campaign on ${date}`;
      case 'partnership':
        return `${userName} at ${companyName} started a partnership on ${date}`;
      default:
        return '';
    }
  };
  

  const getAllActivities = () => {
    return feed
      .flatMap(user =>
        user.activity.map(activity => ({
          ...activity,
          userName: user.first_name + ' ' + user.last_name,
          companyName: user.company_name,
          pfphref: user.pfphref,
        }))
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date));
  };

  const activities = getAllActivities();
  const visibleActivities = activities.slice(0, visibleCount);

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 5); // Load 5 more items on click
  };

  return (
    <Box sx={{ marginBottom: 3 }}>
      <Typography variant="h6" gutterBottom align="left">
        ZapFeed - Recent Activities
      </Typography>
      <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 0 }}>
        {visibleActivities.map((activity, index) => (
          <Paper key={index} elevation={1} sx={{ width: '100%', maxWidth: '40rem', marginBottom: 1, padding: 1 }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={activity.userName} src={activity.pfphref} />
              </ListItemAvatar>
              <ListItemText
                primary={formatActivitySummary(activity, activity.userName, activity.companyName)}
                sx={{ textAlign: 'left' }}
              />
            </ListItem>
          </Paper>
        ))}
        {visibleActivities.length === 0 && (
          <Paper elevation={1} sx={{ width: '100%', marginBottom: 1, padding: 1 }}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                }
                sx={{ textAlign: 'left' }}
              />
            </ListItem>
          </Paper>
        )}
      </List>
      {visibleCount < activities.length && (
        <Button onClick={handleShowMore} variant="outlined" sx={{ marginTop: 2 }}>
          Show More
        </Button>
      )}
    </Box>
  );
};

export default ZapFeedForUser;
