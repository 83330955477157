import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Divider,
} from "@mui/material";
import BlitzHeader from "../../../Components/BlitzHeader";
import routes from "../../../Config/routes";

const ForCreators = () => {
  const navigate = useNavigate();

  const handleAccess = () => navigate(`${routes.creatorConnectOnboard}`);

  return (
    <>
      <BlitzHeader>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            py={2}
          >
            {/* Logo Section */}
            <Box display="flex" alignItems="center" flex={1} justifyContent="flex-start">
              {/* BlitzLogo can be placed here */}
            </Box>

            {/* Menu Section */}
            <Box display="flex" alignItems="center" flex={1} justifyContent="center">
              <Button
                color="inherit"
                variant="text"
                onClick={() => navigate(routes.about)}
              >
                About
              </Button>
              <Button
                color="inherit"
                variant="text"
                onClick={() => navigate(routes.solutions)}
              >
                Solutions
              </Button>
            </Box>

            {/* Access Button */}
            <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleAccess}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        </Container>
      </BlitzHeader>

      {/* Main Content */}
      <Container
        sx={{
          backgroundColor: "#f5f5f5",
          color: "#000",
          paddingTop: "20px",
          paddingBottom: "20px",
          minHeight: "100vh",
          mt: 8, // Adds spacing between the header and content
        }}
      >
        {/* Section 1: Introduction */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
            Why Creators Choose Blitz
          </Typography>
          <Typography variant="body1" paragraph>
            Unlock the full potential of your creative career with Blitz. Our platform simplifies your workflow, connects you with top brands, and accelerates your growth.
          </Typography>
        </Box>

        <Divider />

        {/* Section 2: All-in-One AI Manager */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            All-in-One AI Manager
          </Typography>
          <Typography variant="body1" paragraph>
            Blitz’s AI Manager instantly improves your deal flow by connecting you with top brands and automating your negotiations. Say goodbye to hefty human manager fees and hello to streamlined, AI-powered success.
          </Typography>
        </Box>

        <Divider />

        {/* Section 3: Marketplace Access */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Access to the World's Top Brands
          </Typography>
          <Typography variant="body1" paragraph>
            Gain exclusive access to Blitz’s global marketplace, where the world's leading brands are searching for creators like you. Expand your reach, secure high-paying partnerships, and grow your personal brand.
          </Typography>
        </Box>

        <Divider />

        {/* Section 4: Business Management Suite */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Personalized Business Management Suite
          </Typography>
          <Typography variant="body1" paragraph>
            Manage your entire business with ease using Blitz’s suite of tools designed to handle everything from legal contracts to financial services, tax, and investment planning. Tailor-made for creators, Blitz gives you control over your career.
          </Typography>
        </Box>

        <Divider />

        {/* Section 5: Financial Growth */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Increase Your Revenue by 2-3x
          </Typography>
          <Typography variant="body1" paragraph>
            By leveraging Blitz's powerful tools and resources, you can significantly increase your revenue, achieving 2-3x growth. Maximize your income while minimizing the time and effort spent managing your career.
          </Typography>
        </Box>

        <Divider />

        {/* Section 6: Get Paid Faster */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Get Paid Faster
          </Typography>
          <Typography variant="body1" paragraph>
            BlitzPay ensures you receive payments quickly and efficiently, so you can focus on what you do best—creating. No more delays or uncertainty, just fast and reliable payments every time.
          </Typography>
        </Box>

        <Divider />

        {/* Section 7: Discover */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Discover the Power of Blitz
          </Typography>
          <Typography variant="body1" paragraph>
            The future of creator management is here. Join the Blitz revolution today and unlock the full potential of our platform. Let’s schedule a meeting to secure your free early access and begin your journey toward innovation and success.
          </Typography>
          <Button
            variant="contained"
            sx={{
              mt: 3,
              px: 4,
              py: 1.5,
              fontSize: "1rem",
              textTransform: "none",
              backgroundColor: "#7b2a2a",
              color: "#fff",
            }}
            onClick={handleAccess}
          >
            Request Access
          </Button>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ backgroundColor: "#f5f5f5", py: 4, textAlign: "center" }}>
        <Typography
          variant="body2"
          color="inherit"
        >
          © 2024 Pangea, Inc. All Rights Reserved.
        </Typography>
      </Box>
    </>
  );
};

export default ForCreators;
