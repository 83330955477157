import React from "react";
import { Card, CardContent, CardHeader, Typography, Box, Button } from "@mui/material";

const DeliverableCard = ({ deliverable, onViewOffer }) => (
  <Card sx={{ 
      marginBottom: 2, 
      color: "white", 
      display: "flex", 
      flexDirection: "column", 
      minHeight: 220,  // Set a minimum height to ensure it doesn't shrink too much
      height: "auto"  // Allow the height to adjust as needed but not shrink
    }}>
    <CardHeader title={`Deliverable: ${deliverable.deliverable_type}`} />
    <CardContent sx={{ overflowY: "auto", flexGrow: 1 }}>
      <Typography variant="body2">
        Amount: {deliverable.amount || 'N/A'}
      </Typography>
      <Typography variant="body2">
        Status: {deliverable.status}
      </Typography>
      <Typography variant="body2">
        Due Date: {deliverable.due_date}
      </Typography>
      <Typography variant="body2">
        Platforms: {deliverable.platforms.join(', ')}
      </Typography>
    </CardContent>
    <Box sx={{ padding: 2 }}>
      <Button onClick={() => onViewOffer(deliverable)} color="primary" variant="contained" fullWidth>
        View Offer
      </Button>
    </Box>
  </Card>
);

export default DeliverableCard;
