import React, { useState } from 'react';
import { useQuery } from 'react-query';
import client from '../../API';
import UploadFileDialog from '../../Components/tools/uploadfile';
import CreateAssetDialog from '../../Components/tools/createasset';
import {
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Paper,
  Divider,
  Tabs,
  Tab,
} from '@mui/material';
import { PictureAsPdf, Description, Image } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AssetsManager = () => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [createAssetDialogOpen, setCreateAssetDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [prettyView, setPrettyView] = useState(true);
  const [activeTab, setActiveTab] = useState("all");

  const { data: files, refetch: refetchFiles } = useQuery('files', () => client.files.fetch(''));

  const openUploadDialog = () => setUploadDialogOpen(true);
  const closeUploadDialog = () => {
    setUploadDialogOpen(false);
    refetchFiles();
  };

  const openCreateAssetDialog = () => setCreateAssetDialogOpen(true);
  const closeCreateAssetDialog = () => {
    setCreateAssetDialogOpen(false);
    refetchFiles();
  };

  const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const togglePrettyView = () => setPrettyView((prev) => !prev);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filterFiles = (files, filter) => {
    if (!files) return [];
    switch (filter) {
      case 'html':
        return files.filter(file => file.type === 'text/html');
      case 'pdf':
        return files.filter(file => file.type === 'application/pdf');
      case 'png':
        return files.filter(file => file.type === 'image/png');
      case 'misc':
        return files.filter(file => !['text/html', 'application/pdf', 'image/png'].includes(file.type));
      default:
        return files;
    }
  };

  const renderFileIcon = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return <PictureAsPdf fontSize="large" />;
      case 'text/html':
        return <Description fontSize="large" />;
      case 'image/jpeg':
      case 'image/png':
        return <Image fontSize="large" />;
      default:
        return <Description fontSize="large" />;
    }
  };

  const renderFilePreview = (file) => {
    if (file.type && file.type.startsWith('image/')) {
      return <img src={file.file_url} alt={file.filename} style={{ width: '100%', height: 'auto' }} />;
    } else {
      return renderFileIcon(file.type);
    }
  };

  const filteredFiles = filterFiles(files, activeTab);

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Paper elevation={2} square sx={{ padding: 2, mb: 4 }}>
        <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: 'center' }}>
          Assets Manager
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={openUploadDialog}
            startIcon={<MoreVertIcon />}
          >
            Upload Asset
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={openCreateAssetDialog}
            startIcon={<MoreVertIcon />}
          >
            Create Asset
          </Button>
        </Box>
      </Paper>

      <Tabs value={activeTab} onChange={handleTabChange} centered sx={{ mb: 4 }}>
        <Tab label="All" value="all" />
        <Tab label="HTML" value="html" />
        <Tab label="PDF" value="pdf" />
        <Tab label="PNG" value="png" />
        <Tab label="Misc" value="misc" />
      </Tabs>

      <FormControlLabel
        control={<Switch checked={prettyView} onChange={togglePrettyView} />}
        label="Make it pretty"
        sx={{ mb: 4 }}
      />

      {prettyView ? (
        <Grid container spacing={3}>
          {filteredFiles?.map((file) => (
            <Grid item xs={12} sm={6} md={4} key={file.id}>
              <Card
                onClick={() => window.open(file.file_url, '_blank')}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: 6,
                    transform: 'translateY(-4px)',
                  },
                }}
              >
                <CardContent>
                  {renderFilePreview(file)}
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {file.filename}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <table style={{ margin: '0 auto', width: '80%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Filename</th>
              <th>File URL</th>
            </tr>
          </thead>
          <tbody>
            {filteredFiles?.map((file) => (
              <tr key={file.id}>
                <td>{file.id}</td>
                <td>{file.filename}</td>
                <td>
                  <a href={file.file_url} target="_blank" rel="noopener noreferrer">
                    {file.file_url}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <UploadFileDialog open={uploadDialogOpen} onClose={closeUploadDialog} />
      <CreateAssetDialog open={createAssetDialogOpen} onClose={closeCreateAssetDialog} />
    </Box>
  );
};

export default AssetsManager;
