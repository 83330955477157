import React from 'react';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, Box, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const helpContent = {
  '/home': {
    video: 'https://www.youtube.com/embed/home_video_id',
    text: 'This is the home page where you can find the latest updates and featured content.',
  },
  '/dashboard': {
    video: 'https://www.youtube.com/embed/dashboard_video_id',
    text: 'This is the dashboard where you can view your overall account status and access different features.',
  },
  '/search': {
    video: 'https://www.youtube.com/embed/vBEEWHIv3ms?si=EprBNuvQnmELE4Og',
    text: 'This is the search page where you can find campaigns, creators, and other resources.',
  },
  '/campaigns': {
    video: 'https://www.youtube.com/embed/vBEEWHIv3ms?si=EprBNuvQnmELE4Og',
    text: 'This is the campaigns page where you can manage your campaigns and track their progress.',
  },
  '/campaigns/': {
    video: 'https://www.youtube.com/embed/xf-IPA2Mx4A?si=5wdqs6xyPUbHn049',
    text: 'This is a page dedicated to viewing and interacting with your inquiry or current campaign. Here, you can track progress, view reporting, and extract projections.',
  },
  '/blitzpay': {
    video: 'https://www.youtube.com/embed/blitzpay_video_id',
    text: 'This is the BlitzPay page where you can manage payouts and financial transactions.',
  },
  '/invoicing': {
    video: 'https://www.youtube.com/embed/invoicing_video_id',
    text: 'This is the invoicing page where you can manage your invoices and payment status.',
  },
  '/creators/': {
    video: 'https://youtu.be/Uzl1iZx56Ng',
    text: 'This is the creator media kit page where you can manage a creator’s information and bookings.',
  },
  // Add more routes and their respective help content as needed
};

const HelpDialog = ({ open, onClose }) => {
  const [value, setValue] = React.useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const currentPath = location.pathname;
  const content = helpContent[currentPath] || {};
  const currentPathName = currentPath.charAt(1).toUpperCase() + currentPath.slice(2);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{`Help - ${currentPathName}`}</DialogTitle>
      <DialogContent>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Video" />
          <Tab label="Explanation" />
        </Tabs>
        <Box>
          {value === 0 && (
            <Box sx={{ mt: 2 }}>
              {content.video ? (
                <iframe
                  width="100%"
                  height="400"
                  src={content.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Help Video"
                ></iframe>
              ) : (
                <p>No video available for this page.</p>
              )}
            </Box>
          )}
          {value === 1 && (
            <Box sx={{ mt: 2 }}>
              <p>{content.text || 'No explanation available for this page.'}</p>
            </Box>
          )}
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button variant="contained" onClick={() => navigate('/help')}>
            Go to Help
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default HelpDialog;
