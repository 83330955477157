import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  TextField,
  Avatar,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import useAuth from "../../../../../Hooks/use-auth";
import client from "../../../../../API";

const ConversationsPopup = ({ open, handleClose, creator, conversationId }) => {
  const { getCurrrentUser } = useAuth();
  const userInfo = getCurrrentUser();

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);

  const { data, error, isLoading, refetch } = useQuery(
    ['messages', conversationId],
    () => client.conversations.getMessages(conversationId),
    {
      enabled: !!conversationId,
      onSuccess: (data) => {
        setMessages(data);
      },
    }
  );

  useEffect(() => {
    if (open && conversationId) {
      refetch();
    }
  }, [open, conversationId, refetch]);

  const sendMessageMutation = useMutation(client.conversations.sendMessage, {
    onSuccess: (newMessage) => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          sender_id: userInfo.id,
          message: newMessage.message,
          created_at: new Date(),
          creator_id: creator.id,
          read_at: null,
        },
      ]);
      setMessage("");
    },
    onError: (error) => {
      console.error("Error sending message:", error);
    },
  });

  const handleSendMessage = () => {
    const payload = {
      conversation_id: conversationId,
      message,
      sender_role: 'user', // Explicitly set to 'user'
      user_id: userInfo.id, // Pass user_id only
    };

    sendMessageMutation.mutate(payload);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Avatar src={creator.pfphref} alt={creator.name} />
          <Typography variant="h6" sx={{ marginLeft: 2 }}>
            {creator.name}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading && <Typography>Loading messages...</Typography>}
        {error && <Typography>Error loading messages</Typography>}
        <Box
          display="flex"
          flexDirection="column"
          sx={{ minHeight: "300px", maxHeight: "500px", overflowY: "auto" }}
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: msg.sender_id === userInfo.id ? 'flex-end' : 'flex-start',
                mb: 2,
              }}
            >
              <Box
                sx={{
                  maxWidth: '70%',
                  p: 2,
                  borderRadius: 2,
                  backgroundColor: msg.sender_id === userInfo.id ? '#007bff' : '#e4e6eb',
                  color: msg.sender_id === userInfo.id ? 'white' : 'black',
                  textAlign: msg.sender_id === userInfo.id ? 'right' : 'left',
                }}
              >
                <Typography variant="body1">
                  {msg.message}
                </Typography>
                <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                  {new Date(msg.created_at).toLocaleTimeString()} - {msg.read_at ? `Read on ${new Date(msg.read_at).toLocaleString()}` : "Not read yet"}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <TextField
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          label="Type a message"
          fullWidth
          variant="outlined"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSendMessage();
            }
          }}
          InputProps={{
            style: {
              color: "white",
              backgroundColor: "#424242",
            },
          }}
        />
        <IconButton onClick={handleSendMessage} color="primary" sx={{ ml: 2 }}>
          <SendIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConversationsPopup;
