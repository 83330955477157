import React, { useState, useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CreatorAuthProvider } from './Hooks/creator-use-auth.js';
import { Fab, ThemeProvider, useMediaQuery } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import HelpIcon from '@mui/icons-material/Help';
import PageTitle from './Components/PageTitle.js'; // Adjust the import path as needed
import Main from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import Register from './Pages/Login/Register';
import Dashboard from './Pages/Dashboard/dashboard/dashboard.js';
import User from './Pages/Dashboard/user.js';
import Invoicing from './Pages/Dashboard/invoicing/invoicing.js';
import Campaigns from './Pages/Dashboard/Campaigns/campaigns';
import Search from './Pages/Dashboard/Search/search';
import CreatorSignup from './Pages/Home/auxLinks/creatorSignup.js';
import BlitzPay from './Pages/BlitzPay/blitzpay.js';
import AddCreators from './Pages/Dashboard/Campaigns/addCreators.js'; // Adjust the path as necessary
import routes from './Config/routes.js';
import PrivateRoute from './Lib/private-route.js';
import CreatorRoute from './Lib/creator-route.js';
import CompanyList from './Pages/Dashboard/Company/index.js';
import CampaignDetailsPage from './Pages/Dashboard/Campaigns/individualcampaigns/campaignDetailsPage.js'; // Adjust the path as needed
import CreatorDetailsPage from './Pages/Dashboard/Creators/creatorDetailsPage.js';
import AgencyDetailsPage from './Pages/Dashboard/Creators/agency/agencyDetail.js';
import CreatorConnect from './CreatorUser/CreatorPages/creatorConnect.js';
import CreatorStart from './CreatorUser/CreatorPages/Dashboard/start.js';
import CreatorLogin from './CreatorUser/creatorLogin.js';
import CreatorStripeId from './CreatorUser/CreatorPages/Dashboard/stripe.js';
import Commissions from './Pages/Misc/commisisons/commisions.js';
import CreatorInvoicePage from './CreatorUser/CreatorPages/Dashboard/misc/creatorinvoicepage.js';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
import InvoicePage from './Pages/Dashboard/invoicing/invoicepage.js';
import CreatorOnboard from './CreatorUser/creatorOnboard.js';
import CreatorCRMViewPublic from './Pages/Misc/creatorCRM/creatorCRM.js';
import HelpDialog from './helpdialog.js'; // Adjust the path as necessary
import InternalLogin from './CreatorUser/internalLogin.js';
import RequestAccess from './Pages/Home/auxLinks/requestAccess.js';
import SimilarCreators from './Pages/Dashboard/Creators/similarcreators.js';
import DemoRegister from './Pages/Home/auxLinks/demoRegister.js';
import BlitzSummary from './Pages/Home/blitzsummary.js';
import CreatorCampaignTimelines from './Pages/Dashboard/Campaigns/individualcampaigns/creatorcampaigntimelines.js';
import CreatorDeliverables from './Pages/Dashboard/Campaigns/projectcomponents/deliverablepage.js';
import BlitzRoute from './Components/BlitzRoute.js';
import TempCreatorPage from './Pages/Dashboard/Creators/tempcreatorpage.js';
import HelpPage from './Pages/Misc/helppage.js';
import AssetsManager from './Pages/Misc/assetsDemo.js';
import EditCampaignPage from './Pages/Dashboard/Campaigns/campaignsDialog/editCampaignPage.js';
import { getTheme, lightTheme } from './Utils/baseTheme.js'
import PayPage from './Pages/BlitzPay/paypage.js';
import PartnershipsPage from './Pages/Dashboard/Campaigns/projectcomponents/partnershipspage.js';
import CreatorConnectReatuh from './CreatorUser/CreatorPages/creatorConnectReauth.js';
import Solutions from './Pages/Home/brandbenefits/solutions.js';
import ForCreators from './Pages/Home/forcreators/forcreators.js';

import CreatorDashboard from './CreatorUser/CreatorPages/Dashboard/creatorDashboard.js'
import CreatorCampaigns from './CreatorUser/CreatorPages/Dashboard/creatorCampaigns.js';
import PayoutsInvoicing from './CreatorUser/CreatorPages/Dashboard/payoutsinvoicing.js';
import Pitch from './CreatorUser/CreatorPages/Dashboard/pitch.js';
import CreatorPartnerships from './CreatorUser/CreatorPages/Dashboard/partnershipcomps/creatorpartnerships.js';
import EditCreator from './CreatorUser/CreatorPages/Dashboard/userInfo.js';
import BrandEmails from './CreatorUser/CreatorPages/Dashboard/brandEmails.js';
import CreatorMore from './CreatorUser/CreatorPages/Dashboard/misc/creatorMore.js';
import CreatorConnectRoute from './Components/CreatorConnectRoute.js';

import AgencyLogin from './Pages/Agency/agencylogin.js';
import AgencyStart from './Pages/Agency/agencyStart.js';
import AgencyCampaigns from './Pages/Agency/agencyProject.js';
import CreatorSpecificCampaign from './CreatorUser/CreatorPages/Dashboard/creatorcomponents/creatorspecificcampaign.js';
import WhyPartner from './Pages/Home/forcreators/whypartner.js';
import AgencyMarket from './Pages/Agency/agencyMarket.js';
import Trackers from './Pages/Trackers/trackers.js';
import CreatorSpecificPartnership from './CreatorUser/CreatorPages/Dashboard/partnershipcomps/creatorspecificpartnerships.js';
import AgencyPartnerships from './Pages/Agency/agencypartnerships.js';
import AgencySpecificPartnership from './Pages/Agency/pages/specagencypartnershippage.js';
import AgencySpecificCampaign from './Pages/Agency/components/agencyspecificcampaign.js';
import PitchFromZap from './CreatorUser/CreatorPages/Dashboard/zapfeed/pitchtozapfeed.js';
const Home = React.lazy(() => import('./Pages/Home/Home'));


function App() {
  //const [isDark, setIsDark] = useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [helpDialogOpen, setHelpDialogOpen] = useState(false); // State for HelpDialog
  const queryClient = new QueryClient();
  const theme = useMemo(() => getTheme(!prefersDarkMode), [prefersDarkMode])

  const toggleHelpDialog = () => {
    setHelpDialogOpen(!helpDialogOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {false && <Fab
        variant="contained"
        size='large'
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(2),
          left: theme.spacing(2),
          backgroundColor: theme.palette.mode == 'dark' ? "#222" : "#eee",
          color: theme.palette.mode == 'dark' ? "#eee" : "#222",
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClick={() => { setIsDark(!isDark); }}
      >
        {!isDark && <LightModeIcon></LightModeIcon>}
        {isDark && <DarkModeIcon></DarkModeIcon>}
      </Fab>}
      {false && <Fab
        variant="contained"
        size='large'
        sx={(theme) => ({
          position: 'fixed',
          bottom: theme.spacing(2),
          left: theme.spacing(2), // Adjust to place it next to the light/dark mode button
          zIndex: theme.zIndex.drawer + 1,
        })}
        onClick={toggleHelpDialog}
      >
        <HelpIcon />
      </Fab>}
      <CreatorAuthProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <HelpDialog open={helpDialogOpen} onClose={toggleHelpDialog} />
            <Routes>
              {/*---Creator Connect routes---*/}
              <Route element={<CreatorConnectRoute onHelp={toggleHelpDialog} ></CreatorConnectRoute>}>
                <Route path={routes.creatorConnectStart} element={<GoogleOAuthProvider clientId={googleClientId}><CreatorRoute><PageTitle title="Creator Start - Blitz" /><CreatorStart /></CreatorRoute></GoogleOAuthProvider>} />
                <Route path={routes.creatorConnectCampaigns} element={<CreatorRoute><PageTitle title="Creator Campaigns - Blitz" /><CreatorCampaigns /></CreatorRoute>} />
                <Route path={routes.creatorConnectPayoutsInvoicing} element={<CreatorRoute><PageTitle title="Payouts & Invoicing - Blitz" /><PayoutsInvoicing /></CreatorRoute>} />
                <Route path={routes.creatorConnectPitch} element={<CreatorRoute><PageTitle title="Outbound - Blitz" /><Pitch /></CreatorRoute>} />
                <Route path={routes.creatorConnectPartnerships} element={<CreatorRoute><PageTitle title="Partnerships - Blitz" /><CreatorPartnerships /></CreatorRoute>} />
                <Route path={routes.creatorConnectEdit} element={<GoogleOAuthProvider clientId={googleClientId}><CreatorRoute><PageTitle title="Edit Creator Account - Blitz" /><EditCreator /></CreatorRoute></GoogleOAuthProvider>} />
                <Route path={routes.creatorConnectDealsEmails} element={<CreatorRoute><PageTitle title="Inbound - Blitz" /><BrandEmails /></CreatorRoute>} />
                <Route path={routes.creatorConnectMore} element={<CreatorRoute><PageTitle title="More - Blitz" /><CreatorMore /></CreatorRoute>} />
                <Route path={routes.creatorCampaigns} element={<CreatorRoute><PageTitle title="Manage Campaign - Blitz" /><CreatorSpecificCampaign /></CreatorRoute>} />
                <Route path={routes.creaotrParntershipsSpecific} element={<CreatorRoute><PageTitle title="Manage Partnership - Blitz" /><CreatorSpecificPartnership /></CreatorRoute>} />
                <Route path={routes.pitchFromZap} element={<CreatorRoute><PageTitle title="Pitch to a Project - Blitz" /><PitchFromZap /></CreatorRoute>} />

              </Route>
              {/*---Private routes with header ---*/}
              <Route element={<BlitzRoute onHelp={toggleHelpDialog} ></BlitzRoute>}>
                <Route path={routes.dashboard} element={<PrivateRoute><PageTitle title="Dashboard - Blitz" /><Dashboard /></PrivateRoute>} />
                <Route path={routes.user} element={<PrivateRoute><PageTitle title="User Management - Blitz" /><User /></PrivateRoute>} />
                <Route path={routes.invoicing} element={<PrivateRoute><PageTitle title="Invoicing - Blitz" /><Invoicing /></PrivateRoute>} />
                <Route path={routes.campaigns} element={<PrivateRoute><PageTitle title="Campaigns - Blitz" /><Campaigns /></PrivateRoute>} />
                <Route path={routes.search} element={<PrivateRoute><PageTitle title="Search - Blitz" /><Search /></PrivateRoute>} />
                <Route path={routes.blitzpay} element={<PrivateRoute><PageTitle title="Blitz - Payouts" /><BlitzPay /></PrivateRoute>} />
                <Route path={routes.addCreators} element={<PrivateRoute><PageTitle title="Add Creators - Blitz" /><AddCreators /></PrivateRoute>} />
                <Route path={routes.company} element={<PrivateRoute><PageTitle title="Company List - Blitz" /><CompanyList /></PrivateRoute>} />
                <Route path={routes.commisions} element={<PrivateRoute><PageTitle title="Your Commisisons - Blitz" /> <Commissions /></PrivateRoute>} />
                <Route path={routes.creatorCRMPublic} element={<> <PageTitle title="Creator CRM - Blitz" /> <CreatorCRMViewPublic /> </>} />
                <Route path={routes.assetsManager} element={<PrivateRoute><PageTitle title="Assets Manager - Blitz" /> <AssetsManager /></PrivateRoute>} />
                <Route path={routes.editCampaign} element={<PrivateRoute><PageTitle title="Edit Campaign - Blitz" /><EditCampaignPage /></PrivateRoute>} />
                <Route path={routes.paypage} element={<PrivateRoute><PageTitle title="BlitzPay" /><PayPage /></PrivateRoute>} />
                <Route path={routes.partnershipsPage} element={<PrivateRoute><PageTitle title="Partnerships - Blitz" /><PartnershipsPage /></PrivateRoute>} />
                <Route path={routes.trackers} element={<PrivateRoute><PageTitle title="Trackers - Blitz" /><Trackers /></PrivateRoute>} />

              </Route>
              {/*--- Public pages ---*/}
              <Route path={routes.base} element={<Navigate to={routes.home} />} />
              <Route path={routes.home} element={<><PageTitle title="Home - Blitz" /><Main><Home /></Main></>} />
              <Route path={routes.tempCreator} element={<><PageTitle title="Your Temp page - Blitz" /><TempCreatorPage /></>} />
              <Route path={routes.campaignReport} element={<><PageTitle title="Campaign Details - Blitz" /><CampaignDetailsPage /></>} />
              <Route path={routes.creatorMediaKit} element={<><PageTitle title="Creator Media Kit - Blitz" /><CreatorDetailsPage /></>} />
              <Route path={`${routes.creatorMediaKit}/promotional/:discount`} element={<CreatorDetailsPage />} />
              <Route path={routes.roster} element={<AgencyDetailsPage />} />
              <Route path={`${routes.creatorSignup}/:manager`} element={<><PageTitle title="Creator Signup - Blitz" /><CreatorSignup /></>} />
              <Route path={`${routes.creatorMediaKit}/similar`} element={<><PageTitle title="Similar Creators - Blitz" /><SimilarCreators /></>} />
              <Route path={`${routes.demoRegister}/:ref`} element={<> <PageTitle title="Register Demo Account - Blitz" /> <DemoRegister /> </>} />
              <Route path={routes.about} element={<> <PageTitle title="About - Blitz" /> <BlitzSummary /> </>} />
              <Route path={routes.creatorCampaignTimeline} element={<><PageTitle title="Creator Campaign Timelines - Blitz" /><CreatorCampaignTimelines /></>} />
              <Route path={routes.deliverablePage} element={<><PageTitle title="Deliverables - Blitz" /><CreatorDeliverables /></>} />
              <Route path={`${routes.invoicing}/:invoiceId`} element={<><PageTitle title="Invoice - Blitz" /><InvoicePage /></>} />
              <Route path={`${routes.creatorPayoutInvoices}/:id`} element={<><PageTitle title="Creator Invoice - Blitz" /><CreatorInvoicePage /></>} />
              <Route path={routes.help} element={<><PageTitle title="Help- Blitz" /><HelpPage /></>} />
              <Route path={routes.solutons} element={<><PageTitle title="Solutions - Blitz" /><Solutions /></>} />
              <Route path={routes.forcreators} element={<><PageTitle title="For Creators - Blitz" /><ForCreators /></>} />
              <Route path={routes.agencyLogin} element={<><PageTitle title="Agency Login - Blitz" /><AgencyLogin /></>} />
              <Route path={routes.agencyLoginA} element={<><PageTitle title="Agency Login - Blitz" /><AgencyLogin /></>} />

              <Route path={routes.agencyStart} element={<><PageTitle title="Agency Start - Blitz" /><AgencyStart /></>} />
              <Route path={routes.agencyProjects} element={<><PageTitle title="Agency Projects - Blitz" /><AgencyCampaigns /></>} />
              <Route path={routes.whypartner} element={<><PageTitle title="Why Partner? - Blitz" /><WhyPartner /></>} />
              <Route path={routes.agencymarket} element={<><PageTitle title="Agency Marketplace - Blitz" /><AgencyMarket /></>} />
              <Route path={routes.agencyPartnerships} element={<><PageTitle title="Agency Partnerships - Blitz" /><AgencyPartnerships /></>} />
              <Route path={routes.agencyParntershipsSpecific} element={<><PageTitle title="Manage Partnership - Blitz" /><AgencySpecificPartnership /></>} />
              <Route path={routes.agencyCampaignsSpecific} element={<><PageTitle title="Manage Campaign - Blitz" /><AgencySpecificCampaign /></>} />

              {/*---Sign ups and Registers---*/}
              <Route path={routes.login} element={<><PageTitle title="Login - Blitz" /><Login /></>} />
              <Route path={routes.register} element={<><PageTitle title="Register - Blitz" /><Register /></>} />
              <Route path={routes.requestAccess} element={<><PageTitle title="Request Access - Blitz" /><RequestAccess /></>} />
              <Route path={`${routes.requestAccess}/:ref`} element={<><PageTitle title="Request Access - Blitz" /><RequestAccess /></>} />
              <Route path={routes.creatorSignup} element={<><PageTitle title="Creator Signup - Blitz" /><CreatorSignup /></>} />
              <Route path={routes.creatorConnectStripe} element={<CreatorRoute><PageTitle title="Creator Start - Blitz" /><CreatorStripeId /></CreatorRoute>} />
              <Route path={routes.creatorConnectOnboard} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Creator Connect - Blitz" /><CreatorOnboard></CreatorOnboard></GoogleOAuthProvider>} />
              <Route path={routes.creatorConnectReauth} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Creator Connect - Blitz" /><CreatorConnectReatuh /></GoogleOAuthProvider>} />
              <Route path={routes.creatorConnect} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Creator Connect - Blitz" /><CreatorConnect /></GoogleOAuthProvider>} />
              <Route path={routes.creatorLogin} element={<GoogleOAuthProvider clientId={googleClientId}><PageTitle title="Creator Login - Blitz" /><CreatorLogin /></GoogleOAuthProvider>} />
              {/*---INTERNAL LOGIN FOR TESTING IN LOCAL, COMMENT OUT WHEN NOT IN USE---*/}
              {<Route path={routes.internalLogin} element={<><PageTitle title="Creator Login - Blitz" /><InternalLogin /></>} />}
              <Route path="*" element={<><PageTitle title="404 Not Found - Blitz" /><Navigate to={routes.home} /></>} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </CreatorAuthProvider>
    </ThemeProvider>
  );
}

export default App;
