import React, { useState } from "react";
import {
  Container,
  Button,
  TextField,
  Toolbar,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom"; // Also import useLocation
import routes from "../../Config/routes.js";
import ConsentForm from "./popups/consentform.js";
import { useGoogleLogin } from '@react-oauth/google';
import BlitzHeader from "../../Components/BlitzHeader.js";
import { useMutation, useQuery } from "react-query";
import API from "../../API/index.js";

const CreatorConnectReatuh = () => {
  const navigate = useNavigate();
  const { creator } = useParams();
  const [consentOpen, setConsentOpen] = useState(false);
  const { mutate, isLoading } = useMutation({ mutationFn: API.creatorConnect.reauth, mutationKey: ['creatorConnect', 'reauth'] });
  const [waitGoogle, setWaitGoogle] = useState(false);
  const {data, isLoading: reauthDataLoading } = useQuery({
    queryKey:['reauth', creator], 
    queryFn: ()=>API.creatorConnect.getReauth(creator), 
    onError:(error)=>{
      alert(`There was an error fetching this reauth request: ${error?.response?.data?.error ? error.response.data.error : error.message}`);
      navigate(routes.home);
    },
    onSuccess:(data)=>console.log(data),
    refetchInterval:false,
    refetchOnWindowFocus:false
    })
    

  const scope = [
    'https://mail.google.com/',
    'profile',
    'email',
  ];

  const scopeString = scope.reduce((accum, currSco, currIdx) => (accum + (currIdx == 0 ? '' : ' ') + currSco), '');

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      setWaitGoogle(false);
      createCreator(tokenResponse.code);

    },
    onError: errorResponse => { 
      alert(`Error getting Google authorization: ${errorResponse}`);
      setWaitGoogle(false);
    },
    onNonOAuthError: (error) => {
      setWaitGoogle(false);
      alert(`Error getting Google authorization: ${error}`);
    },
    hint:data?.email ? data.email : undefined,
    scope: scopeString,
    flow: "auth-code"
  });

  const handleConsentClose = (consented) => {
    setConsentOpen(false);
    if (consented) {
      setWaitGoogle(true);
      googleLogin();
    }
  };

  const createCreator = (google_code = null) => {
    if (!google_code) {
      alert("Unknown error");
      return;
    }
    mutate({ google_code, username: creator }, {
      onSuccess: () => {
        alert("You have reauthorized your Google account succesfully!");
        navigate(routes.creatorLogin);
      },
      onError: (error) => {
        alert(`There was an error reauthorizating your Google account: ${error?.response?.data?.error ? error?.response?.data?.error : error.message}`);
      }
    })
  }

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading || waitGoogle || reauthDataLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <BlitzHeader>Authorize</BlitzHeader>
      <Toolbar></Toolbar>
      <Container maxWidth="md" sx={{ display: 'flex', gap: 2, flexDirection: 'column', alignItems: 'center', paddingBlockStart:2 }}>
        <Typography variant="h4">Reauthorization for Partners</Typography>
        <TextField label="Creator Name" fullWidth value={data?.username || '...'} disabled />
        <Button variant="contained" color="primary" onClick={() => setConsentOpen(true)}>
          Reauthorize my Google Account
        </Button>
      </Container>

      <ConsentForm open={consentOpen} onClose={handleConsentClose} />
    </>
  );
};

export default CreatorConnectReatuh;
