import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Snackbar,
  CircularProgress,
  Tabs,
  Tab,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../API";
import { StyledTableRow } from "../../Utils/styledcell";
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import VendorDialog from "./vendorintake";

const ActionCardGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const StyledCard = styled(Card)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
`;

const BlitzPay = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [payouts, setPayouts] = useState([]);
  const [filteredPayouts, setFilteredPayouts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [vendorDialogOpen, setVendorDialogOpen] = useState(false);
  const navigate = useNavigate();

  const { mutate: fetchPayouts } = useMutation(client.payouts.list, {
    onSuccess: (data) => {
      if (Array.isArray(data.payouts)) {
        setPayouts(data.payouts);
      } else {
        console.error("Expected an array for payouts, received:", data);
        setPayouts([]);
      }
    },
    onError: (error) => {
      console.error("Error fetching payouts:", error);
      let errorMessage = "An error occurred, please try again.";
      if (error.code === "ERR_NETWORK") {
        errorMessage = "Network is disconnected!";
      } else if (error.response && error.response.data) {
        errorMessage = error.response.data.message || errorMessage;
      }
      setSnackbarMessage(errorMessage);
      setSnackbarOpen(true);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    fetchPayouts();
  }, []);

  useEffect(() => {
    if (Array.isArray(payouts)) {
      setFilteredPayouts(
        payouts.filter((payout) => {
          if (activeTab === "all") return true;
          return payout.status.toLowerCase() === activeTab;
        })
      );
    }
  }, [payouts, activeTab]);

  const handleTabClick = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleOpenVendorDialog = () => {
    setVendorDialogOpen(true);
  };

  const handleCloseVendorDialog = () => {
    setVendorDialogOpen(false);
  };

  const creatorsPaid = payouts.length;
  const amountInEscrow = Array.isArray(payouts)
    ? payouts.reduce((acc, curr) => {
        if (["pending", "queued"].includes(curr.status.toLowerCase())) {
          const sanitizedAmount = curr.amount;
          return acc + sanitizedAmount;
        } else {
          return acc;
        }
      }, 0)
    : 0;

  const blitzPaysUsed = Array.isArray(payouts)
    ? payouts.filter((payout) => payout.blitzpay).length
    : 0;

  const paymentsThisMonth = Array.isArray(payouts)
    ? payouts.reduce((acc, curr) => {
        const payoutDate = new Date(curr.payout_date);
        const currentMonth = new Date().getMonth();
        const currentYear = new Date().getFullYear();
        if (payoutDate.getMonth() === currentMonth && payoutDate.getFullYear() === currentYear) {
          const sanitizedAmount = curr.amount;
          return acc + sanitizedAmount;
        } else {
          return acc;
        }
      }, 0)
    : 0;

  const averagePaymentAmount = Array.isArray(payouts) && payouts.length > 0
    ? payouts.reduce((acc, curr) => acc + curr.amount, 0) / payouts.length
    : 0;

  return (
    <>
      <Paper elevation={2} square sx={{ width: '100%', padding: 2, mb: 2 }}>
        <Typography variant="h4" gutterBottom component="div" sx={{ textAlign: "center" }}>
          Blitz Pay
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2, mb: 4 }}>
          <Button variant="contained" color="primary" onClick={() => navigate("/pay")}>
            Pay a Creator
          </Button>
          <Button variant="contained" color="primary" onClick={handleOpenVendorDialog}>
            Add a Vendor
          </Button>
        </Box>
        <Tabs value={activeTab} onChange={handleTabClick} centered>
          <Tab label="All" value="all" />
          <Tab label="Pending" value="pending" />
          <Tab label="Approved" value="approved" />
          <Tab label="Paid" value="paid" />
        </Tabs>
      </Paper>
      <ActionCardGrid>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Summary</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Creators Paid: {creatorsPaid}</Typography>
            <Typography variant="body1">Amount in Escrow: ${amountInEscrow}</Typography>
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Payments This Month</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Total: ${paymentsThisMonth.toFixed(2)}</Typography>
          </CardContent>
        </StyledCard>
        <StyledCard>
          <CardContent>
            <Typography variant="h6">Average Payment Amount</Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1">Average: ${averagePaymentAmount.toFixed(2)}</Typography>
          </CardContent>
        </StyledCard>
      </ActionCardGrid>
      <TableContainer sx={{ width: '80%', margin: 'auto', overflowX: 'auto' }} component={Paper}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table aria-label="payouts table">
            <TableHead>
              <TableRow>
                <TableCell>Creator Name</TableCell>
                <TableCell>Campaign Name</TableCell>
                <TableCell>Payout Date</TableCell>
                <TableCell>Payment Status</TableCell>
                <TableCell>BlitzPay</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPayouts.map((payout) => (
                <StyledTableRow key={payout.payout_id || "N/A"}>
                  <TableCell>{payout.creator_id}</TableCell>
                  <TableCell>{payout.campaignname || "Creator Payout"}</TableCell>
                  <TableCell>{payout.payout_date}</TableCell>
                  <TableCell>{payout.status}</TableCell>
                  <TableCell>{payout.blitzpay ? "true" : "false"}</TableCell>
                  <TableCell>${payout.amount}</TableCell>
                  <TableCell>{payout.notes}</TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        action={<Button color="inherit" size="small" onClick={handleCloseSnackbar}>Close</Button>}
      />
      <VendorDialog open={vendorDialogOpen} onClose={handleCloseVendorDialog} />
    </>
  );
};

export default BlitzPay;
