import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Button
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import useAgencyAuth from '../../../Hooks/agency-use-auth';
import Axios from 'axios';
import { ConfigValue } from '../../../Config';
import AgencyCreatorConversation from '../components/agencypconvo';
import AgencyNavbar from '../../../Components/Navbar/agencyNavBar';

const AgencySpecificPartnership = () => {
  const { partnershipId } = useParams();
  const { getToken, getCompanyInfo } = useAgencyAuth();
  const navigate = useNavigate();

  const [partnership, setPartnership] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, [partnershipId]);  // Removed getToken and getCompanyInfo from dependencies

  const fetchData = async () => {
    const token = getToken();
    const agencyName = getCompanyInfo()?.name;

    if (!token || !agencyName) {
      setError('Agency name, creator name, or token missing');
      setIsLoading(false);
      return;
    }

    try {
      const url = `${ConfigValue.PUBLIC_REST_API_ENDPOINT}/agency/agencyPartnerships/${partnershipId}?agencyName=${encodeURIComponent(agencyName)}`;
      const response = await Axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setPartnership(response.data);
    } catch (err) {
      setError(err.message || 'Failed to fetch partnership');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = () => {
    setError(null);
    setIsLoading(true);
    fetchData();
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'primary.main' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography sx={{ color: 'error.main' }}>Error: {error}</Typography>
        <Button onClick={handleRefresh}>Refresh</Button>
      </Box>
    );
  }

  if (!partnership) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">No partnership found.</Typography>
        <Button onClick={handleRefresh}>Refresh</Button>
      </Box>
    );
  }

  return (
<Box display="flex" flexDirection="row">
      {/* Navbar */}
      <AgencyNavbar />

      {/* Main Content */}
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={() => navigate('/agency/partnerships')}>
          Go Back
        </Button>
      </Box>

      {/* Partnership Details */}
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          {partnership.name}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Creator: </strong> {partnership.creator}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Status:</strong> {partnership.status}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Client Email:</strong> {partnership.email}
        </Typography>
        <Typography variant="body2" sx={{ marginLeft: 2 }}>
          {partnership.description}
        </Typography>
      </Paper>

      {/* Conversation Component */}
      <Box sx={{ p: 2, backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', mb: 4 }}>
        <AgencyCreatorConversation partnershipId={partnershipId} creator={partnership.creator} />
      </Box>
    </Box>
    </Box>

  );
};

export default AgencySpecificPartnership;
