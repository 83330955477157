// Import API and react-query
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Tabs,
  Tab,
  Button
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useCreatorAuth } from '../../../../Hooks/creator-use-auth';
import API from '../../../../API';
import CreatorConversation from './pconvo'; // Adjust the path as needed

// Reusable TabPanel Component
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const CreatorSpecificPartnership = () => {
  const { partnershipId } = useParams(); // Fetch the partnershipId from the URL
  const { creatorToken } = useCreatorAuth(); // Fetch creator token for authentication

  // Handler for switching between tabs
  

  // Query for fetching partnership details using the new getPartnershipSingle API mutation
  const { isLoading, error, data: partnership, refetch } = useQuery(
    ['creator', 'partnership', partnershipId],
    () => {
      if (creatorToken.creator_user.username) {
        return API.creatorConnect.getPartnershipSingle(partnershipId, creatorToken.creator_user.username);
      }
      return null;
    },
    { enabled: !!partnershipId }
  );
  
  useEffect(() => {
    if (partnershipId) {
      refetch();
    }
    console.log(partnership); // Add this to check what the response contains
  }, [partnershipId, refetch]);
  
  

  // Re-fetch when the partnershipId changes
  useEffect(() => {
    if (partnershipId) {
      refetch();
    }
  }, [partnershipId, refetch]);

  // Render loading spinner if data is being fetched
  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    );
  }

  // Display error message if there's an error
  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography sx={{ color: 'error.main' }}>Error fetching partnership: {error.message}</Typography>
      </Box>
    );
  }

  // If no partnership data is found
  if (!partnership) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">No partnership found.</Typography>
      </Box>
    );
  }
  
  // Main Render
  return (
    <Box sx={{ p: 2 }}>
        <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="primary" onClick={() => window.history.back()}>
          Go Back
        </Button>
      </Box>
      {/* Partnership Details Card */}
      <Paper elevation={2} sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          {partnership.partnership.name} {/* Update to access nested object */}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Status:</strong> {partnership.partnership.status} {/* Update */}
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Client Email:</strong> {partnership.partnership.email} {/* Update */}
        </Typography>
        <Typography variant="body2" sx={{ marginLeft: 2 }}>
                  {partnership.partnership.description}
          </Typography>
        {/* Continue updating other fields as needed */}
      </Paper>
  
      {/* Tab Navigation */}
      {/* Creator Conversation with added padding and border styling */}
  <Box sx={{ p: 2, backgroundColor: '#ffffff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', mb: 4 }}>
    <CreatorConversation partnershipId={partnershipId} />
  </Box>
  
      {/* Optional Back Button */}
      
    </Box>
  );
};

export default CreatorSpecificPartnership;
