import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, useMediaQuery, styled, ThemeProvider, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CampaignIcon from '@mui/icons-material/Campaign';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import blitzLogo from './globalAssets/logo_placeholder.png';
import routes from "../Config/routes";
import { lightTheme } from "../Utils/baseTheme";
import ListItemLink from './ListItemLink';

const links = [
  { text: 'Dashboard', route: routes.creatorConnectStart, icon: <DashboardIcon /> },
  { text: 'Money', route: routes.creatorConnectPayoutsInvoicing, icon: <AttachMoneyIcon /> },
  { text: 'Campaigns', route: routes.creatorConnectCampaigns, icon: <CampaignIcon /> },
  { text: 'Partnerships', route: routes.creatorConnectPartnerships, icon: <WorkIcon /> },
  { text: 'Inbound', route: routes.creatorConnectDealsEmails, icon: <EmailIcon /> },
  { text: 'Outbound', route: routes.creatorConnectPitch, icon: <SendIcon /> },
  { text: 'Edit Account', route: routes.creatorConnectEdit, icon: <AccountCircleIcon /> },
  { text: 'More', route: routes.creatorConnectMore, icon: <MoreHorizIcon /> },
];

const CreatorHeader = ({ children }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const currentRoute = window.location.pathname; // Get the current route

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavigation = (route) => {
    navigate(route);
    setDrawerOpen(false);
  };

  const renderIcons = () => (
    <Box sx={{ flex: 1, overflowX: 'auto' }}>
      <Box display="flex" justifyContent="space-around" width='max-content'>
        {links.map((linkData) => (
          <IconButton color="inherit" onClick={() => handleNavigation(linkData.route)} sx={{ border: '1px solid white' }}>
            {linkData.icon}
          </IconButton>
        ))}
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline></CssBaseline>
      <AppBar position="fixed" id="CreatorHeader" sx={{ bgcolor: 'primary.main' }}>
        <Toolbar>
          {true && <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>}
          <IconButton
            color="inherit"
            aria-label="logo"
            sx={{ margin: 0, padding: 0 }}
            onClick={() => navigate(routes.creatorConnectStart)}
          >
            <img
              src={blitzLogo}
              alt="logo"
              style={{ height: "40px", marginLeft: "8px", marginRight: "8px" }}
            />
          </IconButton>
          {false && renderIcons()}
        </Toolbar>
      </AppBar>
      <Toolbar></Toolbar>
      {true && (
        <Drawer
          id='DrawerContainer'
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            sx={{ width: 250 }}
          >
            <List>
              {links.map((item) => (
                <ListItem disableGutters key={item.text}>
                  <ListItemLink to={item.route} primary={item.text} icon={item.icon} >
                  </ListItemLink>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      )}
      <Box
        id='TopPageContainer'
        sx={{
          minWidth: '100%',
        }}>
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default CreatorHeader;
