import React, { useState, useEffect } from "react";
import logo from "../globalAssets/logo_placeholder.png";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/use-auth";
import routes from "../../Config/routes.js";
import client from "../../API";
import { useMutation } from "react-query";
import { Drawer, Box, Typography, List, ListItem, Divider, IconButton, styled, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { drawerWidth } from "../../Utils/constants.js";
import styles from "./NavBar.module.css";
import ListItemLink from "../ListItemLink.js";
import LogoutIcon from '@mui/icons-material/Logout';
import { CreditCardOutlined, DnsOutlined, HomeOutlined, InsertDriveFileOutlined, KeyboardArrowLeftSharp, KeyboardArrowRightSharp, LiveHelpOutlined, MonetizationOnOutlined, PeopleOutline, RateReviewOutlined, ReceiptLongOutlined, StorefrontOutlined } from "@mui/icons-material";
import WhiteListButton from "../WhiteListButton.js";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TrackChangesOutlined from '@mui/icons-material/TrackChangesOutlined';

const listItems = [
  {
    to: routes.dashboard,
    primary: "Dashboard",
    icon: <HomeOutlined />,
    tooltip: "View and manage your dashboard",
  },
  {
    to: routes.search,
    primary: "Marketplace",
    icon: <StorefrontOutlined />,
    tooltip: "Explore our creator selection and make projects from there",
  },
  {
    to: routes.campaigns,
    primary: "Projects",
    icon: <DnsOutlined />,
    tooltip: "Manage your ongoing projects, campaigns, and partnerships",
  },
  {
    to: routes.blitzpay,
    primary: "Blitz Pay",
    icon: <CreditCardOutlined />,
    tooltip: "Make instant payments through Blitz Pay",
  },
  {
    to: routes.invoicing,
    primary: "Invoicing",
    icon: <ReceiptLongOutlined />,
    tooltip: "Handle all invoicing tasks",
  },
  {
    to: routes.assetsManager,
    primary: "Assets Management",
    icon: <InsertDriveFileOutlined />,
    tooltip: "Manage your digital assets, files, and more",
  },
  {
    to: routes.commisions,
    primary: "Commissions",
    icon: <MonetizationOnOutlined />,
    tooltip: "View and manage commissions",
  },
];

const ListItemLinkWithTooltip = ({ to, primary, icon, tooltip, isOpen = true }) => (
  <Tooltip title={tooltip} placement="right">
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemLink to={to} primary={primary} icon={icon} isOpen={isOpen} />
    </ListItem>
  </Tooltip>
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const WhiteIconButton = styled(IconButton)(({ theme }) => ({
  color: '#000',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.04)'
  },
}));

const IconDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'extended' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ extended }) => extended,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ extended }) => !extended,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

const Navbar = ({ isDesktop = true, isOpen = true, onClose, onClickHelp, onOpen }) => {
  const { getCurrrentUser, isAuthorized, unauthorize } = useAuth();
  const userInfo = getCurrrentUser();
  const navigate = useNavigate();

  const logout = () => {
    unauthorize();
  };

  const [companyData, setCompanyData] = useState({
    balance: "Loading...",
    credit_line: "Loading...",
    phphref: "",
    account_status: "Loading..."
  });

  const { mutate: fetchCompanyData } = useMutation(client.companies.listFetch, {
    onSuccess: (data) => {
      if (data && data.balance !== undefined && data.credit_line !== undefined) {
        setCompanyData({
          balance: `$${parseFloat(data.balance).toFixed(2)}`,
          credit_line: data.credit_line
            ? `$${parseFloat(data.credit_line).toFixed(2)}`
            : "No Credit Line Established",
          phphref: data.phphref || "",
          account_status: data.account_status || ""
        });
      } else {
        console.error("Received data is not in the expected format:", data);
      }
    },
    onError: (error) => {
      console.error("Error fetching company data:", error);
      setCompanyData({
        balance: "Error loading balance",
        credit_line: "Error loading credit line",
        phphref: "",
        account_status: "Error loading account status"
      });
    },
  });

  useEffect(() => {
    fetchCompanyData();
  }, []);

  const isExtended = (!isDesktop || isOpen);

  return (
    <IconDrawer
      id='NavbarContainer'
      variant={isDesktop ? "permanent" : "temporary"}
      sx={{
        color: '#000 !important',
        '& .MuiDrawer-paper': { color: '#000 !important', boxSizing: 'border-box', backgroundColor: '#FFF !important', backgroundImage: 'none !important' },
      }}
      open={isDesktop || isOpen}
      extended={isExtended}
      onClose={onClose}
    >
      <Box className={styles.navbarHeader} sx={{ marginInline: 2, marginBlockStart: 2, display: { xs: 'none', md: 'flex' }, flexDirection: isExtended ? 'row' : 'row-reverse', alignItems: 'center', justifyContent: isExtended ? 'space-between' : 'center' }}>
        <Box className={styles.logo}>
          {isExtended && <RouterLink to={isAuthorized ? routes.dashboard : routes.home}>
            <img src={logo} alt="Logo" />
          </RouterLink>}
        </Box>
        <WhiteIconButton onClick={() => { isOpen ? onClose() : onOpen() }}>
          {isExtended ? <KeyboardArrowLeftSharp></KeyboardArrowLeftSharp> : <KeyboardArrowRightSharp></KeyboardArrowRightSharp>}
        </WhiteIconButton>
      </Box>
      {isDesktop && <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 1, borderColor: 'rgba(0, 0, 0, 0.12) !important' }} />}
      {<Box className={styles.user}>
        <Box className={styles.userTab} sx={{ marginBlockStart: { xs: 4, md: 0 } }}>
          <Box className={styles.userInfo}>
            <Box sx={{ display: 'flex', flexDirection: isExtended ? 'row' : 'row-reverse', justifyContent: isExtended ? 'space-between' : 'center', alignItems: 'center', width: '100%' }}>
              <Box sx={{ textAlign: 'left', maxWidth: isExtended ? 'auto' : 0, overflowX: 'clip' }}>
                <Typography variant="subtitle1"><b>
                  Hey, {`${userInfo?.first_name ?? ""}`}!
                </b></Typography>
                <Typography variant="subtitle1"><b>{userInfo?.company_name}</b></Typography>
              </Box>
              <Tooltip title={"Log out"} placement="right">
                <WhiteIconButton onClick={logout}>
                  <LogoutIcon />
                </WhiteIconButton>
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: isExtended ? 'row' : 'row-reverse', justifyContent: isExtended ? 'space-between' : 'center', alignItems: 'center', width: '100%' }}>
              <Box sx={{ textAlign: 'left', maxWidth: isExtended ? 'auto' : 0, overflowX: 'clip' }}>
                <Typography variant="subtitle2" color='rgba(0, 0, 0, 0.6)'>Balance: {companyData.balance}</Typography>
                <Typography variant="subtitle2" color='rgba(0, 0, 0, 0.6)'>Credit Line: {companyData.credit_line}</Typography>
              </Box>
              <Tooltip title={"Modify your account settings"} placement="right">
                <WhiteIconButton onClick={() => navigate(routes.user)}>
                  <SettingsOutlinedIcon />
                </WhiteIconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>}
      {<Divider sx={{ marginBlockStart: 2, marginBlockEnd: 0, borderColor: 'rgba(0, 0, 0, 0.12) !important' }} variant="middle" />}
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <List>
          {listItems.map((item, index) => (
            <ListItemLinkWithTooltip key={item.route} {...item} isOpen={isExtended} />
          ))}
          {companyData.account_status === "Agency" || companyData.account_status === "AlphaFree" && (
  <ListItemLinkWithTooltip
    isOpen={isExtended}
    key={routes.agencyLogin}
    to={`${routes.agencyLogin}/${userInfo?.company_name}`} // Append agency name to the route
    primary="My Agency"
    icon={<PeopleOutline />}
    tooltip="Manage your agency's roster, campaigns, and more"
  />
)}

          {companyData.account_status === "AlphaFree" && (
            <ListItemLinkWithTooltip
              isOpen={isExtended}
              key={routes.creatorCRMPublic}
              to={routes.creatorCRMPublic}
              primary="Creator CRM"
              icon={<RateReviewOutlined />}
              tooltip="Manage your creator relationships"
            />
          )}
          {companyData.account_status === "AlphaFree" && (
            <ListItemLinkWithTooltip
              isOpen={isExtended}
              key={routes.trackers}
              to={routes.trackers}
              primary="Trackers"
              icon={<TrackChangesOutlined />}
              tooltip="Track data from your list of social media links"
            />
          )}
        </List>

        <Box>
          <Divider sx={{ marginBlockStart: 2, marginBlockEnd: 0, borderColor: 'rgba(0, 0, 0, 0.12) !important' }} />
          <ListItem disablePadding component={"div"}>
            <WhiteListButton onClick={onClickHelp}
              sx={{
                justifyContent: isExtended ? 'initial' : 'center'
              }}
            >
              <ListItemIcon
                sx={{ color: 'rgba(0, 0, 0, 0.87)', minWidth: '36px', marginInlineEnd: isOpen ? 0 : 'auto', justifyContent: isExtended ? 'initial' : 'center' }}
              ><LiveHelpOutlined /></ListItemIcon>
              <ListItemText primaryTypographyProps={{ fontSize: '1.2rem' }}
                sx={{ opacity: isExtended ? 1 : 0 }}
                primary='Help' />
            </WhiteListButton>
          </ListItem>
        </Box>
      </Box>
    </IconDrawer>
  );
};

export default Navbar;
