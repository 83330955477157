import React from 'react';
import { Typography, Grid } from '@mui/material';
import { ResponsiveContainer, BarChart, LineChart, PieChart, XAxis, YAxis, Tooltip, Legend, Bar, Line, Pie, Cell } from 'recharts';

const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A83731",
    "#5A30B5",
    "#34A853",
  ];

const CampaignReportingPanel = ({ costPerCreatorData, viewsByDayData, reachData }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        Reporting
      </Typography>
      <Grid container spacing={2}>
        {/* Cost Per Creator Chart */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom>
            Cost Per Creator
          </Typography>
          <ResponsiveContainer width="100%" aspect={1}>
            <BarChart data={costPerCreatorData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="price" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>

        {/* Total Views by Day Chart */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom>
            Total Views by Day
          </Typography>
          <ResponsiveContainer width="100%" aspect={1}>
            <LineChart data={viewsByDayData}>
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="views" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Grid>

        {/* Total Reach Pie Chart */}
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle1" gutterBottom>
            Total Reach by Creator
          </Typography>
          <ResponsiveContainer width="100%" aspect={1}>
            <PieChart>
              <Pie
                data={reachData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {reachData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default CampaignReportingPanel;
