import React, { useEffect, useRef } from "react";
import { Box, Typography, Button, Container, Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BlitzHeader from "../../../Components/BlitzHeader";
import paymentImage from "../../../Components/globalAssets/blackwhite.png";
import marketplaceImage from "../../../Components/globalAssets/blitzLogo.png";
import routes from "../../../Config/routes";

const Solutions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const paymentRef = useRef(null);
  const marketplaceRef = useRef(null);
  const aiManagerRef = useRef(null);
  const acceleratedCollabRef = useRef(null);

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.substring(1);
      scrollToSection(targetId);
    }
  }, [location.hash]);

  const scrollToSection = (sectionId) => {
    switch (sectionId) {
      case "blitzPay":
        paymentRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "creatorSourcing":
        marketplaceRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "aiCampaignManagement":
        aiManagerRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case "acceleratedCollaborations":
        acceleratedCollabRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <BlitzHeader>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            py={2}
          >
            {/* Logo Section */}
            <Box display="flex" alignItems="center" flex={1} justifyContent="flex-start">
              {/* BlitzLogo can be placed here */}
            </Box>

            {/* Menu Section */}
            <Box display="flex" alignItems="center" flex={1} justifyContent="center">
              <Button
                color="inherit"
                variant="text"
                onClick={() => navigate(routes.about)}
              >
                About
              </Button>
              <Button
                color="inherit"
                variant="text"
                onClick={() => navigate(routes.whyPartner)}
              >
                Why Partner
              </Button>
            </Box>

            {/* Access Button */}
            <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
              <Button
                color="inherit"
                variant="outlined"
                onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}
              >
                Request Access
              </Button>
            </Box>
          </Box>
        </Container>
      </BlitzHeader>

      {/* Main Content */}
      <Container
        sx={{
          backgroundColor: "#f5f5f5",
          color: "#000",
          paddingTop: "20px",
          paddingBottom: "20px",
          minHeight: "100vh",
          mt: 8, // Adds spacing between the header and content
        }}
      >
        {/* Solutions Header */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "bold", my: 4 }}>
            Our Solutions
          </Typography>
        </Box>

        <Divider />

        {/* BlitzPay Section */}
        <Box ref={paymentRef} sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", py: 10 }}>
            <Box flex={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 } }}>
              <img src={paymentImage} alt="Payment" style={{ width: "100%", maxWidth: "450px" }} />
            </Box>
            <Box sx={{ flex: 1, textAlign: { xs: "center", md: "left" } }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                BlitzPay - Accounts Payable
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Streamline your payment process and ensure creators are paid on time, every time, through their preferred methods.
              </Typography>
              <Button variant="contained" sx={{ px: 4, py: 1.5, fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
                Request Access
              </Button>
            </Box>
          </Box>
        </Box>

        <Divider />

        {/* Creator Sourcing Section */}
        <Box ref={marketplaceRef} sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row-reverse" }, justifyContent: "center", alignItems: "center", py: 10 }}>
            <Box flex={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 } }}>
              <img src={marketplaceImage} alt="Marketplace" style={{ width: "100%", maxWidth: "450px" }} />
            </Box>
            <Box sx={{ flex: 1, textAlign: { xs: "center", md: "left" } }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Creator Sourcing
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Discover and collaborate with top creators around the globe, all within Blitz's expansive network.
              </Typography>
              <Button variant="contained" sx={{ px: 4, py: 1.5, fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
                Request Access
              </Button>
            </Box>
          </Box>
        </Box>

        <Divider />

        {/* AI Campaign Management Section */}
        <Box ref={aiManagerRef} sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: "center", alignItems: "center", py: 10 }}>
            <Box flex={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 } }}>
              <img src={paymentImage} alt="AI Manager" style={{ width: "100%", maxWidth: "450px" }} />
            </Box>
            <Box sx={{ flex: 1, textAlign: { xs: "center", md: "left" } }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                AI Campaign Management
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Automate and optimize your campaign management with Blitz's AI, reducing costs and increasing efficiency.
              </Typography>
              <Button variant="contained" sx={{ px: 4, py: 1.5, fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
                Request Access
              </Button>
            </Box>
          </Box>
        </Box>

        <Divider />

        {/* Accelerated Creator Collaborations Section */}
        <Box ref={acceleratedCollabRef} sx={{ mb: 4 }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row-reverse" }, justifyContent: "center", alignItems: "center", py: 10 }}>
            <Box flex={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: { xs: 4, md: 0 } }}>
              <img src={marketplaceImage} alt="Collaborations" style={{ width: "100%", maxWidth: "450px" }} />
            </Box>
            <Box sx={{ flex: 1, textAlign: { xs: "center", md: "left" } }}>
              <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
                Accelerated Creator Collaborations
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Fast-track your partnerships with creators through Blitz’s efficient collaboration tools.
              </Typography>
              <Button variant="contained" sx={{ px: 4, py: 1.5, fontSize: "1rem", textTransform: "none", backgroundColor: "#7b2a2a", color: "#fff" }} onClick={() => navigate(`${routes.demoRegister}/thealexroa`)}>
                Request Access
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Footer */}
      <Box sx={{ backgroundColor: "#f5f5f5", py: 4, textAlign: "center" }}>
        <Typography
          variant="body2"
          color="inherit"
        >
          © 2024 Pangea, Inc. All Rights Reserved.
        </Typography>
      </Box>
    </>
  );
};

export default Solutions;
