import React, { useState, useEffect } from 'react';
import { Box, Typography, Toolbar, Button, TextField } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams to get route params
import { ConfigValue } from '../../Config';
import AlertDialog from '../../Components/AlertDialog';
import useAlertDialog from '../../Components/useAlertDialog';
import BlitzHeader from '../../Components/BlitzHeader';
import useAgencyAuth from '../../Hooks/agency-use-auth';

const AgencyLogin = () => {
    const { agencyName: encodedAgencyName } = useParams(); // Get encoded agency name from params
    
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const { dialogState, openDialog, closeDialog } = useAlertDialog();
    const [agencyName, setAgencyName] = useState('');
    const { authorize } = useAgencyAuth(); // Use the agency auth hook

    useEffect(() => {
        if (encodedAgencyName) {
          const decodedName = decodeURIComponent(encodedAgencyName); // Decode the name
          setAgencyName(decodedName);
        }
      }, [encodedAgencyName]);

      const handleLoginSuccess = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${ConfigValue.PUBLIC_REST_API_ENDPOINT}/agency/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ agencyName })
            });
    
            const data = await response.json();  // Extract JSON data
    
            if (response.ok) {
                authorize(data.token, { name: agencyName });
                navigate('/agency/start');
            } else {
                openDialog("Failure", `Login failed: ${data.message}`, closeDialog, closeDialog, "Ok", null);
            }
        } catch (error) {
            console.error('Error during login:', error);
            openDialog("Failure", 'Login process encountered an error.', closeDialog, closeDialog, "Ok", null);
        } finally {
            setIsLoading(false);
        }
    };
    
    return (
        <>
            <BlitzHeader />
            <Toolbar />
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5rem' }}>
                <AlertDialog alertState={dialogState} />
                <Typography variant="h4" style={{ marginBottom: '20px', textAlign: 'center' }}>
                    Log in to Blitz to manage your agency campaigns
                </Typography>
                <Box style={{ width: '80%', maxWidth: '300px' }}>
                    <TextField
                        label="Agency Name"
                        value={agencyName}
                        onChange={(e) => setAgencyName(e.target.value)}
                        fullWidth
                        margin="normal"
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLoginSuccess}
                        disabled={isLoading}
                        fullWidth
                        style={{ marginTop: '20px' }}
                    >
                        {isLoading ? 'Logging in...' : 'Log In'}
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default AgencyLogin;
