import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { PictureAsPdf, Description, Image } from "@mui/icons-material";
import UploadFileDialog from "./uploadfile"; // Adjust the path as necessary

const renderFileIcon = (fileType) => {
  switch (fileType) {
    case "application/pdf":
      return <PictureAsPdf fontSize="large" />;
    case "text/plain":
      return <Description fontSize="large" />;
    case "image/jpeg":
    case "image/png":
      return <Image fontSize="large" />;
    default:
      return <Description fontSize="large" />;
  }
};

const SelectAssetDialog = ({ open, onClose, files, selectedAsset, onAssetClick, refetchFiles }) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false);
    refetchFiles(); // Refresh the asset list after the upload dialog is closed
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Select an Asset</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {files?.map((file) => (
            <Grid item xs={12} sm={6} md={4} key={file.id}>
              <Card
                onClick={() => onAssetClick(file)}
                style={{
                  cursor: "pointer",
                  border: selectedAsset?.id === file.id ? "2px solid blue" : "none",
                }}
              >
                <CardContent>
                  {renderFileIcon(file.type)}
                  <Typography variant="h6">{file.filename}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setUploadDialogOpen(true)} color="primary">
          Upload File
        </Button>
        <Button onClick={() => onClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onClose(true)} color="primary">
          Confirm
        </Button>
      </DialogActions>

      <UploadFileDialog open={uploadDialogOpen} onClose={handleUploadDialogClose} />
    </Dialog>
  );
};

export default SelectAssetDialog;
