import { ListItemButton, styled } from "@mui/material";

const WhiteListButton = styled(ListItemButton,{
        shouldForwardProp: (prop) => prop !== 'isLocation',
    })(({ theme, isLocation }) => ({
    color: isLocation ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.87)',
    '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.04)'
    },
}));

export default WhiteListButton;