import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Paper,
  Button,
  Divider,
  Link,
} from "@mui/material";
import axios from "axios";
import { useMutation } from "react-query";
import API from "../../../../API";

const AssetsTab = ({ campaignDetails, creator }) => {
  const [liveLink, setLiveLink] = useState(creator?.liveLink || "");
  const [saving, setSaving] = useState(false);

  const handleLiveLinkChange = (event) => {
    setLiveLink(event.target.value);
  };

  const { mutate: updateLink, isLoading: isUpdatingLink } = useMutation(
    ({ input }) => API.creatorConnect.campaignUpdateLink(input),
    {
      onSuccess: () => {
        alert("Live Link updated.");
      },
      onError: (error) => {
        const errorMsg = (error.response && error.response.data && error.response.data.error)
          ? error.response.data.error
          : error.message;
        alert(`Error when updating Live Link: ${errorMsg}`);
      },
    }
  );

  const handleSaveLiveLink = async () => {
    updateLink({
      input: {
        campaignId: campaignDetails.id,
        updates: [{ id: creator.id, liveLink }],
      },
    });
  };

  const isValidURL = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  if (!creator || !campaignDetails) return <>Loading</>;

  return (
    <Paper sx={{ margin: 1, padding: 3, paddingBlockEnd: 1 }} elevation={2}>
      <Typography variant="h6">Campaign Brief: {campaignDetails.brief}</Typography>
      <Divider></Divider>
      <Box sx={{ paddingBlockEnd: 2, marginTop: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={!!creator.promotionPlatform} disabled />}
          label={`Platform: ${creator?.promotionPlatform || "Not specified"}`}
        />
        <FormControlLabel
          control={<Checkbox checked={!!creator.promotionType} disabled />}
          label={`Promotion Type: ${creator?.promotionType || "Not specified"}`}
        />
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Creator Brief</Typography>
          {isValidURL(creator.creatorBrief) ? (
            <Link href={creator.creatorBrief} target="_blank" rel="noopener">
              Open Creator Brief
            </Link>
          ) : (
            <TextField fullWidth value={creator?.creatorBrief || ""} disabled multiline />
          )}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Posting Instructions</Typography>
          <TextField fullWidth value={creator?.postingInstructions || ""} disabled multiline />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Boost Code</Typography>
          <TextField fullWidth value={creator?.boostCode || ""} disabled />
        </Box>
        {creator.submissionLink && (
          <Box sx={{ marginTop: 2, textAlign: "center" }}>
            <Button variant="contained" color="primary" href={creator.submissionLink} target="_blank">
              Upload your assets here
            </Button>
          </Box>
        )}
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="body1">Insert live link here</Typography>
          <TextField fullWidth value={liveLink} onChange={handleLiveLinkChange} disabled={isUpdatingLink} />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveLiveLink}
            disabled={isUpdatingLink}
            sx={{ marginTop: 2 }}
          >
            {isUpdatingLink ? "Saving..." : "Save Live Link"}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default AssetsTab;
