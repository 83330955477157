import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Snackbar,
  Alert
} from "@mui/material";
import { useMutation } from "react-query";
import { styled } from "@mui/system";
import client from "../../../../../API";

const StyledDialogTitle = styled(DialogTitle)`
  background-color: #424242;
  color: white;
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  background-color: #424242;
`;

const OfferDialog = ({ open, onClose, offer }) => {
  const [daysSinceSent, setDaysSinceSent] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const calculateDaysSinceSent = () => {
      const sentDate = new Date(offer.sentDate);
      const currentDate = new Date();
      const differenceInTime = currentDate - sentDate;
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      setDaysSinceSent(Math.round(differenceInDays));
    };

    calculateDaysSinceSent();
  }, [offer.sentDate]);

  const completeOfferMutation = useMutation(
    () => client.deliverables.update(offer.id, { status: "COMPLETE" }),
    {
      onSuccess: () => {
        createPayoutAndInvoice();
      },
      onError: (error) => {
        console.error("Error completing offer:", error);
        setShowError(true);
        setErrorMessage("Error completing offer. Please try again.");
      }
    }
  );

  const createPayoutAndInvoice = () => {
    const poNumber = `PO-${Math.floor(Math.random() * 1000000)}`;

    const payoutData = {
      creator_id: offer.creator_id,
      amount: offer.amount,
      po_number: poNumber,
      notes: `Payout for completed offer ${offer.id}`,
      bypassSMSVerification: true,
      blitzPay: true
    };

    client.payouts.create(payoutData)
      .then(() => {
        setShowSuccess(true);
        onClose();
      })
      .catch(error => {
        console.error("Error creating payout and invoice:", error);
        setShowError(true);
        setErrorMessage("Error creating payout and invoice. Please try again.");
      });
  };

  const handleCompleteOffer = () => {
    completeOfferMutation.mutate();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <StyledDialogTitle>View Offer</StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="body1">Offer Details:</Typography>
        <Typography variant="body2">{offer.details}</Typography>
        <Typography variant="body1">Days Since Offer Sent: {daysSinceSent}</Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} color="secondary">Close</Button>
        <Button onClick={handleCompleteOffer} color="primary">Complete Offer</Button>
      </StyledDialogActions>
      <Snackbar
        open={showSuccess}
        autoHideDuration={6000}
        onClose={() => setShowSuccess(false)}
      >
        <Alert onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
          Offer completed and payout issued successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={() => setShowError(false)}
      >
        <Alert onClose={() => setShowError(false)} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};
export default OfferDialog;
