import React from 'react';
import { Box, Typography, Card, CardContent, Button, Grid } from '@mui/material';
import styles from "../styles.module.css";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import useAuth from '../../../../Hooks/use-auth';

const getRandomCampaigns = (campaigns, count) => {
  const shuffled = [...campaigns].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const CombinedCampaignsAndReviews = ({ campaigns, reviews, setReviewDialogOpen }) => {
  const { currentUser } = useAuth();
  const randomCampaigns = getRandomCampaigns(campaigns.filter(campaign => campaign.status === 'Completed'), 5);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<StarIcon key={i} />);
      } else {
        stars.push(<StarBorderIcon key={i} />);
      }
    }
    return stars;
  };

  return (
    <Box className={styles.combinedSection} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={styles.completedCampaigns} sx={{ width: '100%' }}>
            <Typography variant='h5'>Completed Campaigns</Typography>
            {randomCampaigns.map(campaign => (
              <Card key={campaign.id} className={styles.card} sx={{ margin: '1rem 0', padding: '1rem', transition: '0.3s', '&:hover': { boxShadow: 6 } }}>
                <CardContent>
                  <Typography variant='h6'>{campaign.name}</Typography>
                  <Typography variant='body2'>Status: {campaign.status}</Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className={styles.reviews} sx={{ width: '100%' }}>
            <Typography variant='h5'>Reviews</Typography>
            {reviews.map(review => (
              <Card key={review.id} className={styles.card} sx={{ margin: '1rem 0', padding: '1rem', transition: '0.3s', '&:hover': { boxShadow: 6 } }}>
                <CardContent>
                  <Typography variant='h6'>Rating: {renderStars(review.rating)}</Typography>
                  <Typography variant='h6'>{review.user.first_name} {review.user.last_name}</Typography>
                  <Typography variant='body2'>{review.user.company_name}</Typography>
                  <Typography variant='body2'>{review.description}</Typography>
                </CardContent>
              </Card>
            ))}
              <Button variant="contained" color="secondary" onClick={() => setReviewDialogOpen(true)} sx={{ marginTop: '10px' }}>
                Leave a Review
              </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CombinedCampaignsAndReviews;
