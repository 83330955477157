import React, { useState, useEffect, useMemo, memo } from "react";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  CircularProgress, // Import CircularProgress
  Pagination,
  TextField,
  FormControlLabel,
  Switch,
  IconButton,
  Link,
} from "@mui/material";
import { useMutation } from "react-query";
import client from "../../../API";
import CreatorDialog from "./creatorintake";
import CreatorContainers from "./creatorcontainersearch";
import PromoPlatFilter from "./Filters/PromoPlatFilter";
import NichesFilter from "./Filters/NichesFilters";
import CreatorFilter from "./Filters/CreatorFilters";
import DemoFilters from "./Filters/DemoFilters";
import MinMaxFilter from "./Filters/MinMaxFilter";
import { indexToPromoFilter } from "../../../Utils/constants";
import { FilterAltOffRounded } from "@mui/icons-material";
import { StyledTableRow } from "../../../Utils/styledcell";
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';

const StyleContent = (theme) => ({
  paddingTop: theme.spacing(3),
  overflowX: "auto",
  paddingLeft: theme.spacing(2),
  marginBlockEnd: theme.spacing(2),
  width: '100%',
  minHeight: '100%'
});

const StyleCsvSection = (theme) => ({
  overflowX: "auto",
  marginInline: theme.spacing(1),
});

const classes = {
  csvTable: {
    width: "100%",
    tableLayout: "auto",
  },
  linkCell: {
    maxWidth: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }
};

const LeftColumn = memo(function LeftColumn({ onCreatorSelect, selectedItems, itemsPerPage = 16 }) {
  const [data, setData] = useState([]);
  const [isCreatorDialogOpen, setIsCreatorDialogOpen] = useState(false);
  const [showPretty, setShowPretty] = useState(true);
  const [loading, setLoading] = useState(true); // Add loading state
  const [page, setPage] = useState(0);

  //New filters
  const [promoIndex, setPromoIndex] = useState(1);

  const [nicheSet, setNicheSet] = useState(new Set([]));

  const [creatorGender, setCreatorGender] = useState(new Set([]));
  const [creatorRace, setCreatorRace] = useState(new Set([]));
  const [creatorRegion, setCreatorRegion] = useState(new Set([]));

  const [publicGender, setPublicGender] = useState(new Set([]));
  const [publicAge, setPublicAge] = useState(new Set([]));
  const [publicCountry, setPublicCountry] = useState(new Set([]));

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const [minFollow, setMinFollow] = useState(null);
  const [maxFollow, setMaxFollow] = useState(null);

  const [minViews, setMinViews] = useState(null);
  const [maxViews, setMaxViews] = useState(null);

  const [filter, setFilter] = useState('')

  const [search, setSearch] = useState('')

  const handleMinMaxApply = (value, minSetter, maxSetter) => {
    minSetter(value.min);
    maxSetter(value.max);
  }

  const handleCreatorDemoApply = (value) => {
    setCreatorGender(value.gender);
    setCreatorRace(value.race);
    setCreatorRegion(value.region);
  }

  const handlePublicDemoApply = (value) => {
    setPublicGender(value.gender);
    setPublicAge(value.age);
    setPublicCountry(value.country);
  }

  const handleResetFilters = () => {
    setPromoIndex(1);

    setNicheSet(new Set([]));

    setCreatorGender(new Set([]));
    setCreatorRace(new Set([]));
    setCreatorRegion(new Set([]));

    setPublicGender(new Set([]));
    setPublicAge(new Set([]));
    setPublicCountry(new Set([]));

    setMinPrice(null);
    setMaxPrice(null);

    setMinFollow(null);
    setMaxFollow(null);

    setMinViews(null);
    setMaxViews(null);
    setSearch('');
    setFilter('');
  };

  //Debounces search bar to make it more performant
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setFilter(search);
    }, 500);
    return () => clearTimeout(delayInputTimeoutId); //Cancels the debounce when more terms come in
  }, [search]);

  useEffect(() => {
    setPage(0); //Reset page count when changing search
  }, [promoIndex, filter, nicheSet, minViews, maxViews, minPrice, maxPrice, minFollow, maxFollow, publicCountry, publicAge, publicGender, creatorRegion, creatorRace, creatorGender])

  useEffect(() => {
    console.log(`Pages: ${filteredData.length / itemsPerPage}, items ${filteredData.length}, this page offset:${page * itemsPerPage} to ${Math.min((page * itemsPerPage) + itemsPerPage)}`);
  }, [page, data])

  const { platform, promo: promotionType } = indexToPromoFilter(promoIndex);

  const { mutate: fetchData } = useMutation(client.creators.list, {
    onSuccess: async (data) => {
      let creators = [...data];

      let sortedAndUpdatedData = sortCreators([...creators]);

      setData(sortedAndUpdatedData);
      setLoading(false); // Set loading to false when data is fetched
    },
    onError: (error) => {
      console.error("Failed to fetch data:", error);
      setLoading(false); // Set loading to false on error
    },
  });

  useEffect(() => {
    fetchData({ include_reviews: true });
  }, []);

  const handleCreatorSubmit = (formData) => {
    console.log(formData);
    setIsCreatorDialogOpen(false);
  };

  const sortCreators = (creators) => {
    return creators.sort((a, b) => {
      if (a.status === "Partner" && b.status !== "Partner") return -1;
      if (b.status === "Partner" && a.status !== "Partner") return 1;

      const aFollowers = parseNumber(a.followers);
      const bFollowers = parseNumber(b.followers);
      if (aFollowers !== bFollowers) {
        return bFollowers - aFollowers;
      }

      const aViews = parseNumber(a.avg_views);
      const bViews = parseNumber(b.avg_views);
      if (aViews !== bViews) {
        return bViews - aViews;
      }

      const aRecency = new Date(a.last_updated || a.date_added);
      const bRecency = new Date(b.last_updated || b.date_added);
      return bRecency - aRecency;
    });
  };

  const parseNumber = (value) => {
    if (!value || value === "N/A") return 0;
    return parseInt(value.replace(/,/g, ""), 10);
  };

  const platformToKey = {
    TikTok: ["tiktok", "rate_tt_sound", "rate_tt_brand"],
    Instagram: ["instagram", "rate_ig_reelsound", "rate_ig_reelbrand"],
    Youtube: ["youtube", "rate_yt_integ30s", "rate_yt_integ60s", "rate_yt_short"],
  };

  const platPromoToKey = {
    // Existing platforms
    TikTok: { "Sound": "rate_tt_sound", "Brand": "rate_tt_brand" },
    Instagram: { "Feed Post": "rate_ig_feedpost", "Sound": "rate_ig_reelsound", "Brand": "rate_ig_reelbrand" },
    Youtube: { "3045s Integration": "rate_yt_integ30s", "60s Integration": "rate_yt_integ60s", "Shorts": "rate_yt_short" },
    
    // New platforms and promotions
    Podcasts: { "15s": "rate_podcast_15s", "60s": "rate_podcast_60s" },  // Shows category
    Twitch: { "Live 30s": "rate_twitch_live_30s" },  // Streaming category
    Kick: { "Live 30s": "rate_kick_live_30s" },  // Streaming category
    X: { "Feed Post": "rate_x_feedpost", "Repost": "rate_x_repost" },  // Others category
  };
  


  const handleSelectItem = (creatorId) => {
    const selectedCreator = data.find(
      (creator) => creator.creator === creatorId,
    );
    if (!selectedCreator) {
      console.warn(`Item with ID ${creatorId} not found.`);
      return;
    }
  
    let priceKey = platPromoToKey[platform]?.[promotionType]; // Add optional chaining to avoid undefined errors
    if (!priceKey) {
      console.warn(`Promotion type or platform key is not available for ${platform} - ${promotionType}`);
      return; // Exit if the priceKey is undefined
    }
  
    const price = selectedCreator[priceKey] ? selectedCreator[priceKey] * 1.2 : null;
  
    const platformLinkKey = `${platform} Link`;
    const platformLink = selectedCreator[headerToKeyMap[platformLinkKey]];
    const followingCount = selectedCreator[platformToKey[platform]?.[0]]; // Use optional chaining
  
    const relevantData = {
      id: selectedCreator.creator,
      name: selectedCreator.creator,
      price: price,
      following: followingCount,
      promotionPlatform: platform,
      promotionType: promotionType,
      platformLink: platformLink,
      pfphref: selectedCreator.pfphref
    };
  
    if (onCreatorSelect) {
      onCreatorSelect(creatorId, relevantData);
    }
  };
  

  const headerToKeyMap = {
    Creator: "creator",
    TikTok: "tiktok",
    Instagram: "instagram",
    Youtube: "youtube",
    "Geolocation/Gender/Ethnicity": "geolocation_gender_ethnicity",
    "Primary Market": "primary_market",
    "Content Style": "notes_content_style",
    "TikTok Sound": "rate_tt_sound",
    "TikTok Brand": "rate_tt_brand",
    "IG Reels Sound": "rate_ig_reelsound",
    "IG Reels Brand": "rate_ig_reelbrand",
    "IG Feed Post": "rate_ig_feedpost",
    "Instagram Link": "instagram_link",
    "TikTok Link": "tiktok_link",
    "Youtube Link": "youtube_link",
    "AVG Views": "avg_views",
    "3045s Integration": "rate_yt_integ30s",
    "60s Integration": "rate_yt_integ60s",
    "shorts": "rate_yt_short"
  };

  const headers = {
    TikTok: [
      "Creator",
      "TikTok",
      "Geolocation/ Gender/ Ethnicity",
      "Primary Market",
      "Content Style",
      "AVG Views",
    ],
    Instagram: [
      "Creator",
      "Instagram",
      "Geolocation/ Gender/ Ethnicity",
      "Primary Market",
      "Content Style",
      "Instagram Link",
      "AVG Views",
    ],
    Youtube: [
      "Creator",
      "Youtube",
      "Geolocation/ Gender/ Ethnicity",
      "Primary Market",
      "Content Style",
      "Youtube Link",
      "AVG Views",
    ],
  };

  const platPromoToHead = {
    TikTok: { 
      "Sound": "TikTok Sound", 
      "Brand": "TikTok Brand" 
    },
    Instagram: { 
      "Sound": "IG Reels Sound", 
      "Brand": "IG Reels Brand", 
      "Feed Post": "IG Feed Post" 
    },
    Youtube: { 
      "3045s Integration": "3045s Integration", 
      "60s Integration": "60s Integration", 
      "Shorts": "shorts" 
    },
    Podcasts: { 
      "15s": "Podcast 15-Second", 
      "60s": "Podcast 60-Second" 
    },
    Twitch: { 
      "Live 30s": "Twitch Live 30-Second" 
    },
    Kick: { 
      "Live 30s": "Kick Live 30-Second" 
    },
    X: { 
      "Feed Post": "X Feed Post", 
      "Repost": "X Repost" 
    }
  };
  

  // Check if platform exists in headers and platPromoToHead
if (headers[platform] && platPromoToHead[platform]) {
  const platformHeaders = [...headers[platform]]; // Copy the original array
  const promoHeader = platPromoToHead[platform][promotionType];

  if (promoHeader) {
    // Ensure there are enough elements in the array to use splice
    if (platformHeaders.length >= 2) {
      platformHeaders.splice(-2, 0, promoHeader); // Insert promoHeader at the correct position
    } else {
      platformHeaders.push(promoHeader); // If there are fewer than 2 elements, push promoHeader to the end
    }
  }
}

  const parseFollowerCount = (followerString) => {
    if (!followerString || followerString === "N/A") return 0;
    return parseInt(followerString.replace(/,/g, ""), 10) || 0;
  };

  const followerRangeMatch = (followerCount) => {
    return followerCount >= minFollow && followerCount <= maxFollow;
  };

  const calculateCPM = (rate, avgViews) => {
    if (!rate || !avgViews || rate === "N/A" || avgViews === "N/A") {
      return null;
    }
    const numericalRate = rate;
    const numericalViews = parseInt(avgViews.replace(/[^0-9]/g, ""), 10);

    if (isNaN(numericalRate) || isNaN(numericalViews)) {
      return null;
    }
    return (numericalRate / numericalViews) * 1000;
  };

  const priceRangeMatch = (price) => {
    if (!price) return false;
    const numericalPrice = price;
    return numericalPrice >= minPrice && numericalPrice <= maxPrice;

  };

  const emptyCountry = { code: 'N/A', value: Number.NEGATIVE_INFINITY };
  const emptyAge = { age_range: 'N/A', value: Number.NEGATIVE_INFINITY };
  const emptyGender = { name: 'N/A', value: Number.NEGATIVE_INFINITY };

  const highestValueCountry = (publicData) => {
    return publicData.reduce((max, demo) => {
      const demoMax = demo.country_data.reduce((ctrMax, country) => {
        if (country.value > ctrMax.value)
          ctrMax = country;
        return ctrMax;
      }, emptyCountry);
      if (demoMax.value > max.value)
        max = demoMax;
      return max;
    }, emptyCountry);
  }

  const higherValueAge = (publicData) => {
    const publicByAge = publicData.map((demo) => demo.audience_data.reduce((acc, entry) => {
      const { age_range, value } = entry;
      const existingEntry = acc.find(item => item.age_range === age_range);
      if (existingEntry) {
        existingEntry.value += Math.round(value);
      } else {
        acc.push({ age_range: age_range, value: Math.round(value) });
      }
      return acc;
    }, [])
    )
    return publicByAge.reduce((max, demo) => {
      const demoMax = demo.reduce((ageMax, ageGroup) => {
        if (ageGroup.value > ageMax.value)
          ageMax = ageGroup;
        return ageMax;
      }, emptyAge);
      if (demoMax.value > max.value)
        max = demoMax;
      return max;
    }, emptyAge);
  }

  const higherValueGender = (publicData) => {
    const publicByGender = publicData.map(
      (demo) => ([
        {
          name: 'male',
          value: demo.audience_data.reduce((accum, item) => {
            if (item.gender.toLowerCase() === 'male')
              accum += Math.round(item.value);
            return accum;
          }, 0)
        },
        {
          name: 'female',
          value: demo.audience_data.reduce((accum, item) => {
            if (item.gender.toLowerCase() === 'female')
              accum += Math.round(item.value);
            return accum;
          }, 0)
        }
      ])
    )

    return publicByGender.reduce((max, demo) => {
      const demoMax = demo.reduce((genderMax, genderGroup) => {
        if (genderGroup.value > genderMax.value)
          genderMax = genderGroup;
        return genderMax;
      }, emptyGender);
      if (demoMax.value > max.value)
        max = demoMax;
      return max;
    }, emptyGender);
  }

  const filteredData = useMemo(() => {
    const filterValue = indexToPromoFilter(promoIndex);
    if (!platformToKey[filterValue.platform] || !platPromoToKey[filterValue.platform]) {
      return []; // No data if platform is not yet supported in backend
    }
    const filtered = data.filter((creator) => {
      const platformKeys = platformToKey[filterValue.platform];
      const isPlatformDataPresent = platformKeys.some(
        (key) => {
          return creator[key] && creator[key] !== "N/A";
        }
      );
      if (!isPlatformDataPresent) return false;

      const currPromotionKey = platPromoToKey[filterValue.platform][filterValue.promo];
      //console.log(`Promokey? ${currPromotionKey}`);

      const promoTypeMatch = creator[currPromotionKey] && creator[currPromotionKey] !== null;

      //Creator data

      const primaryMarketMatch = nicheSet.size > 0 ? nicheSet.has(creator.primary_market) : true;

      const raceMatch = creatorRace.size > 0 ? Array.from(creatorRace).some(key => creator.ethnicity?.includes(key)) : true;

      const genderMatch = creatorGender.size > 0 ? Array.from(creatorGender).some(key => (creator.gender || []).includes(key)) : true;

      let regionMatch = true;
      if (creatorRegion.size > 0) {
        regionMatch = creatorRegion.has(creator.region)
      }

      //Range Data


      let avgViewsMatch = true;
      const parseViews = (viewsString) => {
        if (!viewsString) {
          return 0;
        }
        const parsedNumber = parseInt(viewsString.replace(/,/g, ""), 10) || 0;
        return parsedNumber;
      };

      if (minViews !== null && maxViews !== null) {
        const avgViewsValue = parseViews(creator.avg_views);
        avgViewsMatch = (avgViewsValue >= minViews && avgViewsValue <= maxViews)
      }

      const priceMatch = (minPrice !== null && maxPrice !== null) ? priceRangeMatch(creator[currPromotionKey]) : true;

      const followerCount = parseFollowerCount(creator[platformToKey[platform][0]]);
      const followersMatch = (minFollow !== null && maxFollow !== null) ? followerRangeMatch(followerCount) : true;

      //Demo Data

      let demoCountryMatch = true;
      if (publicCountry.size > 0) {
        const maxCountr = highestValueCountry(creator.public);
        demoCountryMatch = publicCountry.has(maxCountr.code)
      }

      let demoAgeMatch = true;
      if (publicAge.size > 0) {
        const maxAge = higherValueAge(creator.public);
        demoAgeMatch = publicAge.has(maxAge.age_range.toLowerCase());
      }

      let demoGenderMatch = true;
      if (publicGender.size > 0) {
        const maxGender = higherValueGender(creator.public);
        demoGenderMatch = publicGender.has(maxGender.name);
      }

      const searchMatch =
        filter === "" ||
        Object.values(creator).some(
          (val) => typeof val === "string" && val.toLowerCase().includes(filter.toLowerCase()),
        );


      return (
        promoTypeMatch &&
        primaryMarketMatch &&
        raceMatch &&
        genderMatch &&
        regionMatch &&
        avgViewsMatch &&
        followersMatch &&
        priceMatch &&
        demoCountryMatch &&
        demoAgeMatch &&
        demoGenderMatch &&
        searchMatch
      );
    });

    return filtered.map((creator) => {
      const platformKeys = platformToKey[filterValue.platform];
      const isPlatformDataPresent = platformKeys.some(
        (key) => {
          return creator[key] && creator[key] !== "N/A";
        }
      );

      const currPromotionKey = platPromoToKey[filterValue.platform][filterValue.promo];
      const rate = isPlatformDataPresent ? creator[currPromotionKey] : 0;
      const avgViews = creator.avg_views;

      const cpm = calculateCPM(rate, avgViews);

      const updatedCreator = { ...creator };
      Object.keys(platPromoToKey).forEach((plat) => {
        Object.values(platPromoToKey[plat]).forEach((key) => {
          if (updatedCreator[key]) {
            const price = updatedCreator[key];
            if (!isNaN(price)) {
              updatedCreator[key] = (price * 1.2);
            }
          }
        });
      });

      return { ...updatedCreator, cpm };
    });

  }, [data, promoIndex, nicheSet, minViews, maxViews, minPrice, maxPrice, minFollow, maxFollow, publicCountry, publicAge, publicGender, creatorRegion, creatorRace, creatorGender, filter]);

  const toggleView = () => {
    setShowPretty(!showPretty);
  };

  const generateCellContent = (creator, header) => {
    const key = headerToKeyMap[header];
    let content = null
    if(key == 'geolocation_gender_ethnicity')
      content = `${creator.geolocation} / ${creator.gender} / ${creator.ethnicity}`
    else
      content = creator[key];
    if (header === "Creator" && content !== "\\N" && content !== "N/A") {
      return (
        <Link
          href={`https://blitzpay.pro/creators/${creator.creator}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </Link>
      );
    } else if (
      header.endsWith("Link") &&
      content !== "\\N" &&
      content !== "N/A"
    ) {
      return (
        <Link href={content} target="_blank" rel="noopener noreferrer">
          {content}
        </Link>
      );
    } else if (content === "\\N") {
      return <span>N/A</span>;
    } else if (content == null || content == undefined) {
      return <span>N/A</span>;
    }

    return content;
  };

  return (
    <>
      <Box sx={{ ...StyleContent, paddingInline: { xs: 1, md: 3 }, paddingBlock: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, }}>
        <PromoPlatFilter value={promoIndex} onChangeValue={(value) => setPromoIndex(value)} ></PromoPlatFilter>
        <NichesFilter value={nicheSet} onChangeValue={(value) => setNicheSet(value)} ></NichesFilter>
        <MinMaxFilter minValue={minPrice} maxValue={maxPrice} width='6rem' name='Rate' onChangeValue={(value) => handleMinMaxApply(value, setMinPrice, setMaxPrice)} ></MinMaxFilter>
        <MinMaxFilter minValue={minFollow} maxValue={maxFollow} name='Followers' width='8rem' onChangeValue={(value) => handleMinMaxApply(value, setMinFollow, setMaxFollow)} ></MinMaxFilter>
        <MinMaxFilter minValue={minViews} maxValue={maxViews} name='Avg. Views' width='10rem' onChangeValue={(value) => handleMinMaxApply(value, setMinViews, setMaxViews)} ></MinMaxFilter>
        <CreatorFilter genderValue={creatorGender} raceValue={creatorRace} regionValue={creatorRegion} onChangeValue={handleCreatorDemoApply}></CreatorFilter>
        <DemoFilters genderValue={publicGender} ageValue={publicAge} countryValue={publicCountry} onChangeValue={handlePublicDemoApply}></DemoFilters>
        <TextField value={search} onChange={(event) => setSearch(event.target.value)} label='Search' sx={{ width: '10rem' }} ></TextField>
        <Box sx={{ marginInlineStart: 'auto', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1, alignItems:'center' }}>
          <IconButton onClick={()=>setIsCreatorDialogOpen(true)}>
            <PersonAddAlt1OutlinedIcon></PersonAddAlt1OutlinedIcon>
          </IconButton>
          <IconButton onClick={handleResetFilters}>
            <FilterAltOffRounded></FilterAltOffRounded>
          </IconButton>
          <FormControlLabel checked={showPretty} onChange={(event) => setShowPretty(event.target.checked)} control={<Switch />} label="Show Pretty" />
        </Box>

      </Box>
      <CreatorDialog open={isCreatorDialogOpen} onClose={() => setIsCreatorDialogOpen(false)} onSubmit={handleCreatorSubmit} />

      {loading ? (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress />
        </Box>
      ) : filteredData.length === 0 ? (
        <Box sx={{ textAlign: 'center', padding: 3 }}>
          <h3>Sorry, there are no creators for this exact set of filters, try changing your filters!</h3>
        </Box>
      ) : showPretty ? (
        <Box sx={{ paddingInline: { xs: 2, md: 1 } }}>
          {<CreatorContainers
            creators={filteredData.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage), filteredData.length)}
            platform={platform}
            selectedItems={selectedItems}
            onCardClick={handleSelectItem} />}
        </Box>
      ) : (
        <Box sx={StyleCsvSection}>
          <Paper elevation={3} sx={(theme) => ({
            marginBottom: theme.spacing(2),
            maxWidth: "100%",
            overflowX: "auto",
          })}>
            <Table sx={classes.csvTable}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">Select</TableCell>
                  {platformHeaders.map((header) => (
                    <TableCell key={header}>{header}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.slice(page * itemsPerPage, Math.min((page * itemsPerPage) + itemsPerPage), filteredData.length).map((creator, idx) => (
                  <StyledTableRow
                    key={idx}
                    selected={selectedItems.has(creator.creator)}
                    sx={{
                      maxWidth: 10,
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.has(creator.creator)}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleSelectItem(creator.creator);
                        }}
                        color="primary"
                      />
                    </TableCell>
                    {platformHeaders.map((header) => (
                      <TableCell key={header}>
                        {generateCellContent(creator, header)}
                      </TableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
      )
      }
      <Paper elevation={1} sx={{ padding: 1, marginBlockStart: 2 }} square>
        <Pagination sx={{ marginInline: 'auto', maxWidth: 'fit-content' }} count={Math.floor(filteredData.length / itemsPerPage)} page={page + 1} onChange={(event, value) => setPage(value - 1)}></Pagination>
      </Paper>
    </>
  );
});

export default LeftColumn;
