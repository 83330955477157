import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from "@mui/material";
import { useMutation } from "react-query";
import { styled } from "@mui/system";
import client from "../../../../../API";

const StyledDialogTitle = styled(DialogTitle)`
  background-color: #424242;
  color: white;
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
`;

const StyledDialogActions = styled(DialogActions)`
  background-color: #424242;
`;

const PayoutDialog = ({ open, onClose, partnershipId, creatorId, conversationId }) => {
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [poNumber, setPoNumber] = useState("");

  useEffect(() => {
    if (!poNumber) {
      setPoNumber(`PO-${Math.floor(Math.random() * 1000000)}`);
    }
  }, [poNumber]);

  const createDeliverableMutation = useMutation(client.deliverables.create, {
    onSuccess: () => {
      onClose();
    },
    onError: (error) => {
      console.error("Error creating deliverable:", error);
    },
  });

  const handleSubmit = () => {
    createDeliverableMutation.mutate({
      partnership_id: partnershipId,
      creator_id: creatorId,
      conversation_id: conversationId,
      deliverable_type: "Payout",
      deliverable_link: null,
      amount,
      status: "sent",
      notes,
      due_date: null,
      platforms: [],
      po_number: poNumber,
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <StyledDialogTitle>Create Payout</StyledDialogTitle>
      <StyledDialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          type="number"
        />
        <TextField
          fullWidth
          margin="normal"
          label="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          multiline
          rows={4}
        />
        <TextField
          fullWidth
          margin="normal"
          label="PO Number"
          value={poNumber}
          onChange={(e) => setPoNumber(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">Create Payout</Button>
      </StyledDialogActions>
    </Dialog>
  );
};
export default PayoutDialog;
