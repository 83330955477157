export const API_ENDPOINTS = {
  USERS_LOGIN: "/users/login",
  FETCH_USER: "/users/userinfo",
  USERS_REGISTER: "/users/register",
  USERS_KPI: "/admin/users/kpi",
  USERS_DETAILS: "/users/details",
  ADMIN_CREATOR_EMAILS: 'admin/creatoremails',
  USERS_ZAPFEED: "/fetchzapfeed",
  CLIENTS_ADD: '/admin/clients/add',
  CLIENTS: '/admin/clients/fetch',

  LAUNCH_CAMPAIGN: "/campaign_handler/launch_campaign",
  UPDATE_WHOLE: "/campaign_handler/update",
  RESEND_LAUNCH: "/campaign_handler/resend_offer",
  SEND_POSTING_INSTRUCTIONS: "/campaign_handler/sendPostingInstructions",
  CAMPAIGNS: "/campaigns/",
  CAMPAIGNS_COPY: "/campaigns/copyCampaign",
  CAMPAIGN_UPDATE_CREATOR_STATS: "/campaigns/updateCreatorStats",
  CAMPAIGNS_DETAILS: (id) => `/campaigns/${id}/details`,
  CAMPAIGNS_ADMIN: "/admin/campaigns",
  CAMPAIGNS_ADMIN_KPI: "/admin/campaigns/kpi",
  CAMPAIGNS_TIMELINE: (id) => `/campaigns/${id}/timeline`,
  CAMPAIGNS_TIMELINE_BY_CREATOR: (campaignId, creatorId) => `/campaigns/${campaignId}/creator/${creatorId}/timeline`,
  CAMPAIGNS_ADDBONUS: (id) => `/campaigns/${id}/add_bonus`,
  CAMPAIGN_UPDATE_WHOLE: (campaignId) => `/campaign_handler/update/${campaignId}`,
  UPDATE_CREATOR_LIST: "/campaigns/updateCreatorList",
  UPDATE_CREATOR_DETAILS: "/campaigns/updateCreatorDetails",
  REMOVE_CREATORS: "/campaigns/removeCreators",
  UPDATE_MANAGER: "/campaigns/updateManager",
  UPDATE_CREATOR_PRICES: "/campaigns/updateCreatorPrices",
  UPDATE_CAMPAIGN_SUM: "/campaigns/updateCampaignSum",
  UPDATE_LINKS_CODES: "/campaigns/updateLinksAndCodes",
  CREATOR_UPDATE_LINKS: "/campaigns/creatorUpdateLinksAndCodes",
  UPDATE_CAMPAIGN: "/campaigns/updateStatus",
  DELETE_CAMPAIGN: "/campaigns/delete",
  COMPLETE_CAMPAIGN: "/campaigns/completeCampaign",
  COMPLETE_CREATOR: (campaignId) => `/campaigns/completeCreator/${campaignId}`,
  GENERATE_REPORT: "/campaigns/generate_report",
  EXPORT_SHEET: "/export_to_sheets",

  CREATOR_PAYOUT_READ: "/sheets/creatorPayoutInfo/read",
  CREATOR_DATA_READ: "/sheets/creatorData/read",
  CREATOR_ADD: "/creators/add",
  CREATOR_SIGNUP: "/creators/signup",
  CREATOR_UPDATE: "/creators/update",
  CREATOR_EMAILS: "/creators/emails",
  CREATOR_DELETE: "/creators/delete",
  INVOICES: "/invoices",
  INVOICE_FETCH: (invoiceId) => `/invoice/${invoiceId}`,
  CREATOR_INVOICES: "/incoming_invoices",
  CREATORS: "/creators",
  CREATORS_PARTNERS: "/creators/partners",
  CREATORS_SPEC: "/creators/",
  CREATOR_AVERAGE_REVIEW: (creatorId) => `/creators/${creatorId}/average_review`,
  CREATOR_REVIEW: (creatorId) => `/creators/${creatorId}/reviews`,
  
  CREATORS_KPI: "/creators/kpi",
  CREATORS_MANAGER: "/creators/manager/",
  COMPANY: "/company",
  USERS_COMPANY: "/company/companyusers",
  DELETE_COMPANY: "/company/delete",
  FETCH_COMPANY_DATA: "/company/companydata",
  EDIT_CAMPAIGN_ADMIN: "/admin/campaign/edit",
  DELETE_CAMPAIGN_ADMIN: "/admin/campaign/delete",
  INVOICES_ADMIN: "/admin/invoices",
  INVOICE_KPI: "/admin/invoices/kpi",
  PAYOUTS_ADMIN: "/admin/payouts",
  PAYOUTS_EDIT_ADMIN: "/admin/payouts/edit",
  DELETE_PAYOUTS_ADMIN: "/admin/payouts/delete",
  PAYOUTS: "/payouts",
  PAYOUT_DELETE: "/payouts/delete",
  DELETE_INVOICE: "/invoices/delete",
  CREATE_INVOICE: "/invoices/create",
  EDIT_INVOICES_ADMIN: "/admin/invoice/edit",
  CREATE_BATCH_PAYOUTL: "/payouts/batch",

  CREATORCONNECT_REAUTH: "/creatorUsers/reauth",
  creatorConnectCampaigns: "/creatorUsers/campaigns",
  CREATORCONNECT_EDIT: "/creatorUsers/edit",
  CREATORCONNECT_ONBOARD: "/creatorUsers/onboard",
  CREATORCONNECT_EDIT_STRIPE: "/creatorUsers/stripe",
  CREATORCONNECT_GET: "/creatorUsers/creator",
  CREATORCONNECT_EMAILS: "/creatorUsers/emails",
  CREATORCONNECT_PAYOUTS: "/creatorUsers/payouts",
  CREATORCONNECT_FINANCIALS: "/creatorUsers/financials",
  CREATORCONNECT_PARTNERSHIPS: "/creatorUsers/partnerships",
  CREATORCONNECT_PARTNERSHIPS_SINGLE: "/creatorUsers/partnerships/",
  CREATORCONNECT_CONTACTS: "/crm/contacts",
  CREATORCONNECT_USERS_BY_COMPANY: "/creatorUsers/users_by_company",
  CREATORCONNECT_CREDX_CREDITS: "/credx/credits",
  CREATORCONNECT_CREDX_PITCH: "/credx/send_pitch_email",
  CREATORCONNECT_CREDX_SUBSTRACT: "/credx/subtract_credits",
  CREATORCONNECT_ZAPFEED: "/creatorUsers/fetchzapfeed",

  CREATORCONNECT_MISC: "/creatorUsers/misc_projects_by_creator",
  CREATORCONNECT_INVOICE: (id) => `/creatorUsers/creator-invoice/${id}`,
  CREATORCONNECT_INVOICE_CREATE: '/creatorUsers/create_invoice',
  CREATORCONNECT_STATUS_CAMPAIGN: (campaignId) => `/creatorUsers/campaigns/${campaignId}/accept`,

  CONVERSATIONS: "/conversations/conversations",
  CONVERSATION_CHECK: "/conversations/conversations/check",
  CONVERSATION_CREATOR_UNREAD: '/conversations/creator/unread_messages',

  MESSAGES: "/conversations/messages",
  GET_CONVERSATION_MESSAGES: "/conversations/messages",
  PARTNERSHIPS: "/projects/partnerships",
  PARTNERSHIPS_PUBLIC: "/projects/public/partnerships",
  PARTNERSHIPS_CREATE: "/projects/partnerships/create",
  PARTNERSHIPS_FETCH: "/projects/partnerships",
  UPDATE_PARTNERSHIP_STATUS: "/projects/partnerships", // Updated
  DELETE_PARTNERSHIP: "/projects/partnerships", // Updated
  PARTNERSHIP_DELIVERABLES: (partnershipId, creatorId) => `/deliverables/getByPartnershipAndCreator/${partnershipId}/${creatorId}`,
  PARTNERSHIP_CONVERSATIONS: "/conversations/partnerships/conversations",
  PARTNERSHIP_CONVERSATION_CHECK: "/conversations/partnerships/conversations/check",
  PARTNERSHIP_MESSAGES: "/conversations/partnerships/messages",
  GET_PARTNERSHIP_CONVERSATION_MESSAGES: "/conversations/partnerships/messages",
  CREATOR_PARTNERSHIP_CONVERSATIONS: "/conversations/partnerships/creator/conversations",
  CREATOR_PARTNERSHIP_CONVERSATION_CHECK: "/conversations/partnerships/creator/conversations/check",
  CREATOR_PARTNERSHIP_MESSAGES: "/conversations/partnerships/creator/messages",
  GET_CREATOR_PARTNERSHIP_CONVERSATION_MESSAGES: "/conversations/partnerships/creator/messages",
  COMMISSIONS: "/projects/commissions",
  DELIVERABLES: "/deliverables",
  CREATE_DELIVERABLES: "/deliverables/create",
  UPDATE_DELIVERABLES: "/deliverables/update",
  GET_DELIVERABLE_PARTNERSHIPS: "/deliverables/getByPartnership",
  MISC_PROJECTS: "/projects/misc_projects/fetch",
  MISC_PROJECTS_CREATE: "/projects/misc_projects/create",
  REQUEST_ACCESS_CRM: "/commissions/referrals/create",
  USER_ADMIN_FETCH: "/admin/users/details_with_credits",
  CREATOR_USER_ADMIN_FETCH: "/admin/creator_users/details_with_credits",
  USER_ADMIN_EDIT: "/admin/users/edit_with_credits",
  CREATOR_CREDITS_ADMIN_EDIT: "/admin/creator_users/edit_with_credits",

  ADMIN_PARTNERSHIPS: "/admin/partnerships",
  COMMISSIONS_All: "/commissions/referrals",
  FETCH_SIMILAR: "/creators/",
  CRM_ADD: "/crm/add",
  CRM_UPDATE: "/crm/sendupdates",
  CRM_UPDATE_CONTACTS: "/crm/updateContacts",
  CRM_UPDATE_CONTACT_STATUS: "/crm/updateContactStatus",
  CRM_CREATOR_UPDATE_STATUS: "/crm/creator/update_status",
  CRM_CREATOR_CREATORS: "/crm/creator/creators",
  CRM_IGDM_CREATORS: "/crm/igdm/creators",
  CRM_CONTACTS: "/crm/contacts",
  CREDX_CREDITS: "/credx/credits",
  SUBTRACT_CREDITS: "/credx/subtract_credits",
  TWILIO_SEND_CREATOR_SMS: '/sendCreatorSMS',
  FETCH_MESSAGES: '/admin/textmessages/fetch',
  DEMO_REGISTER: "/company/registerdemo",
  SHARED_WITH_ADMIN: "/admin/users/shared-emails",
  CREATE_PAYPAL: "/invoices/create_payment",
  UPDATE_CREATORS_STATS: "/campaigns/updateCreatorStats",
  POST_FILES: "/files/upload",
  FETCH_FILES: "/files/",
  CREATE_ASSET: "/files/create_asset",
  CREATOR_UNREAD_MESSAGES: "/conversations/creator/unread_messages",
  UPDATE_CREATOR_CAMPAIGN_SPEC: "/campaign_handler/updateCampaignCreatorSpecs",
  UPDATE_META_DATA: "/campaign_handler/campaign/edit",
  CAMPAIGNS_SIMILAR: "/campaigns/createSimilar",

  CREATE_TRACKERS: "/trackers/create",
  FETCH_TRACKERS: "/trackers/fetch",
  UPDATE_TRACKERS: "/trackers/update",
  CREATOR_ADMIN_FETCH : "/admin/creators",
  post_creator_pfp :"/files/upload_onboard_pfp",

  AGENCY_CAMPAIGN_CREATOR_ACCEPT: (campaignId, creatorId) => `agency/campaigns/${campaignId}/creator/${creatorId}/accept`,
  UPDATE_CLIENT :"/admin/clients/"
};


