import 'react-phone-input-2/lib/style.css';
import { FormControl, FormHelperText, useTheme } from '@mui/material'
import React from 'react'
import PhoneInput from 'react-phone-input-2'

export default function MuiPhoneInput({ country, preferredCountries, value, onChange, isValid, required, error }) {
    const theme = useTheme();
    const HandleInput = (value, data, event) => {
        if(value.length < 11)
            event.target.setCustomValidity('This Phone number is not valid');
        else
            event.target.setCustomValidity('');
        onChange(value);
    }

    return (
        <FormControl variant='outlined' sx={{width: '100%'}}>
            <PhoneInput
                country={country}
                preferredCountries={preferredCountries}
                value={value}
                onChange={HandleInput}
                isValid={isValid}
                inputProps={{required:required}}
                inputStyle={{
                    width: '100%',
                    backgroundColor: 'transparent',
                    color: theme.palette.text.primary,
                    colorScheme: theme.palette.mode
                }}
                dropdownStyle={{
                    backgroundColor: '#FFF',
                    color: "#000",
                    colorScheme: 'light',
                }}
                
            />
            <FormHelperText error={error}>{error}</FormHelperText>
        </FormControl>
    )
}
