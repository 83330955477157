import React from 'react';
import { Typography, List, ListItem, ListItemText, Box, Select, MenuItem, Chip, Link, ListItemAvatar, Avatar, Paper } from '@mui/material';
import CampaignsContainers from '../creatorcontainers';
import profilePhoto from "../../../../../Components/globalAssets/ppfLogo.png"; // Placeholder for the profile photo
import useIsDesktop from '../../../../../Hooks/useIsDesktop';

const CampaignDetailsPanel = ({ campaignDetails, viewMode, isCampaignActive, handleStatusChange }) => {
  const isDesktop = useIsDesktop();
  return (
    <>
      <Typography variant="h6">Creators</Typography>
      {viewMode === "list" ? (

        <List sx={{width:{xs:'100%', 'md':'auto'}}} >
          {Array.isArray(campaignDetails.creators) &&
            campaignDetails.creators.map((creator, index) => (
              <Paper elevation={1} key={index} sx={{borderRadius:16, marginBlockEnd:1, width:{xs:'100%', md:'auto'}}}>
                <ListItem
                  secondaryAction=
                  {isCampaignActive && (
                    <Select
                      label="Status"
                      value={creator.status || "pitched"} // Use existing status or default to 'pitched'
                      onChange={(event) =>
                        handleStatusChange(creator.id, event.target.value)
                      }
                      sx={{ width:'8em', borderRadius: '4px 32px 32px 4px', // Adjust the 16px value as needed for your desired rounding
                        overflow: 'hidden', // Ensure the rounded corners are visible 
                        }}
                    >
                      <MenuItem value="Pitched">Pitched</MenuItem>
                      <MenuItem value="Approved">Approved</MenuItem>
                      <MenuItem value="Dropped">Drop</MenuItem>
                      <MenuItem value="Counter">Counter</MenuItem>
                      <MenuItem value="Accepted">Accepted</MenuItem>
                      <MenuItem value="Declined">Declined</MenuItem>
                    </Select>
                  )}
                  sx={{...(isCampaignActive && isDesktop ? {paddingInlineEnd:  '10em'}: {}), width:{xs:'100%', 'md':'auto'} }}
                >
                  <ListItemAvatar>
                    <Avatar src={creator.pfphref || profilePhoto}>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{width:{xs:'7em', md:'10em'}}}
                    primary={creator.name ?? ""}
                    secondary={`Following: ${creator.following ?? 0}`}
                  />
                    <Box
                      sx={{
                        paddingInlineStart:1,
                        display: {xs:"none",md:'flex'},
                        gap: 1,
                        flexWrap: "wrap",
                        alignItems: 'flex-start',
                      }}
                    >
                      <Chip label={`Price: $${creator.price ?? 0}`} color="primary" sx={{width:'8em'}} />
                      <Chip label={`Offer Sent: ${creator.offerSent ? "Yes" : "No"}`} />
                      <Chip label={`Assets Sent: ${creator.assetsSent ? "Yes" : "No"}`} />
                      <Chip
                        label={`Assets Approved: ${creator.assetsApproved ? "Yes" : "No"}`}
                      />
                      <Chip
                        label={`Promotion Type: ${creator.promotionType ?? ""}`}
                      />
                      <Chip
                        label={`Promotion Platform: ${creator.promotionPlatform ?? ""}`}
                      />
                      <Chip label={`Likes: ${creator.likes ?? 0}`} />
                      <Chip label={`Comments: ${creator.comments ?? 0}`} />
                      <Chip label={`Total Views: ${creator.totalViews ?? 0}`} />
                      <Chip label={`Post Date: ${creator.postDate ?? 0}`} />
                      {creator.platformLink && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <Link
                            href={creator.platformLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View on {creator.promotionPlatform ?? ""}
                          </Link>
                        </Typography>
                      )}
                      {creator.liveLink && (
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          <Link
                            href={creator.liveLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Live Video {creator.liveLink ?? ""}
                          </Link>
                        </Typography>
                      )}
                    </Box>
                </ListItem>
              </Paper>
            ))}
        </List>
      ) : (
        // Prettified view using the CampaignsContainers component
        <CampaignsContainers
          creators={campaignDetails.creators}
          handleStatusChange={handleStatusChange}
        />
      )}
    </>
  );
};

export default CampaignDetailsPanel;
