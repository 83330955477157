import React, { useState, useEffect } from 'react';
import { useTheme, useMediaQuery, Drawer, List, ListItem, IconButton, Typography, Box, Tooltip } from '@mui/material';
import { DnsOutlined, StorefrontOutlined, PeopleOutline, ChevronLeft, ChevronRight, RateReviewOutlined, HomeOutlined} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import useAgencyAuth from "../../Hooks/agency-use-auth.js";
import LogoutIcon from '@mui/icons-material/Logout';

const AgencyNavbar = ({ isDesktop = true, isOpen = true, onClose, onClickHelp }) => {
  const {getCompanyInfo } = useAgencyAuth();

  const agencyName = getCompanyInfo()?.name;
  const theme = useTheme();
  const [open, setOpen] = useState(isOpen);

  // Toggle the state of the drawer
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Close drawer when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!document.getElementById('agencyNavbar').contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [onClose]);

  return (
    <Drawer
      id="agencyNavbar"
      variant="permanent"
      open={open}
      sx={{
        width: open ? 240 : theme.spacing(7),
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? 240 : theme.spacing(7),
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <IconButton onClick={handleDrawerToggle} sx={{ justifyContent: 'flex-end' }}>
        {open ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
      <List>
        <Tooltip title="Dashboard" placement="right" disableHoverListener={!open}>
          <ListItem button component={Link} to="/agency/start">
            <HomeOutlined />
            {open && <Typography variant="body2" noWrap>Dashboard</Typography>}
          </ListItem>
        </Tooltip>
        <Tooltip title="Campaigns" placement="right" disableHoverListener={!open}>
          <ListItem button component={Link} to="/agency/projects">
            <DnsOutlined />
            {open && <Typography variant="body2" noWrap>Campaigns</Typography>}
          </ListItem>
        </Tooltip>
        <Tooltip title="Partnerships" placement="right" disableHoverListener={!open}>
          <ListItem button component={Link} to="/agency/partnerships">
            <RateReviewOutlined />
            {open && <Typography variant="body2" noWrap>Partnerships</Typography>}
          </ListItem>
        </Tooltip>
        <Tooltip title="Marketplace" placement="right" disableHoverListener={!open}>
          <ListItem button component={Link} to="/search">
            <StorefrontOutlined />
            {open && <Typography variant="body2" noWrap>Marketplace</Typography>}
          </ListItem>
        </Tooltip>
        <Tooltip title="My Roster" placement="right" disableHoverListener={!open}>
          <ListItem button component={Link} to={`/roster/${agencyName}`}>
            <PeopleOutline />
            {open && <Typography variant="body2" noWrap>My Roster</Typography>}
          </ListItem>
        </Tooltip>
        <Tooltip title="Back to Blitz" placement="right" disableHoverListener={!open}>
          <ListItem button component={Link} to={`/dashboard`}>
            <LogoutIcon />
            {open && <Typography variant="body2" noWrap>Back to Blitz</Typography>}
          </ListItem>
        </Tooltip>

      </List>
    </Drawer>
  );
};

export default AgencyNavbar;
