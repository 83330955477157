import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import client from '../../../../API';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  List,
  ListItem,
  Box,
  FormControlLabel,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CreatorDialog from "../creatorupdate";
import { StyleContentWithNavBar } from '../../../../Utils/ThemedStyles';

const StyleTableHeader = (theme) => ({
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
});

const fixedMenuItemStyle = {
  width: '200px',
  textAlign: 'center'
};

const CreatorDetailsView = () => {
  const [creators, setCreators] = useState([]);
  const [selectedCreators, setSelectedCreators] = useState([]);
  const [isCreatorDialogOpen, setCreatorDialogOpen] = useState(false);
  const [editingCreator, setEditingCreator] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [verificationFilter, setVerificationFilter] = useState('');
  const [refreshTokenFilter, setRefreshTokenFilter] = useState('');
  const [phoneFilter, setPhoneFilter] = useState(false);
  const [invalidFilter, setInvalidFilter] = useState(false);
  const [avgViewsSort, setAvgViewsSort] = useState('');
  const [tiktokFollowersSort, setTiktokFollowersSort] = useState('');
  const [instagramFollowersSort, setInstagramFollowersSort] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');

  // State variables for the action cards
  const [totalUnverifiedCreators, setTotalUnverifiedCreators] = useState(0);
  const [totalUnconnectedCreators, setTotalUnconnectedCreators] = useState(0);
  const [totalVerifiedCreators, setTotalVerifiedCreators] = useState(0);

  const { mutate: fetchData } = useMutation(client.creators.adminFetch, {
    onSuccess: (data) => {
      setCreators(data);

      const unverifiedCount = data.filter(creator => creator.user_status === 'unverified').length;
      setTotalUnverifiedCreators(unverifiedCount);

      const verifiedCount = data.filter(creator => creator.user_status === 'verified').length;
      setTotalVerifiedCreators(verifiedCount);

      const unconnectedCount = data.filter(creator => !creator.refresh_token_status).length;
      setTotalUnconnectedCreators(unconnectedCount);
    },
    onError: (error) => {
      console.error('Failed to fetch data:', error);
    },
  });

  const { mutate: deleteCreator } = useMutation(
    (creatorId) => client.creators.delete({ creator_id: creatorId }),
    {
      onSuccess: () => {
        fetchData({ is_vendor: true });
      },
      onError: (error) => {
        console.error('Failed to delete creator:', error);
      },
    }
  );

  useEffect(() => {
    fetchData({ is_vendor: true });
  }, []);

  const handleDialogOpen = (creator = null) => {
    setEditingCreator(creator);
    setCreatorDialogOpen(true);
  };

  const handleDialogClose = (refresh = false) => {
    setCreatorDialogOpen(false);
    setEditingCreator(null);
    if (refresh) fetchData({ is_vendor: true });
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleVerificationFilterChange = (event) => {
    setVerificationFilter(event.target.value);
  };

  const handleRefreshTokenFilterChange = (event) => {
    setRefreshTokenFilter(event.target.value);
  };

  const handlePhoneFilterChange = () => {
    setPhoneFilter(!phoneFilter);
  };

  const handleInvalidFilterChange = () => {
    setInvalidFilter(!invalidFilter);
  };

  const handleAvgViewsSortChange = (event) => {
    setAvgViewsSort(event.target.value);
  };

  const handleTiktokFollowersSortChange = (event) => {
    setTiktokFollowersSort(event.target.value);
  };

  const handleInstagramFollowersSortChange = (event) => {
    setInstagramFollowersSort(event.target.value);
  };

  const handleSelectCreator = (creatorId) => {
    setSelectedCreators(prevSelected => (
      prevSelected.includes(creatorId) ? prevSelected.filter(id => id !== creatorId) : [...prevSelected, creatorId]
    ));
  };

  const handleDeleteSelected = () => {
    selectedCreators.forEach(creatorId => deleteCreator(creatorId));
    setSelectedCreators([]);
  };

  const handleIncludeCreatorConnectLink = () => {
    const updatedBody = selectedCreators.reduce((body, id) => {
      const creator = creators.find(creator => creator.creator === id);
      return `${body}\n\nCreator Connect: blitzpay.pro/creatorconnect/${creator.creator}`;
    }, emailBody);
    setEmailBody(updatedBody);
  };

  const handleIncludeCreatorMediaKit = () => {
    const updatedBody = selectedCreators.reduce((body, id) => {
      const creator = creators.find(creator => creator.creator === id);
      return `${body}\n\nCreator Media Kit: blitzpay.pro/creators/${creator.creator}`;
    }, emailBody);
    setEmailBody(updatedBody);
  };

  const handleSendUpdates = async () => {
    const updates = selectedCreators.map(id => {
      const creator = creators.find(creator => creator.creator === id);
      return {
        email: creator.email,
        subject: emailSubject.replace(/{first_name}/g, creator.creator),
        body: emailBody.replace(/{first_name}/g, creator.creator).replace(/{creator_link}/g, `blitzpay.pro/creatorconnect/${creator.creator}`).replace(/{media_kit}/g, `blitzpay.pro/creators/${creator.creator}`)
      };
    });

    try {
      const result = await client.crm.update(updates);
      console.log('Updates sent:', result);

      await client.crm.update_contacts({ updates, note: `last email - ${emailSubject}` });

      for (const id of selectedCreators) {
        await client.crm.creator_update_status({ id, status: 'EMAIL1' });
      }

      setDialogOpen(false);
      setSelectedCreators([]);
      setEmailSubject('');
      setEmailBody('');
    } catch (error) {
      console.error('Failed to send updates:', error);
    }
  };

  const filteredCreators = creators.filter(creator => {
    let isValid = true;

    // Status Filter
    if (statusFilter === "Other" && (creator.status === "Partner" || creator.status === "Associate")) {
      isValid = false;
    } else if (statusFilter !== "" && statusFilter !== "Other" && creator.status !== statusFilter) {
      isValid = false;
    }

    // Invalid data filter
    if (invalidFilter && !creator.invalid_geogenderrace) {
      isValid = false;
    }

    // Phone filter
    if (phoneFilter) {
      if (creator.phone_number && !["NA", "N/A"].includes(creator.phone_number.toUpperCase())) {
        isValid = false;
      }
    }

    // Verification filter
    if (verificationFilter !== '') {
      if (verificationFilter === 'verified' && creator.user_status !== 'verified') {
        isValid = false;
      } else if (verificationFilter === 'unverified' && creator.user_status !== 'unverified') {
        isValid = false;
      }
    }

    // Refresh token filter
    if (refreshTokenFilter !== '') {
      if (refreshTokenFilter === 'connected' && !creator.refresh_token_status) {
        isValid = false;
      } else if (refreshTokenFilter === 'not_connected' && creator.refresh_token_status) {
        isValid = false;
      }
    }

    return isValid;
  }).filter(creator => (
    (creator.creator || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.rate_tt_brand?.toFixed(2) || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.rate_ig_feedpost?.toFixed(2) || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.geolocation || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.gender || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.ethnicity || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.avg_views || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
    (creator.notes_content_style || '').toLowerCase().includes(searchQuery.toLowerCase())
  ));

  if (avgViewsSort === 'highest') {
    filteredCreators.sort((a, b) => b.avg_views - a.avg_views);
  } else if (avgViewsSort === 'lowest') {
    filteredCreators.sort((a, b) => a.avg_views - b.avg_views);
  }

  if (tiktokFollowersSort === 'highest') {
    filteredCreators.sort((a, b) => b.tiktok - a.tiktok);
  } else if (tiktokFollowersSort === 'lowest') {
    filteredCreators.sort((a, b) => a.tiktok - b.tiktok);
  }

  if (instagramFollowersSort === 'highest') {
    filteredCreators.sort((a, b) => b.instagram - a.instagram);
  } else if (instagramFollowersSort === 'lowest') {
    filteredCreators.sort((a, b) => a.instagram - b.instagram);
  }

  return (
    <Box>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Total Unverified Creators</Typography>
              <Typography variant="h4">{totalUnverifiedCreators}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Total Unconnected Creators</Typography>
              <Typography variant="h4">{totalUnconnectedCreators}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h5">Total Verified Creators</Typography>
              <Typography variant="h4">{totalVerifiedCreators}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap', marginBlockEnd:2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <FormControl variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            value={statusFilter}
            onChange={handleStatusFilterChange}
            label="Status"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="Partner" sx={fixedMenuItemStyle}>Partner</MenuItem>
            <MenuItem value="Associate" sx={fixedMenuItemStyle}>Associate</MenuItem>
            <MenuItem value="Other" sx={fixedMenuItemStyle}>Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Verification Status</InputLabel>
          <Select
            value={verificationFilter}
            onChange={handleVerificationFilterChange}
            label="Verification Status"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="verified" sx={fixedMenuItemStyle}>Verified</MenuItem>
            <MenuItem value="unverified" sx={fixedMenuItemStyle}>Unverified</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Connected Email Status</InputLabel>
          <Select
            value={refreshTokenFilter}
            onChange={handleRefreshTokenFilterChange}
            label="Connected Email Status"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="connected" sx={fixedMenuItemStyle}>Connected</MenuItem>
            <MenuItem value="not_connected" sx={fixedMenuItemStyle}>Not Connected</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Avg Views</InputLabel>
          <Select
            value={avgViewsSort}
            onChange={handleAvgViewsSortChange}
            label="Avg Views"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value="highest" sx={fixedMenuItemStyle}>Highest to Lowest</MenuItem>
            <MenuItem value="lowest" sx={fixedMenuItemStyle}>Lowest to Highest</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>TikTok Followers</InputLabel>
          <Select
            value={tiktokFollowersSort}
            onChange={handleTiktokFollowersSortChange}
            label="TikTok Followers"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value="highest" sx={fixedMenuItemStyle}>Highest to Lowest</MenuItem>
            <MenuItem value="lowest" sx={fixedMenuItemStyle}>Lowest to Highest</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel>Instagram Followers</InputLabel>
          <Select
            value={instagramFollowersSort}
            onChange={handleInstagramFollowersSortChange}
            label="Instagram Followers"
            sx={{ minWidth: 200 }}
          >
            <MenuItem value=""><em>None</em></MenuItem>
            <MenuItem value="highest" sx={fixedMenuItemStyle}>Highest to Lowest</MenuItem>
            <MenuItem value="lowest" sx={fixedMenuItemStyle}>Lowest to Highest</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          label='Show only creators without valid phone numbers'
          checked={phoneFilter}
          onChange={handlePhoneFilterChange}
          color="primary"
          control={<Checkbox></Checkbox>}
        ></FormControlLabel>
        <FormControlLabel
          label='Find invalid location/gender/race data'
          checked={invalidFilter}
          onChange={handleInvalidFilterChange}
          color="primary"
          control={<Checkbox></Checkbox>}
        ></FormControlLabel>
        <Button variant="contained" color="secondary" onClick={handleDeleteSelected} disabled={selectedCreators.length === 0}>
          Delete Selected
        </Button>
        <Button variant="contained" color="primary" onClick={() => setDialogOpen(true)} disabled={selectedCreators.length === 0}>
          Send Updates
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedCreators.length > 0 && selectedCreators.length < filteredCreators.length}
                  checked={filteredCreators.length > 0 && selectedCreators.length === filteredCreators.length}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setSelectedCreators(filteredCreators.map(creator => creator.creator));
                    } else {
                      setSelectedCreators([]);
                    }
                  }}
                />
              </TableCell>
              <TableCell sx={StyleTableHeader} align="center">Creator Name</TableCell>
              <TableCell sx={StyleTableHeader} align="center">TikTok Following</TableCell>
              <TableCell sx={StyleTableHeader} align="center">Instagram Following</TableCell>
              <TableCell sx={StyleTableHeader} align="center">Geolocation</TableCell>
              <TableCell sx={StyleTableHeader} align="center">Average Views</TableCell>
              <TableCell sx={StyleTableHeader}>Verified Status</TableCell>
              <TableCell sx={StyleTableHeader}>Connected Email Status</TableCell>
              <TableCell sx={StyleTableHeader} align="center">Email</TableCell>
              <TableCell sx={StyleTableHeader} align="center">Phone Number</TableCell>
              <TableCell sx={StyleTableHeader} align="center">Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCreators.map((creator) => (
              <TableRow key={creator.creator}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCreators.includes(creator.creator)}
                    onChange={() => handleSelectCreator(creator.creator)}
                  />
                </TableCell>
                <TableCell align="right">{creator.creator || 'N/A'}</TableCell>
                <TableCell align="right">{creator.tiktok || 'N/A'}</TableCell>
                <TableCell align="right">{creator.instagram || 'N/A'}</TableCell>
                <TableCell align="right">{`${creator.geolocation} / ${creator.gender} / ${creator.ethnicity}`}</TableCell>
                <TableCell align="right">{creator.avg_views || 'N/A'}</TableCell>
                <TableCell>{creator.user_status || 'N/A'}</TableCell>
                <TableCell>{creator.refresh_token_status ? 'Yes' : 'No'}</TableCell>
                <TableCell align="right">{creator.email || 'N/A'}</TableCell>
                <TableCell align="right">{creator.phone_number || 'N/A'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDialogOpen(creator)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isCreatorDialogOpen && (
        <CreatorDialog
          open={isCreatorDialogOpen}
          onClose={handleDialogClose}
          creatorInfo={editingCreator}
        />
      )}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '80vh' } }}
      >
        <DialogTitle>Send CRM</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Typography variant="subtitle1" gutterBottom>Selected Users to Receive Updates:</Typography>
            <List dense>
              {selectedCreators.map(id => {
                const creator = creators.find(creator => creator.creator === id);
                return (
                  <ListItem key={creator.creator}>
                    {creator.email}
                  </ListItem>
                );
              })}
            </List>
            <TextField
              label="Email Subject"
              variant="outlined"
              fullWidth
              margin="normal"
              value={emailSubject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
            <TextField
              label="Email Body"
              variant="outlined"
              multiline
              rows={8}
              fullWidth
              margin="normal"
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleIncludeCreatorConnectLink} style={{ marginTop: 16 }}>
              Include CreatorConnect Link
            </Button>
            <Button variant="contained" color="primary" onClick={handleIncludeCreatorMediaKit} style={{ marginTop: 16, marginLeft: 8 }}>
              Include Creator Media Kit
            </Button>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSendUpdates} color="primary" variant="contained">Send</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreatorDetailsView;
