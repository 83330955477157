import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  TextField,
} from "@mui/material";
import useAlertDialog from "../../../Components/useAlertDialog";
import AlertDialog from "../../../Components/AlertDialog";
import useAgencyAuth from '../../../Hooks/agency-use-auth';
import useIsDesktop from "../../../Hooks/useIsDesktop";
import API from "../../../API";

const AgencyCampaignPitchedDialog = ({ openDialog, handleCloseDialog, campaignId, creatorId }) => {
  const [campaign, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAgencyAuth();
  const token = getToken();
  const isDesktop = useIsDesktop();

  const { dialogState, openDialog: openAlert, closeDialog } = useAlertDialog();

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        const data = await API.campaigns.fetch(campaignId);
        if (openDialog) {
          setCampaign(data);
          setIsLoading(false);
        }
      } catch (err) {
        console.error(err);
        openAlert("Error", "Failed to load campaign data.");
      }
    };

    if (openDialog) fetchCampaignData();
  }, [openDialog, campaignId, token]);

  const handleChangeStatus = async (accepted) => {
    try {
      API.agency.campaignCreatorAccept(campaignId, creatorId, { status: accepted ? "Accepted" : "Declined" });
      openAlert("Success", `Campaign has been ${accepted ? "accepted" : "declined"} successfully!`);
      setTimeout(() => handleCloseDialog(), 1500); // Close the dialog after a slight delay
    } catch (error) {
      console.error(error);
      openAlert("Error", `An error occurred while updating the status: ${error?.response?.data?.error || error.message}`);
    }
  };

  const onClickOption = (accepted = false) => {
    const alertTitle = accepted ? "Accept Campaign" : "Reject Campaign";
    const alertText = accepted
      ? "Are you sure you want to accept this campaign?"
      : "Are you sure you want to reject this campaign? This action is not reversible.";
    openAlert(alertTitle, alertText, () => handleChangeStatus(accepted), closeDialog, "Continue", "Cancel");
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog} // Ensure this works on close as expected
        maxWidth="md"
        fullWidth
        scroll="paper"
        fullScreen={!isDesktop}
      >
        {isLoading ? (
          <DialogContent style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            <Paper square elevation={2}>
              <DialogTitle>Accept this campaign?</DialogTitle>
            </Paper>

            <DialogContent dividers>
              <Typography variant="h5">Campaign Name: {campaign.name}</Typography>
              <Typography variant="h6">Campaign Brief: {campaign.brief}</Typography>
              <Divider />
              <Box mt={2}>
                <Typography variant="body1">Agency Brief</Typography>
                <TextField fullWidth value={campaign?.agencyBrief || ""} disabled multiline />
              </Box>
              <Box mt={2}>
                <Typography variant="body1">Posting Instructions</Typography>
                <TextField fullWidth value={campaign?.postingInstructions || ""} disabled multiline />
              </Box>
            </DialogContent>
          </>
        )}
        <Paper elevation={2} square>
          <DialogActions>
            <Button onClick={() => onClickOption(true)} color="success">
              Accept
            </Button>
            <Button onClick={() => onClickOption(false)} color="error">
              Reject
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <AlertDialog alertState={dialogState} />
    </>
  );
};

export default AgencyCampaignPitchedDialog;
