import { createTheme } from "@mui/material";

export const getTheme = (isLight = true) => (createTheme({
    palette: {
        mode: isLight ? 'light' : 'dark',
        primary: {
            main: isLight ? '#7b2a2a' : '#cb534f',
            ...isLight && { dark: '#662323' }
        },
        secondary: {
            main: '#646aff',
        },
        background: {
            default: isLight ? '#ececec' : '#121212',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "#fff",
                    backgroundImage: 'unset',
                    color: '#000'
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 'bold',
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold', // Makes InputLabel text bold
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTab-root': {
                        fontWeight: 'bold', // Makes Tab text bold
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
                input: {
                    fontWeight: 'bold', // Makes the text inside the Select input bold
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
                select: {
                    fontWeight: 'bold', // Makes the text inside the Select input bold
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
              head: {
                fontWeight: 'bold', // Makes TableCell text bold for header cells
              },
            },
          },
        MuiInputBase:{
            styleOverrides:{
                input:{
                    colorScheme: isLight ? 'light' : 'dark',
                }
            }
        },
        MuiFormLabel:{
            styleOverrides:{
                root:{
                    fontWeight: 'bold'
                }
            }
        },
        MuiFormControlLabel:{
            styleOverrides:{
                label:{
                    fontWeight: 'bold'
                }
            }
        }
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: 'Inter, Arial, sans-serif',
        h1: {
            fontWeight: 'bold',
        },
        h2: {
            fontWeight: 'bold',
        },
        h3: {
            fontWeight: 'bold',
        },
        h4: {
            fontWeight: 'bold',
        },
        h5: {
            fontWeight: 'bold',
        },
        h6: {
            fontWeight: 'bold',
        },
    },
}))

export const lightTheme = getTheme(true);

export const darkTheme = getTheme(false);