import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useCreatorAuth } from '../../../../Hooks/creator-use-auth';
import { ConfigValue } from '../../../../Config';

const PitchFromZap = () => {
  const { type, id } = useParams(); // type can be 'campaign' or 'partnership'
  const navigate = useNavigate();

  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [rate, setRate] = useState('');
  const [credits, setCredits] = useState(0);
  const [recipientData, setRecipientData] = useState(null); // New state for recipient data

  const { creatorToken } = useCreatorAuth();
  const creatorUsername = creatorToken.creator_user.username;

  // TODO -> STOP ALL FETCH USAGE, USE THE API CLIENT AT API/index.js
  // Fetch credits
  useEffect(() => {
    const fetchCredxMapping = async () => {
      try {
        const response = await fetch(`${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/credits?creator_username=${creatorUsername}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error: ${errorData.error}`);
        }
        const data = await response.json();
        setCredits(data.credits);
      } catch (error) {
        console.error('Error fetching credits:', error.message);
      }
    };

    fetchCredxMapping();
  }, [creatorUsername]);

  // Fetch recipient data
  useEffect(() => {
    const fetchRecipientData = async () => {
      try {
        const response = await fetch(`${ConfigValue.PUBLIC_REST_API_ENDPOINT}/api/${type}/${id}`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error: ${errorData.error}`);
        }
        const data = await response.json();
        setRecipientData(data);
      } catch (error) {
        console.error('Error fetching recipient data:', error.message);
      }
    };

    fetchRecipientData();
  }, [type, id]);

  const handlePitch = async () => {
    if (!ccEmail) {
      alert('Your Email (CC) is required.');
      return;
    }

    if (!recipientData || !recipientData.email) {
      alert('Recipient data is not loaded yet.');
      return;
    }

    try {
      // Subtract credits
      const response = await fetch(`${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/subtract_credits`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          creator_username: creatorUsername,
          email_count: 1, // Sending one email
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert(`Error: ${errorData.error}`);
        return;
      }

      const data = await response.json();
      setCredits(data.credits); // Update credits from the response

      // Send email to the backend route
      await fetch(`${ConfigValue.PUBLIC_REST_API_ENDPOINT}/credx/send_pitch_email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipient_email: recipientData.email, // Use the fetched recipient email
          subject,
          body,
          rate,
          cc_email: ccEmail,
          creator_username: creatorUsername,
        }),
      });

      alert('Pitch sent successfully!');
      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error('Error sending pitch:', error.message);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Send Pitch
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          You have {credits} credits remaining.
        </Typography>
        {recipientData && (
          <Typography variant="subtitle2" gutterBottom>
            Sending to: {recipientData.name} for {recipientData.campaign_or_partnership_name}
          </Typography>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Subject"
          fullWidth
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Body"
          fullWidth
          variant="outlined"
          multiline
          rows={6}
          value={body}
          onChange={(e) => setBody(e.target.value)}
          helperText="Write something meaningful for brands to know about your page! They will be sent instructions on how to book."
        />
        <TextField
          margin="dense"
          label="Rate"
          fullWidth
          variant="outlined"
          value={rate}
          onChange={(e) => setRate(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Your Email (CC)"
          fullWidth
          variant="outlined"
          required
          value={ccEmail}
          onChange={(e) => setCcEmail(e.target.value)}
        />
        <Box sx={{ marginTop: 2 }}>
          <Button variant="contained" onClick={handlePitch}>
            Send Pitch
          </Button>
          <Button onClick={() => navigate(-1)} sx={{ marginLeft: 2 }}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PitchFromZap;
